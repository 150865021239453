import { NotificationsComponent } from './notifications.component';
import { NgModule } from '@angular/core';
import { NbActionsModule, NbCardModule, NbListModule, NbPopoverModule } from '@nebular/theme';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { CommonModule } from '@angular/common';
import { NewsPostComponent } from './notification-list/news-post/news-post.component';
import {
  NewsPostPlaceholderComponent } from './notification-list/news-post-placeholder/news-post-placeholder.component';
// import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [NbActionsModule, NbCardModule, NbPopoverModule, CommonModule, NbListModule,
    HttpClientModule],
  declarations: [NotificationsComponent, NotificationListComponent, NewsPostComponent, NewsPostPlaceholderComponent],
  exports: [NbActionsModule, NotificationsComponent, NotificationListComponent]
})
export class NotificationsModule {

}
