import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@insight-services/authentication.service';
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  activation_key: string;
  redirectDelay: number = 3000;
  showMessages: any = {};
  errors: string[] = [];
  messages: string[] = [];
  lang = 'eng';
  constructor(private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              protected cd: ChangeDetectorRef,
              protected router: Router,
              public translateService: TranslateService
  ) {
    // this.param1 = this.route.snapshot.params.param1;
    this.route.queryParams.subscribe(params => {
      this.activation_key = params['activation_key'];
    });

    this.lang = this.translateService.currentLang;
  }

  ngOnInit() {
    // console.log('param', this.activation_key);
    if (this.activation_key === null) {
      console.error('No activation key');
    }
    if (typeof this.activation_key === 'undefined') {
      // console.error('No activation key undefined');
      this.showMessages['error'] = true;
      this.translateService
        .stream('main.general.messages.error.invalid-activation-key')
        .subscribe(translatedText => (this.errors = [translatedText]));
    } else {
      this.activate_user();
    }

  }

  activate_user() {
    this.authenticationService.activateAccount(this.activation_key).subscribe(
      _ => {
        // refresh the list
        // console.error('Success patching user!', data);
        this.showMessages['success'] = true;
        this.translateService
          .stream('main.general.messages.success.successfully-activated')
          .subscribe(translatedText => (this.messages = [translatedText]));
        this.redirectPage();
      },
      error => {
        this.showMessages['error'] = true;
        this.translateService
          .stream('main.general.messages.error.invalid-activation-key')
          .subscribe(translatedText => (this.errors = [translatedText]));
        return throwError(error);
      }
    );
  }

  redirectPage() {
    setTimeout(() => this.router.navigateByUrl('/auth/login/'), this.redirectDelay);
  }

}
