import { ChangeDetectionStrategy, Component, Input, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { PasswordValidation } from '@insight-helpers/validations';
import { AuthenticationService } from '@insight-services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ResetPasswordRequest } from '@insight-models/reset-password-request';

const { required } = Validators

@Component({
  selector: 'ngx-reset-password',
  standalone: true,
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class ResetPasswordComponent {
  private readonly formBuilder = inject(NonNullableFormBuilder)
  private readonly authService = inject(AuthenticationService)
  private readonly toastrService = inject(ToastrService)
  private readonly router = inject(Router)

  @Input() id: string
  @Input() languageChose: boolean
  @Input() email: string

  resetPasswordForm = this.formBuilder.group({
    token: ["", [required]],
    new_password: ["", PasswordValidation]
  })
  waitReset = signal<boolean>(false)
  isPasswordVisible = signal<boolean>(false)

  get tokenId() {
    return "token-input" + this.id
  }

  get passwordId() {
    return "new-password-input" + this.id
  }

  getPasswordInputType() {
    if (this.isPasswordVisible()) return 'text'
    return 'password'
  }

  showPassword() {
    this.isPasswordVisible.set(true);
  }

  hidePassword() {
    this.isPasswordVisible.set(false);
  }

  resetPassword() {
    this.waitReset.set(true)
    this.authService.resetPassword({ ...this.resetPasswordForm.value, email: this.email } as ResetPasswordRequest)
      .subscribe({
        next: result => {
          this.waitReset.set(false)
          this.toastrService.success(result.message)
          this.router.navigate(['auth/login'])
        },
        error: error => {
          this.toastrService.error(error.error.message)
          this.waitReset.set(false)
        }
      })
  }
}
