<ol style="list-style-type: none">
  <li>
    <p>
      6698 sayılı Kişisel Verilerin Korunması Kanunu'na (“Kanun”) göre <b>Reidin Bilgi ve Data
        Teknolojileri Ticaret Anonim Şirketi </b>(“Reidin”) sizinle ilgili kişisel verileri
      işlemesi
      sebebiyle veri sorumlusu olarak tanımlanmaktadır. Kanunun "Aydınlatma Yükümlülüğü"
      başlıklı 10'ncu maddesine göre veri sorumluları, kişisel verilerini işledikleri gerçek
      kişileri bazı konularda bilgilendirmekle yükümlüdür.
    </p>
    <p>
      Bu metin hem mevzuata uyum sağlanması hem de şeffaf ve hesap verebilir olabilmek
      amacıyla Reidin tarafından hazırlanmıştır.
    </p>
    <p><b>İlgili Kişi:</b> <i>[<a href="https://www.reidin.com/tr/">www.reidin.com/tr/</a>]</i>
      web sitesi ile alt sayfalarını ziyaret ettiğiniz için <b>Web Sitesi Ziyaretçisi</b>
      sıfatına haiz ve bazı kişisel verilerini işlediğimiz siz, Kanun tarafından ilgili kişi
      olarak tanımlanmaktasınız.</p>
    <p>
      <b>Veri Sorumlusu:</b> Sizinle ilgili kişisel veriler konusunda kişisel verilerin işleme
      amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve
      yönetilmesinden sorumlu olan <b>[Reidin Bilgi ve Data Teknolojileri Ticaret Anonim
        Şirketi]</b>
      veri sorumlusudur.
    </p>
  </li>
  <li>
    <h4 class="privacyPolicyHeader">
      <b>İşlenen Kişisel Verileriniz, İşlenme Amaçları ve Hukuki Sebepleri</b>
    </h4>
    <p>
      <table clas="aydinlatmaMetniTable">
        <thead>
          <tr>
            <th>İşlenen Kişisel Verileriniz</th>
            <th>İşlenme Amaçları</th>
            <th>Hukuki Sebepleri </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ad, Soyad, şirket adı, e-posta adresi, cep telefonu, web sitesi,
              ilgilenilen servis bilgisi ve mesajınız</td>
            <td>Rızası ile demo talep eden ziyaretçi bilgilerinin saklanması, demo talep
              eden kullanıcının istemesi halinde firması ile ilgili bilgilerin
              saklanması, satış-pazarlama yönetimi ve iş geliştirme amaçları ile</td>
            <td>KVKK Md. 5/1 hükmüne istinaden</td>
          </tr>
          <tr>
            <td>Ad, Soyad, şirket adı, e-posta adresi, cep telefonu, ticari iletişim
              onayı bilgisi</td>
            <td>Tacirlere elektronik ticari iletişim amacı ile</td>
            <td>KVKK Md. 5/1 hükmüne istinaden</td>
          </tr>
          <tr>
            <td>Bağlantı Zamanı, Bağlantı Bitiş Zamanı, IP Adresi, Kullanılan IP
              Protokolü ve Aktarılan Veri Miktarı</td>
            <td>İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar
              Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun'da Yer
              Sağlayıcının Sorumluluğu (Madde 4) hükmü uyarınca açıkça veri
              sorumlusunun hukuki yükümlülüğünü yerine getirmesi amacı ile</td>
            <td>KVKK Md.5/2ç hükmüne istinaden </td>
          </tr>
          <tr>
            <td>Ziyaret Edilen Sayfalarınızın Bilgisi</td>
            <td>Şartları sağlayan meşru menfaatlerimiz sebebiyle </td>
            <td>KVKK Md.5/2f hükmüne istinaden</td>
          </tr>
        </tbody>
      </table>
      <p>
        Tarafımızca işlenen kişisel verilerizdir.
      </p>
  </li>
  <li>
    <h4 class="privacyPolicyHeader">Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği
    </h4>
    <p>
      Reidin, Kullanıcıya ait kişisel verileri ve bu kişisel verileri kullanılarak elde ettiği
      yeni verileri veya Kullanıcıların kendilerinin pozitif hareketleriyle web sitesine
      girdikleri kişisel bilgileri başta iletişim taleplerini karşılayabilmek, Yasal
      yükümlülüklerimizi ifa edebilmek, Kullanıcı deneyimini geliştirmek, Kullanıcıların
      güvenliğini sağlamak, Elektronik veya kâğıt ortamında işleme dayanak olacak tüm kayıt ve
      belgeleri düzenlemek; mevzuat ve ilgili otoritelerce öngörülen bilgi saklama, raporlama,
      bilgilendirme yükümlülüklerine uymak; talep edilen ürün ve hizmetlerini sunabilmek,
      kurumsal yönetim politikalarımızı uygulayabilmek, Kullanıcı kimliklerini doğrulamak,
      sistem performansını geliştirmek olmak üzere yukarıda işleme amaçları kısmında
      belirtilen amaçlarını gerçekleştirebilmek için çağrı merkezleri, şikayet yönetimi ve
      güvenliğin sağlanmasına ilişkin yazılım şirketleri ile ve yasal zorunluluklar kapsamında
      yetkili kurum, kuruluş, merci, idari ve yargı organları ile paylaşabilecektir.
    </p>
    <p>
      Reidin, kişisel verileri yukarıda belirtilen kategoriler ve amaçlar dahilinde bu
      amaçlarla sınırlı ve orantılı olmak üzere yurt içinde üçüncü kişilere aktarabileceği
      gibi Kanun’un 9. Maddesinde belirtilen yasal şartların sağlanması halinde yurt dışına da
      aktarabilecektir.
    </p>
  </li>
  <li>
    <h4 class="privacyPolicyHeader">
      Kişisel Verilerin Elde Edilme Yöntemleri
    </h4>
    <p>
      Kişisel verileriniz internet ortamında web sitemiz üzerinden tamamen veya kısmen
      otomatik yöntemlerle elde edilmektedir.
    </p>
  </li>
  <li>
    <h4 class="privacyPolicyHeader">
      Kişisel Verilerinizle İlgili Haklarınız
    </h4>
    <p>
      İlgili kişiler, kişisel verilerine ilişkin haklarını öncelikle veri sorumlusuna iletmek
      zorundadırlar. Kanunun 14'üncü maddesine göre Kişisel Verileri Koruma Kurulu'na doğrudan
      şikayette bulunulamaz.
    </p>
    <p>
      Kanun uyarınca kişisel verilerinizle ilgili olarak:
    </p>
    <ol type="a" class="orderedListWithPharenteses">
      <li>
        <p>Kişisel veri işlenip işlenmediğini öğrenme,</p>
      </li>
      <li>
        <p>Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,</p>
      </li>
      <li>
        <p>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp
          kullanılmadığını öğrenme,</p>
      </li>
      <li>
        <p>Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri
          bilme,</p>
      </li>
      <li>
        <p>Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların
          düzeltilmesini isteme,</p>
      </li>
      <li>
        <p>İşlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin
          silinmesini veya yok edilmesini isteme,</p>
      </li>
      <li>
        <p><b>(d)</b> ve <b>(e)</b> bentleri uyarınca yapılan işlemlerin, kişisel
          verilerinizin
          aktarıldığı üçüncü kişilere bildirilmesini isteme,</p>
      </li>
      <li>
        <p>İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi
          suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</p>
      </li>
      <li>
        <p>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız
          hâlinde zararın giderilmesini talep etme haklarını kullanabilirsiniz.</p>
      </li>
    </ol>
  </li>
  <li>
    <h4 class="privacyPolicyHeader">
      Başvuru Yöntemi
    </h4>
    <p>
      Başvurunuzu:
    </p>
    <ul type="disc">
      <li>
        Sultan Selim Mah. Eski Büyükdere Cad. Bilek İş Merkezi 33/A Kat: 7 Kağıthane,
        İstanbul adresine yazılı olarak;
      </li>
      <li>
        Güvenli elektronik imza veya mobil imza ile;
      </li>
      <li>
        Noter aracılığı ile;
      </li>
      <li>
        Elektronik posta adresinizin sistemimizde kayıtlı olması halinde aynı e-posta
        adresini kullanarak <a href="mailto:kisiselveri@reidin.com">kisiselveri&#64;reidin.com</a> adresine
        e-posta göndererek
        yapabilirsiniz.
      </li>
    </ul>
    <p>Başvurunuzda;</p>
    <ul type="disc">
      <li>
        Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,
      </li>
      <li>
        Türkiye Cumhuriyeti vatandaşları için T.C. kimlik numaranızın, yabancı iseniz
        uyruğunuzun, pasaport numaranızın veya varsa kimlik numaranızın,
      </li>
      <li>
        Tebligata esas yerleşim yeri veya iş yeri adresinizin,
      </li>
      <li>
        Varsa bildirime esas elektronik posta adresi, telefon ve faks numaranızın,
      </li>
      <li>
        Talep konunuzun,
      </li>
    </ul>
    <p>bulunması zorunlu olup varsa konuya ilişkin bilgi ve belgelerin de başvuruya eklenmesi
      gerekmektedir.</p>
    <p>Reidin, kimliğinizi doğrulama hakkını saklı tutar.</p>
    <p>Başvuru sırasında uyulması gereken usul kuralları ve daha detaylı bilgiye Kişisel
      Verileri Koruma Kurumu'nun "Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında
      Tebliğ"inden ulaşabilirsiniz.</p>
  </li>
</ol>
