import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CookiePolicyComponent implements OnInit {
  @Output() close = new EventEmitter();
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  onClose() {
    this.close.emit();
  }
  open(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'cookiesPolicyModalLabel',
      // @ts-ignore
      size: 'xl',
      centered: false,
      scrollable: true,
      windowClass: 'cookie-modal-class',
      backdropClass: 'cookie-modal-backdrop'
    });
  }
}
