<form [formGroup]="loginForm" (ngSubmit)="login()" aria-labelledby="title">
  <div class="form-control-group">
    <label class="form-header">
      {{ this.languageChose ? "Email Address" : "Eposta Adresi" }}
    </label>
    <input class="form-input" formControlName="email" fullWidth name="email" [id]="inputId" autocomplete="username"
      pattern=".+@.+\..+" placeholder="accounts@reidin.com" autofocus />
  </div>

  <hr class="line" />
  <span class="validation-error" *ngIf="emailHasErrors">
    {{"main.general.messages.error.unvalid-email" | translate}}
  </span>

  <div class="form-control-group">
    <label class="form-header d-flex justify-content-between align-items-center">
      {{ this.languageChose ? "Password" : "Şifre" }}
      <a class="forgot-password" routerLink="/auth/forgot-password">
        {{ this.languageChose ? "Forgot Password" : "Şifremi Unuttum" }}
      </a>
    </label>
    <div class="d-flex">
      <input class="form-input" formControlName="password" name="password" [type]="getPasswordInputType()"
        [id]="passwordId" placeholder="**********" autocomplete="current-password" />
      <div *ngIf="!isPasswordVisible()" (click)="showPassword()" class="passwordButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-eye-slash-fill"
          viewBox="0 0 16 16">
          <path
            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
          <path
            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
        </svg>
      </div>
      <div *ngIf="isPasswordVisible()" (click)="hidePassword()" class="passwordButton">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-eye-fill"
          viewBox="0 0 16 16">
          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
        </svg>
      </div>
    </div>
  </div>
  <hr class="line" />
  <span class="validation-error" *ngIf="passwordHasErrors">
    {{"main.general.messages.error.unvalid-password" | translate}}
  </span>
  <div style="margin-bottom: 12px" class="mf-input-wrapper">
    <div class="mf-checkbox-option">
      <label style="
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            color: black;
          " class="form-check-label">
        <input type="checkbox" class="mf-input mf-checkbox-input" value="" [id]="checkboxId" name="mf-checkbox"
          data-checked="" aria-invalid="false" />
        <span>{{
          this.languageChose ? "Remember Me" : "Beni hatırla"
          }}</span>
      </label>
    </div>
  </div>
  <button class="form-button mt-3" type="submit" [class.btn-pulse]="submitted" [disabled]="!loginForm.valid"
    [style]="waitLogin ? 'cursor: not-allowed' : 'cursor:pointer'">
    {{ this.languageChose ? "LOGIN" : "GİRİŞ" }}
    <div class="text-center spinner-border ml-2" style="opacity: 0.5" [class.d-none]="!waitLogin"></div>
  </button>
</form>