import { Component, OnInit, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Column, NotificationTableComponent } from "../notification-table/notification-table.component";
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { PowerBIComponent } from '../../power-bi/power-bi.component';
import { of } from 'rxjs';
import { models } from 'powerbi-client';
import { ListingMonitorNotificationFilter, ListingMonitorNotificationFilterResponse } from '@insight-models/listing-monitor-notification-filter';
import { BaseNotification } from '../base-notification';

@Component({
  selector: 'ngx-listing-monitor',
  standalone: true,
  templateUrl: './listing-monitor.component.html',
  styleUrls: ['./listing-monitor.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    PowerBIComponent,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    NotificationTableComponent
  ]
})
export class ListingMonitorComponent extends BaseNotification<ListingMonitorNotificationFilter, ListingMonitorNotificationFilterResponse> implements OnInit {
  override filterMapping = {
    "City": "city",
    "Area": ["area", "community"],
    "Listing Type": "listingType",
    "Property Type": ["propertyType", "propertySubtype"],
    "Property": "property",
    "listing_ae": "bedroom",
    "Price Status": "priceStatus",
    "Source": "source"
  }

  selectedDashboard$ = of({
    menu_id: 330,
    dashboard_id: "f3f77fd9-5c33-4170-931d-cbed0fd07542"
  })

  columns: Column<ListingMonitorNotificationFilterResponse>[] = [
    {
      header: "Listing Type",
      columnDef: "listingType",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.listingType
    },
    {
      header: "City",
      columnDef: "city",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.city
    },
    {
      header: "Area",
      columnDef: "area",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.area
    },
    {
      header: "Community",
      columnDef: "community",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.community
    },
    {
      header: "Property",
      columnDef: "property",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.property
    },
    {
      header: "Property Type",
      columnDef: "propertyType",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.propertyType
    },
    {
      header: "Property Subtype",
      columnDef: "propertySubtype",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.propertySubtype
    },
    {
      header: "Bedroom",
      columnDef: "bedroom",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.bedroom
    },
    {
      header: "Price Status",
      columnDef: "priceStatus",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.priceStatus
    },
    {
      header: "Source",
      columnDef: "source",
      cell: (element: ListingMonitorNotificationFilterResponse) => element.source
    },
  ]

  constructor() {
    super()
    effect(() => {
      if (this.powerBIReportComponent())
        this.getSlicers(this.powerBIReportComponent())
    })
  }

  ngOnInit(): void {
    this.notificationService.setSelectedNotification({
      value: 'listing-monitor',
      name: 'Listing Monitor',
      dashboardId: 330
    })

    this.GetNotificationFilters(330)
      .subscribe({
        next: (value: any[]) => {
          this.data.data = value
        }
      })
  }

  isTargetHierarchy(slicerState: models.ISlicerState) {
    const target = slicerState.targets[0] as models.IFilterColumnTarget
    const column = target.column;
    const keys = ["Property Type", "Area"]

    for (let key of keys) {
      if (column === key) return true
    }

    return false
  }
}
