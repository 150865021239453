<!-- Cookie caution at bottom -->

<div class="cookie-notification row align-items-center p-4" id="cookiePolicy">
  <span class="col-md-10 text-info">
    Çerezler, içeriği ve reklamları kişiselleştirmek, sosyal medya özellikleri sağlamak ve trafiğimizi analiz
    etmek için kullanılmaktadır. Sitemizi kullanarak çerezlere izin vermiş olursunuz.
    <a href="javascript:void(0)" (click)="open(content)"> Çerez politikamız için
      tıklayın.</a>
  </span>

  <button class="col-xs-12 col-md-2 btn-close-cookie-notification "
    (click)="onClose()">KAPAT</button>
</div>

<!-- Cookie policy modal at top-->
<ng-template #content let-modal>
    <div class="modal-header" style="text-align: center;">
      <h3 class="modal-title" id="cookiesPolicyModalLabel" style="font-weight: bold;display:inline-block">
        Çerez Politikası
      </h3>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close"(click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h4>Zorunlu Çereler</h4>
      <p>

        Bu çerezler, web sitesinin çalışması için gereklidir ve sistemlerimizde kapatılamaz. Web
        sitesinde dolaşmanıza ve gizlilik tercihlerinizi belirleme, giriş yapma veya formları doldurma
        gibi hizmet talebine karşılık gelen eylemlere gibi özelliklerini kullanmanıza olanak
        sağlamaktadır.
      </p>
      <h4>
        Performans Çerezleri
      </h4>
      <p>
        Bu çerezler, web sitesinin çalışma şeklini geliştirmek için kullanılmaktadır. Ziyaretçiyi
        tanımlayan bilgileri toplamaz. Ziyaretçilerin web sitesini nasıl kullandıklarına (Örneğin web
        sayfalarında hata mesajı alıp almadıkları) ilişkin bilgileri içermektedir.
      </p>
      <h4>
        Reklam veya Hedefleme Çerezleri
      </h4>
      <p>
        Bu çerezler, sizin profilinizi oluşturmak ve ilgi alanlarınızla alakalı reklamlar sunmak için
        kullanılırlar. Aynı zamanda, reklam kampanyasının etkinliğini ölçmeye yardımcı olmasının yanı
        sıra, bir reklamı görme sayısını sınırlamak için de kullanılmaktadır. Genellikle web sitesi
        sahibinin izniyle reklam ağlarına yerleştirilir. Web sitesini ziyaret ettiğiniz ve çerezde yer
        alan bilgiler reklam verenler diğer kuruluşlarla paylaşılmaktadır.
      </p>
      <h4>
        Fonksiyonel Çerezler
      </h4>
      <p>
        Bu çerezler web sitesinin yaptığınız seçimleri (kullanıcı adınız, diliniz veya bulunduğunuz
        bölge gibi) hatırlamasını ve gelişmiş, daha fazla kişisel özellikler sağlamasını sağlar. Ayrıca
        metin boyutunda, yazı tiplerinde ve web sayfalarının diğer bölümlerinde özelleştirebileceğiniz
        değişiklikleri hatırlamak için de kullanılabilir. Bu çerezlerin topladığı bilgiler gizli
        tutulabilir ve diğer web sitelerindeki göz atma etkinliğinizi izleyemezler.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close()">Kapat</button>
    </div>
</ng-template>
