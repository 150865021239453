import { Injectable, inject, signal } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Filter } from "@insight-models/filter";
import { environment } from "@insight-environments/environment";
import { Observable, Subject } from "rxjs";
import { FilterDto, FilterListResult } from "@insight-models/filter.dto";
import { filter, map, tap } from "rxjs/operators";
import { User } from "@insight-models/user";

@Injectable({
	providedIn: "root",
})
export class DashboardFilterService {
	private readonly httpClient = inject(HttpClient);

	readonly #filters = signal<FilterDto[]>([]);
	readonly filters = this.#filters.asReadonly();

	saveFilter(filter: Filter) {
		return this.httpClient.post<any>(
			`${environment.backendAPIUrl}/api/save_filter`,
			filter
		);
	}

	private orderByCreatedAtDesc(filters: FilterDto[]) {
		return filters.sort(
			(x, y) =>
				new Date(y.create_date).getTime() -
				new Date(x.create_date).getTime()
		);
	}

	private orderByCreatedAtAsc(filters: FilterDto[]) {
		return filters.sort(
			(x, y) =>
				new Date(x.create_date).getTime() -
				new Date(y.create_date).getTime()
		);
	}

	getAllFilters(): Observable<FilterDto[]> {
		const user = JSON.parse(localStorage.getItem("user")) as User;
		return this.httpClient
			.get<FilterListResult>(
				`${environment.backendAPIUrl}/api/get_filters/${user.id}/`
			)
			.pipe(
				filter(
					(filterListResult) =>
						filterListResult !== null &&
						filterListResult.data !== null &&
						filterListResult.data.length > 0
				),
				map((filterListResult) =>
					this.orderByCreatedAtDesc(filterListResult.data)
				),
				tap((result) => this.#filters.set(result))
			);
	}
}
