import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { AuthenticationService } from '@insight-services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ngx-add-device-dialog',
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddDeviceDialogComponent implements OnInit {
  public title: string;
  public activation_key: string;
  public modalAnimationActive: boolean;
  public modalLoading = false;

  constructor(
    protected dialogRef: NbDialogRef<AddDeviceDialogComponent>,
    protected authenticationService: AuthenticationService,
    private toastrService: NbToastrService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  activateDevice() {
    this.modalLoading = true;

    this.modalAnimationActive = !this.modalAnimationActive;

    if (this.activation_key.length < 4) {
      this.toastrService.danger(
        this.translate.instant(
          'main.general.messages.error.701'
        ),
        'Error');
      this.modalLoading = false;

      this.modalAnimationActive = false;

      return;
    }

    this.authenticationService.addDevice(this.activation_key).subscribe(
      response => {
        if (response.message) {
          this.toastrService.success(
            this.translate.instant(
              'main.general.messages.success.device-id-updated'
            ),
            'Add Device');
        } else {
          this.toastrService.danger(
            this.translate.instant(
              'main.general.messages.error.general-error'
            ),
            'Add Device');
        }

        setTimeout(() => (window.location.href = '/'), 3000);
      },
      error => {
        // console.log('response error', error);
        if (error === '101') {
          this.toastrService.danger(
            this.translate.instant(
              'main.general.messages.error.701'
            ),
            'Error');
        } else {
          this.toastrService.danger(
            this.translate.instant(
              `main.general.messages.error.${error}`
            ),
            'Error');
        }
        this.modalLoading = false;

        this.modalAnimationActive = false;

      }
    );
  }
}
