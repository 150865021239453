<div class="layout-column flex login-area">
  <ul class="nav nav-tabs justify-content-center" style="padding-top:2rem;">
    <li class="nav-item">
        <div class="logo" (click)="goToHome()" style="padding-right: 5rem;padding-bottom:2.5rem;cursor:pointer" >
            <img src="../../assets/images/logo-insight.svg" alt="" style="width:100%"/>
          </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['terms-conditions']" [ngClass]="{'active':path==='terms-conditions'}">
        {{"main.general.buttons.terms-conditions" | translate}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['privacy-policy']" [ngClass]="{'active':path==='privacy-policy'}">
        {{"main.general.buttons.privacy-policy" | translate}}
      </a>
    </li>
    <li class="nav-item" *ngIf="language === 'eng'">
      <a class="nav-link" [routerLink]="['data-disclaimer']" [ngClass]="{'active':path==='data-disclaimer'}">Data
        Disclaimer</a>
    </li>
    <li class="nav-item" *ngIf="language === 'eng'">
      <a class="nav-link" [routerLink]="['refund-policy']" [ngClass]="{'active':path==='refund-policy'}">Refund
        Policy</a>
    </li>
    <li class="nav-item" *ngIf="language === 'tur'">
      <a class="nav-link" [routerLink]="['policy-on-protection-of-personal-data']" [ngClass]="{'active':path==='policy-on-protection-of-personal-data'}">
        Kişisel Verilerin Korunması</a>
    </li>
  </ul>
  <div class="row">
    <div class="col-md-2 offset-10">
      <button class="btn btn-success btn-xs" (click)="goToHome()">Go Back</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 offset-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
