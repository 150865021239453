<nb-layout>

  <!-- Header -->
  <nb-layout-header class="nb-layout-header-background" fixed>
    <ngx-header #header>
    </ngx-header>
  </nb-layout-header>

  <!-- Left sidebar -->
  <nb-sidebar #sidebar class="menu-sidebar" tag="menu-sidebar" [containerFixed]="true" [responsive]="false"
    [collapsedBreakpoints]="['xs', 'is', 'sm','md','lg']" [fixed]="false" (stateChange)="toggleSidebar($event)">

    <!-- <usercard [visible]='showUserCard'></usercard> -->

    <sidebar-menu></sidebar-menu>

  </nb-sidebar>

  <!-- Dashboard Content -->
  <nb-layout-column #mainContent id="mainContent" class="main-content">
    <router-outlet></router-outlet>
  </nb-layout-column>

  <!-- Footer -->
  <!-- <nb-layout-footer>
    <ngx-footer></ngx-footer>
  </nb-layout-footer> -->

</nb-layout>

<!-- <ngx-filters></ngx-filters> -->