import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'ngx-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.scss']
})
export class PrivacyTermsComponent implements OnInit {
  path: string;
  language: string;
  constructor(
    private router: Router,
    private translateService: TranslateService) {
    this.language = this.translateService.currentLang;
    this.router.events.subscribe({
      next: (route) => {
        if (route instanceof NavigationEnd) {
          this.path = route.url.split('/')[2];
        }
      }
    });
  }

  ngOnInit() { }

  goToHome() {
    this.router.navigateByUrl('/auth/login');
  }
}
