import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PowerBIService } from '@insight-services/power-bi.service';

@Injectable()
export class PowerBiRestAPIInterceptor implements HttpInterceptor {

  constructor(
    private readonly powerBIService: PowerBIService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf("api.powerbi.com/v1.0/myorg") > -1) {
      const token = localStorage.getItem("powerbi_token")
      const newRequest: HttpRequest<any> = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      })
      return next.handle(newRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403) {
            return this.powerBIService.getPowerBiToken()
              .pipe(
                tap(token => localStorage.setItem("powerbi_token", token.access_token)),
                map(token => {
                  const newHeaders = request.headers.set('Authorization', `Bearer ${token.access_token}`)
                  const newRequest = request.clone({ headers: newHeaders })
                  return newRequest
                }),
                switchMap(newRequest => {
                    return next.handle(newRequest)
                }),
              )
          }
        })
      );
    }
    return next.handle(request);
  }
}
