import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewChild,
	inject,
	signal,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardFilterService } from "@insight-services/dashboard-filter.service";
import { Router } from "@angular/router";
import { FilterDto } from "@insight-models/filter.dto";
import { MenuService } from "@insight-services/menu.service";
import { filter, map, tap } from "rxjs/operators";
import { TranslateModule } from "@ngx-translate/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatButtonModule } from "@angular/material/button";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
	selector: "ngx-filters",
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		MatTableModule,
		MatButtonModule,
		MatPaginatorModule,
		MatSortModule,
		MatDialogModule,
		MatProgressSpinnerModule,
	],
	templateUrl: "./filters.component.html",
	styleUrls: ["./filters.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements AfterViewInit, OnInit {
	private readonly dashboardFilterService = inject(DashboardFilterService);
	private readonly router = inject(Router);
	private readonly menuService = inject(MenuService);

	loading = signal(true);

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	dataSource = new MatTableDataSource<FilterDto>();
	displayedColumns: string[] = [
		"filter_name",
		"create_date",
		"dashboard",
		"actions",
	];

	ngOnInit(): void {
		this.getDashboardFilters().subscribe({
			next: (filters) => {
				this.dataSource.data = filters;
			},
		});
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	applyDashboardFilter(filter: FilterDto) {
		this.router.navigate(["home/dashboard/", filter.dashboard_id], {
			queryParams: {
				filterId: filter.id,
			},
		});
	}

	getDashboardFilters() {
		return this.dashboardFilterService.getAllFilters().pipe(
			filter((filters) => filters.length > 0),
			map((filters) => ({
				filterDto: filters,
				menuItems: this.menuService.topMenuItems()
			})),
			map(({filterDto, menuItems}) => {
				const result = filterDto.map((filterDto) => {
					for (let item of menuItems) {
						const dashboard = this.menuService.findDashboard(
							item,
							filterDto.dashboard_id
						);
						if (dashboard) {
							filterDto.dashboard_name = dashboard.title_en;
							return filterDto;
						}
					}
					return filterDto;
				});
				return result;
			}),
			tap((_) => this.loading.set(false))
		);
	}
}
