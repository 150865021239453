import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbPopoverDirective } from '@nebular/theme';
import { NbJSThemeOptions } from '@nebular/theme/services/js-themes/theme.options';
import { NotificationListComponent } from './notification-list/notification-list.component';

@Component({
    selector: 'ngx-notifications',
    // changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  @ViewChild(NbPopoverDirective, { static: false }) popover: NbPopoverDirective;

  @Input() showTitle: boolean = true;

  notificationListComponent = NotificationListComponent;
  theme: NbJSThemeOptions;

  ngOnInit() {
  }
}
