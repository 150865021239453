import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

export type NotificationTableData = { id: number, email: string, frequency: 1 | 7 | 30 }
export type NotificationTableColumn = { columnName: string, propertyName: string }

export type Column<T> = {
  columnDef: string,
  header: string,
  cell: (element: T) => string | number | undefined | null,
}

@Component({
  selector: 'ngx-notification-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationTableComponent<TData extends NotificationTableData> implements OnInit, AfterViewInit {
  @Input() columns: Column<TData>[];
  @Input() data: MatTableDataSource<TData>;
  @Input() edittingData: number;
  @Output() onEdit: EventEmitter<TData> = new EventEmitter<TData>();
  @Output() onExitEdit: EventEmitter<TData> = new EventEmitter();
  @Output() onDelete: EventEmitter<TData> = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[];

  readonly frequencyMapping: Readonly<Record<number, string>> = {
    1: "Daily",
    7: "Weekly",
    30: "Monthly"
  }

  ngOnInit(): void {
    this.displayedColumns = [...this.columns.map(x => x.columnDef), "frequency", "email", "actions"]
  }

  ngAfterViewInit(): void {
    this.data.paginator = this.paginator;
    this.data.sort = this.sort;
  }

  exitEdit() {
    this.onExitEdit.emit();
  }

  edit(data: TData) {
    this.onEdit.emit(data)
  }

  delete(data: TData) {
    this.onDelete.emit(data)
  }

  isEditingNotificationSameAsGiven(notification: TData): boolean {
    const edittingNotification = this.edittingData
    if (edittingNotification && notification.id === edittingNotification) {
      return true
    }
    return false
  }

  editButtonColor(notification: TData) {
    if (this.isEditingNotificationSameAsGiven(notification))
      return "warn"
    return "accent"
  }
}
