import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxRegisterComponent } from './register/register.component';
import { NgxLogoutComponent } from './logout/logout.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { NgxAuthComponent } from './ngx-auth.component';
import { NgxLoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

export const routes: Routes = [
  {
    path: '',
    component: NgxAuthComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth/login'
      },
      {
        path: 'login',
        component: NgxLoginComponent
      },
      {
        path: 'login/:redirectUrl',
        component: NgxLoginComponent
      },
      {
        path: 'logout',
        component: NgxLogoutComponent
      },
      {
        path: 'register',
        component: NgxRegisterComponent
      },
      {
        path: 'activate',
        component: ActivateAccountComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: '**',
        redirectTo: '/auth/login'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NgxAuthRoutingModule {
}
