<div>
  <ngx-privacy-policy [language]="languageChose"></ngx-privacy-policy>
</div>

<div>
  <ngx-terms-and-conditions [language]="languageChose"></ngx-terms-and-conditions>
</div>

<div class="modal fade" id="request-demo" tabindex="-1" role="dialog" aria-labelledby="request-demo" aria-hidden="true">
  <ngx-request-demo [languageChose]="languageChose"></ngx-request-demo>
</div>
<nb-layout class="login-layout">
  <nb-layout-column class="layout-desktop">
    <div class="desktop">
      <div style="height: 100vh" class="row">
        <div class="col-md-9">
          <ngx-landing-promotion [languageChose]="languageChose" [isDesktop]="true">
          </ngx-landing-promotion>
        </div>
        <div class="col-md-3 form-container">
          <img class="logo mt-4" src="../../../assets/images/r-insight-logo.svg" alt="" />
          <div class="row">
            <div class="col-xl-5 col-xxl-6 col-12">
              <h1 style="text-align: left !important" class="login-header">
                {{ this.languageChose ? "Sign In" : "Giriş" }}
              </h1>
            </div>
            <div class="select-div col-xl-7 col-xxl-6 col-12">
              <div class="styled-select">
                <select [(ngModel)]="selectedLanguage" (change)="langualeOnChange()" id="language">
                  <option value="eng">
                    {{ this.languageChose ? "English" : "İngilizce" }}
                  </option>
                  <option value="tur">
                    {{ this.languageChose ? "Turkish" : "Türkçe" }}
                  </option>
                </select>
                <i class="dropdown-icon"></i>
              </div>
            </div>
          </div>
          <hr class="line" />
          <nb-alert *ngIf="isLogin" class="alert-success">
            <p class="alert-title">
              {{ this.languageChose ? "Login is success!" : "Giriş Başarılı!" }}
            </p>
          </nb-alert>
          <nb-alert [hidden]="submitted" class="alert-danger">
            <p class="alert-title" *ngFor="let errorDictionaryKey of errorDictionaryKeys">
              {{ errorDictionaryKey | translate }}
            </p>
          </nb-alert>
          <ngx-login-form [loginForm]="loginForm" [submitted]="submitted" [languageChose]="languageChose"
            [waitLogin]="waitLogin" (onSubmit)="login()" id="login-desktop"/>
          <button type="button" class="form-request-demo" data-toggle="modal" data-target="#request-demo">
            {{
            this.languageChose
            ? "Request Your Free Demo"
            : "Ücretsiz Demonuzu İsteyiniz"
            }}
          </button>
          <div style="margin-top: 30px; text-align: center" class="container">
            <a class="privacy-terms" data-toggle="modal" data-target="#privacy-policy">
              <ins>
                {{
                this.languageChose ? "Privacy Policy" : "Gizlilik Politikası"
                }}
              </ins>
            </a>
          </div>
          <div style="margin-top: 10px; text-align: center" class="container">
            <a class="privacy-terms" data-toggle="modal" data-target="#terms-and-conditions">
              <ins>
                {{
                this.languageChose
                ? "Terms and Conditions"
                : "Kullanım Koşulları"
                }}
              </ins>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nb-layout-column>
  <nb-layout-column class="layout-mobile">
    <div class="mobile container">
      <div class="row">
        <div class="col-md-12">
          <div class="form-container">
            <img class="logo" src="../../../assets/images/r-insight-logo.svg" alt="" />
            <div class="row" style="flex-wrap: nowrap">
              <div class="col-sm-6">
                <h1 style="text-align: left !important" class="login-header">
                  {{ this.languageChose ? "Sign In" : "Giriş" }}
                </h1>
              </div>
              <div class="select-div col-sm-6">
                <div class="styled-select mobile">
                  <select [(ngModel)]="selectedLanguage" (change)="langualeOnChange()" id="language">
                    <option value="tur">
                      {{ this.languageChose ? "Turkish" : "Türkçe" }}
                    </option>
                    <option value="eng">
                      {{ this.languageChose ? "English" : "İngilizce" }}
                    </option>
                  </select>
                  <i class="dropdown-icon"></i>
                </div>
              </div>
            </div>
            <hr class="line" />
            <nb-alert *ngIf="isLogin" class="alert-success">
              <p class="alert-title">
                {{
                this.languageChose ? "Login is success!" : "Giriş Başarılı!"
                }}
              </p>
            </nb-alert>
            <nb-alert [hidden]="submitted" class="alert-danger">
              <p class="alert-title" *ngFor="let errorDictionaryKey of errorDictionaryKeys">
                {{ errorDictionaryKey | translate }}
              </p>
            </nb-alert>
            <ngx-login-form [loginForm]="loginForm" [submitted]="submitted" [languageChose]="languageChose"
              [waitLogin]="waitLogin" (onSubmit)="login()" id="login-mobile"/>
            <button type="button" class="form-request-demo" data-toggle="modal" data-target="#request-demo">
              {{
              this.languageChose
              ? "Request Your Free Demo"
              : "Ücretsiz Demonuzu İsteyiniz"
              }}
            </button>
            <div style="margin-top: 20px; text-align: center" class="container">
              <a class="privacy-terms" data-toggle="modal" data-target="#privacy-policy">
                <ins>
                  {{
                  this.languageChose
                  ? "Privacy Policy"
                  : "Gizlilik Politikası"
                  }}
                </ins>
              </a>
            </div>
            <div style="margin-top: 10px; text-align: center" class="container">
              <a class="privacy-terms" data-toggle="modal" data-target="#terms-and-conditions">
                <ins>
                  {{
                  this.languageChose
                  ? "Terms and Conditions"
                  : "Kullanım Koşulları"
                  }}
                </ins>
              </a>
            </div>
          </div>
        </div>
        <ngx-landing-promotion [languageChose]="languageChose" [isDesktop]="false">
        </ngx-landing-promotion>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>