import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsService } from "./services/gtm-analytics.service";

@Component({
	selector: "ngx-app",
	template: `
		<!-- <nb-layout> -->
		<router-outlet></router-outlet>
		<ngx-cookie-policy
			*ngIf="showCookiePolicy"
			(close)="onClose()"
		></ngx-cookie-policy>
		<!-- </nb-layout> -->
	`,
})
export class AppComponent implements OnInit {
	showCookiePolicy = true;
	constructor(
		public translateService: TranslateService,
		public analytics: AnalyticsService,
	) {
		translateService.addLangs(["eng", "tur"]);
		translateService.setDefaultLang("eng");
		translateService.use("eng");
		// this.analytics.init();
	}

	ngOnInit() {
		if (
			this.checkCookiePolicy() &&
			this.checkCookiePolicy().replace("=", "") === "false"
		) {
			this.showCookiePolicy = false;
		}

		if (this.translateService.getDefaultLang() !== "tur") {
			this.showCookiePolicy = false;
		}
	}

	checkCookiePolicy() {
		const name = "showCookiePolicy";
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
	}

	onClose() {
		document.cookie = "showCookiePolicy=false";
		this.showCookiePolicy = false;
	}
}
