import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'reidin-city-icon',
  templateUrl: './city-icon.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CityIcon {

}
