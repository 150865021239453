import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@insight-environments/environment';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { User } from '@insight-models/user';

@Injectable()
export class UserServiceInterceptor implements HttpInterceptor {
  device_id: string;

  constructor(
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authStatus = JSON.parse(localStorage.getItem("userServiceToken"));
    const user = JSON.parse(localStorage.getItem("user")) as User | null

    const lang = 'eng';
    const country = 'AE';

    if (request.url.indexOf(environment.userServiceAPIUrl + '/company_info') > -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${environment.clientSecret}`,
        }
      });
    }

    if (
      request.url.indexOf(environment.userServiceAPIUrl + '/login') > -1
      || request.url.indexOf(environment.userServiceAPIUrl + '/logout') > -1
      || request.url.indexOf(environment.userServiceAPIUrl + '/client/users') > -1
      || request.url.indexOf(environment.userServiceAPIUrl + '/users/free_user') > -1
      || request.url.indexOf(environment.userServiceAPIUrl + '/users/reset_password') > -1
      || request.url.indexOf(environment.userServiceAPIUrl + '/users/change_password') > -1
      || request.url.indexOf(environment.userServiceAPIUrl + '/users/my_settings') > -1
    ) {
      request = user?.id
        ? request.clone({
          setHeaders: {
            Authorization: `Basic ${environment.clientSecret}`,
            Userid: user?.id.toString()
          }
        })
        : request.clone({
          setHeaders: {
            Authorization: `Basic ${environment.clientSecret}`,
          }
        })
    }

    if (
      request.url.indexOf(environment.userServiceAPIUrl + '/users/me') > -1
    ) {
      if (authStatus.access_token !== '') {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authStatus.access_token}`,
            Lang: `${lang}`,
            Country: `${country}`,
            Userid: user?.id.toString()
          }
        });
      }
    }


    if (
      request.url.indexOf(environment.userServiceAPIUrl + '/users/reset_password') > -1
    ) {
      let lang1: string = 'eng';

      if (navigator.language === 'tr-TR' || navigator.language === 'tur') {
        lang1 = 'tur';
      }

      request = request.clone({
        url: request.url + '?lang=' + lang1
      });
    }

    if (request.url.indexOf(environment.userServiceAPIUrl + '/logout') > -1) {
      request = user?.id
        ? request.clone({
          url: request.url,
          setHeaders: {
            Userid: user?.id.toString()
          }
        })
        : request.clone({
          url: request.url,
        })
    }

    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (err.status === 401 && !(err.error === "Session Not Found. Check Device ID")) {
          console.log('Error 401:' + JSON.stringify(err));
          this.router.navigate(['/auth/login']);
        }
        return throwError(err);
      })
    );
  }
}
