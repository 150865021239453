import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'reidin-location-icon',
    templateUrl: './location-icon.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LocationIcon {
}