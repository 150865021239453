<div class="desktop" *ngIf="isDesktop">
  <nav class="left-right-space">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a class="nav-item nav-link" [ngClass]="{'active':activeSlide==0}" id="nav-reports-tab" (click)="navClick($event)"
        data-toggle="tab" href="#nav-reports" role="tab" aria-controls="nav-reports" aria-selected="true">{{
        this.languageChose ? "Reports" : "Raporlar" }}</a>
      <a class="nav-item nav-link" [ngClass]="{'active':activeSlide==1}" id="nav-rankings-tab"
        (click)="navClick($event)" data-toggle="tab" href="#nav-rankings" role="tab" aria-controls="nav-rankings"
        aria-selected="false">{{ this.languageChose ? "Rankings" : "Sıralamalar" }}</a>
      <a class="nav-item nav-link" [ngClass]="{'active':activeSlide==2}" id="nav-trends-tab" (click)="navClick($event)"
        data-toggle="tab" href="#nav-trends" role="tab" aria-controls="nav-trends" aria-selected="false">{{
        this.languageChose ? "Trends" : "Trendler" }}</a>
      <a class="nav-item nav-link" [ngClass]="{'active':activeSlide==3}" id="nav-data-tab" (click)="navClick($event)"
        data-toggle="tab" href="#nav-data" role="tab" aria-controls="nav-data" aria-selected="false">{{
        this.languageChose ? "Data" : "Veri" }}</a>
    </div>
  </nav>
  <div id="carouselDesktop" class="carousel slide" data-interval="false" data-touch="false">
    <div class="container carousel-control-desktop">
      <a class="carousel-control-prev" (click)="sliderControlClick('prev')" role="button" data-slide="prev">
      </a>
      <a class="carousel-control-next" (click)="sliderControlClick('next')" role="button" data-slide="next">
      </a>
    </div>
    <ol class="carousel-indicators">
      <li (click)="liClick($event)" data-target="#carouselDesktop" id="desktopIndicator1" data-slide-to="0"
        class="slider-point" [ngClass]="{'active':activeSlide==0}">
      </li>
      <li (click)="liClick($event)" data-target="#carouselDesktop" id="desktopIndicator2" data-slide-to="1"
        class="slider-point" [ngClass]="{'active':activeSlide==1}"></li>
      <li (click)="liClick($event)" data-target="#carouselDesktop" id="desktopIndicator3" data-slide-to="2"
        class="slider-point" [ngClass]="{'active':activeSlide==2}"></li>
      <li (click)="liClick($event)" data-target="#carouselDesktop" id="desktopIndicator4" data-slide-to="3"
        class="slider-point" [ngClass]="{'active':activeSlide==3}"></li>
    </ol>
    <div class="carousel-inner">
      <div id="desktopSlide1" class="carousel-item" [ngClass]="{'active carousel-animation':activeSlide==0}">
        <img class="d-block w-100" src=".././../../assets/01-reports.png" alt="First slide" />
      </div>
      <div id="desktopSlide2" class="carousel-item" [ngClass]="{'active carousel-animation':activeSlide==1}">
        <img class="d-block w-100" src=".././../../assets/02-rankings.png" alt="Second slide" />
      </div>
      <div id="desktopSlide3" class="carousel-item" [ngClass]="{'active carousel-animation':activeSlide==2}">
        <img class="d-block w-100" src=".././../../assets/03-trends.png" alt="Third slide" />
      </div>
      <div id="desktopSlide4" class="carousel-item" [ngClass]="{'active carousel-animation':activeSlide==3}">
        <img class="d-block w-100" src=".././../../assets/04-data.png" alt="Fourth slide" />
      </div>
    </div>
  </div>
  <div id="carouselCustomerCommentsControls" class="carousel slide" data-ride="carousel">
    <div class="container carousel-control">
      <a class="carousel-control-prev" href="#carouselCustomerCommentsControls" role="button" data-slide="prev">
      </a>
      <a class="carousel-control-next" href="#carouselCustomerCommentsControls" role="button" data-slide="next">
      </a>
    </div>
    <ol class="carousel-indicators bottom-text-dot">
      <li data-target="#carouselCustomerCommentsControls" data-slide-to="0" class="slider-point active"></li>
      <li data-target="#carouselCustomerCommentsControls" data-slide-to="1" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControls" data-slide-to="2" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControls" data-slide-to="3" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControls" data-slide-to="4" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControls" data-slide-to="5" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControls" data-slide-to="6" class="slider-point"></li>
    </ol>
    <div class="carousel-inner customer-comments">
      <div class="carousel-item active">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/01-sohail-raja.jpg"
            alt="Sohail Raja Photo" />
          <h1 class="commit-header">Sohail Raja</h1>
          <p class="sub-header">Valuations Manager</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " Reidin has brought transparency to the Dubai Market and is an invaluable resource in helping"
            +" in all of Hamptons Real Estate needs. They have been extremely professional at all times with"
            +" excellent overall service. Reidin is the foremost tool for anyone requiring up to date Market insights. "
            :" Reidin has brought transparency to the Dubai Market and is an invaluable resource in helping"
            +" in all of Hamptons Real Estate needs. They have been extremely professional at all times with"
            +" excellent overall service. Reidin is the foremost tool for anyone requiring up to date Market insights. "
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/02-prathyusha-gurrapu.jpg"
            alt="Prathyusha Gurrapu Photo" />
          <h1 class="commit-header">Prathyusha Gurrapu</h1>
          <p class="sub-header">Head of Research & Consultancy at Cushman Wakefield Core</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " REIDIN is an important data tool that we use extensively to analyse the wider" 
            + " macro-market as well as drill into sub-markets and individual projects." 
            + " It helps us to uncover the latest trends along with gaining an in-depth" 
            + " and nuanced understanding of the market. The intuitive platform continues to evolve," 
            + " with an easy user interface and features while the service from the team is exceptional."
            : " REIDIN is an important data tool that we use extensively to analyse the wider" 
            + " macro-market as well as drill into sub-markets and individual projects." 
            + " It helps us to uncover the latest trends along with gaining an in-depth" 
            + " and nuanced understanding of the market. The intuitive platform continues to evolve," 
            + " with an easy user interface and features while the service from the team is exceptional."
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/03-akhil-chimnani.jpeg"
            alt="Second slide" />
          <h1 class="commit-header">Akhil CHIMNANI</h1>
          <p class="sub-header">Vice-President at Raine & Horne</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " Reidin has been a tremendous asset to our business. Apart from providing us"
            +" information on transactions, supply, trends and even project information, Reidin"
            +" helps us prepare Market Appraisal Reports & Presentations. "
            : " Reidin has been a tremendous asset to our business. Apart from providing us"
            +" information on transactions, supply, trends and even project information, Reidin"
            +" helps us prepare Market Appraisal Reports & Presentations. "
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/04-haider-tuaima.jpeg"
            alt="Haider TUAIMA Photo" />
          <h1 class="commit-header">Haider TUAIMA</h1>
          <p class="sub-header">Head of Real Estate Research at Valustrat</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " I think REIDIN is one the most important transactional data providers catering"
            +" to the Dubai real estate market, and it has certainly come a long way since I"
            +" first started using the service back in 2007. "
            : " Reidin has been a tremendous asset to our business. Apart from providing us"
            +" information on transactions, supply, trends and even project information, Reidin"
            +" helps us prepare Market Appraisal Reports & Presentations."
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/05-taimur-khan.jpeg"
            alt="Taimur KHAN Photo" />
          <h1 class="commit-header">Taimur KHAN</h1>
          <p class="sub-header">Head of Research at CBRE</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " REIDIN’s intuitive platform allows access to their comprehensive residential and"
            +" commercial real estate data, allowing CBRE to decipher the trends within the market"
            +" from a city level overview to submarket trends and even individual projects. "
            : " REIDIN’s intuitive platform allows access to their comprehensive residential and"
            +" commercial real estate data, allowing CBRE to decipher the trends within the market"
            +" from a city level overview to submarket trends and even individual projects. "
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image"
            src="../../../assets/customers/customer-pictures/06-faisal-durrani.jpg"
            alt="Faisal Durrani Photo" />
          <h1 class="commit-header">Faisal Durrani​</h1>
          <p class="sub-header">Head of Middle East Research at Knight Frank</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>
            {{this.languageChose? " REIDIN’s intuitive Insight portal provides a superb platform for us to explore the "
            +" latest data trends in Dubai’s residential market. Additional drilldown features help to unpick submarket"
            +" level nuances that are critical in understanding Dubai’s complex residential market landscape. "
            : " REIDIN’s intuitive Insight portal provides a superb platform for us to explore the "
            +" latest data trends in Dubai’s residential market. Additional drilldown features help to unpick submarket"
            +" level nuances that are critical in understanding Dubai’s complex residential market landscape. "}}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image"
            src="../../../assets/customers/customer-pictures/07-kashesh-jashnani.jpg"
            alt="Faisal Durrani Photo" />
          <h1 class="commit-header">Kashesh Jashnani​</h1>
          <p class="sub-header">Research Manager at Wasl Group</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>
            {{this.languageChose? " Since partnering with REIDIN, understanding of the Dubai Real Estate Market has become significantly more efficient. "
            +" Their high-quality data allows us to make data-driven decisions,"
            +" identify lucrative opportunities as well as implement strategic decisions accordingly. "
            : " Since partnering with REIDIN, understanding of the Dubai Real Estate Market has become significantly more efficient. "
            +" Their high-quality data allows us to make data-driven decisions,"
            +" identify lucrative opportunities as well as implement strategic decisions accordingly. "}}
              
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
    </div>
    
  </div>
  <div (mouseover)="disableCustomerScroll()" (mouseout)="enableCustomerScroll()" id="desktop-customer" class="customer">
    <img class="customer-logo" src="../../../assets/customers/01-savills.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/02-first-abu-dhabi-bank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/03-dubai-holding.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/04-dubai-properties.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/05-standard-chartered.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/06-deloitte.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/08-uabank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/09-mashreqbank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/10-keller-williams.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/11-central-bank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/12-hsbc.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/13-yapikredi.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/14-ziraat-bankasi.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/15-akbank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/16-papa-johns.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/17-sur-yapi.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/18-turknet.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/19-teb.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/20-garanti.png" alt="" />
    <img style="margin-right: 0px !important" class="customer-logo" src="../../../assets/customers/21-houza.png"
      alt="" />
  </div>
</div>

<div class="col-md-12 mobile" *ngIf="!isDesktop">
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <a class="nav-item nav-link" [ngClass]="{'active':activeSlide==0}" id="nav-reports-tabM" data-toggle="tab"
        (click)="navClick($event)" href="#nav-reportsM" role="tab" aria-controls="nav-reportsM" aria-selected="true">{{
        this.languageChose ?
        "Reports" : "Raporlar" }}</a>
      <a class="nav-item nav-link" [ngClass]="{'active':activeSlide==1}" id="nav-rankings-tabM" data-toggle="tab"
        (click)="navClick($event)" href="#nav-rankingsM" role="tab" aria-controls="nav-rankingsM"
        aria-selected="false">{{ this.languageChose ?
        "Rankings" : "Sıralamalar" }}</a>
      <a class="nav-item nav-link" [ngClass]="{'active':activeSlide==2}" id="nav-trends-tabM" data-toggle="tab"
        (click)="navClick($event)" href="#nav-trendsM" role="tab" aria-controls="nav-trendsM" aria-selected="false">{{
        this.languageChose ? "Trends" : "Trendler"
        }}</a>
      <a class="nav-item nav-link" [ngClass]="{'active':activeSlide==3}" id="nav-data-tabM" data-toggle="tab"
        (click)="navClick($event)" href="#nav-dataM" role="tab" aria-controls="nav-dataM" aria-selected="false">{{
        this.languageChose ? "Data" : "Veri" }}</a>
    </div>
  </nav>
  <div id="carouselMobile" class="carousel slide" (touchstart)="touchStart()" (touchEnd)="touchEnd()"
    (touchmove)="touchMove($event)" data-interval="false" data-touch="false">
    <ol class="carousel-indicators">
      <li (click)="liClick($event)" data-target="#carouselMobile" id="indicator1" data-slide-to="0" class="slider-point"
        [ngClass]="{'active':activeSlide==0}">
      </li>
      <li (click)="liClick($event)" data-target="#carouselMobile" id="indicator2" data-slide-to="1" class="slider-point"
        [ngClass]="{'active':activeSlide==1}"></li>
      <li (click)="liClick($event)" data-target="#carouselMobile" id="indicator3" data-slide-to="2" class="slider-point"
        [ngClass]="{'active':activeSlide==2}"></li>
      <li (click)="liClick($event)" data-target="#carouselMobile" id="indicator4" data-slide-to="3" class="slider-point"
        [ngClass]="{'active':activeSlide==3}"></li>
    </ol>
    <div class="carousel-inner">
      <div id="slide1" class="carousel-item" [ngClass]="{'active carousel-animation':activeSlide==0}">
        <img class="d-block w-100" src=".././../../assets/01-reports.png" alt="First slide" />
      </div>
      <div id="slide2" class="carousel-item" [ngClass]="{'active carousel-animation':activeSlide==1}">
        <img class="d-block w-100" src=".././../../assets/02-rankings.png" alt="Second slide" />
      </div>
      <div id="slide3" class="carousel-item" [ngClass]="{'active carousel-animation':activeSlide==2}">
        <img class="d-block w-100" src=".././../../assets/03-trends.png" alt="Third slide" />
      </div>
      <div id="slide4" class="carousel-item" [ngClass]="{'active carousel-animation':activeSlide==3}">
        <img class="d-block w-100" src=".././../../assets/04-data.png" alt="Fourth slide" />
      </div>
    </div>
  </div>
  <div style="margin-top: 10%" id="carouselCustomerCommentsControlsMobile" class="carousel slide customer-comments"
    data-ride="carousel">
    <div class="container carousel-control">
      <a class="carousel-control-prev" href="#carouselCustomerCommentsControlsMobile" role="button" data-slide="prev">
      </a>
      <a class="carousel-control-next" href="#carouselCustomerCommentsControlsMobile" role="button" data-slide="next">
      </a>
    </div>
    <ol style="bottom: -54px !important" class="carousel-indicators bottom-text-dot">
      <li data-target="#carouselCustomerCommentsControlsMobile" data-slide-to="0" class="slider-point active"></li>
      <li data-target="#carouselCustomerCommentsControlsMobile" data-slide-to="1" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControlsMobile" data-slide-to="2" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControlsMobile" data-slide-to="3" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControlsMobile" data-slide-to="4" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControlsMobile" data-slide-to="5" class="slider-point"></li>
      <li data-target="#carouselCustomerCommentsControlsMobile" data-slide-to="6" class="slider-point"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/01-sohail-raja.jpg"
          alt="Sohail Raja Photo" />
        <h1 class="commit-header">Sohail Raja</h1>
        <p class="sub-header">Valuations Manager</p>
        <p class="p-style">
          <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
          this.languageChose
          ? " Reidin has brought transparency to the Dubai Market and is an invaluable resource in helping"
          +" in all of Hamptons Real Estate needs. They have been extremely professional at all times with"
          +" excellent overall service. Reidin is the foremost tool for anyone requiring up to date Market insights. "
          :" Reidin has brought transparency to the Dubai Market and is an invaluable resource in helping"
          +" in all of Hamptons Real Estate needs. They have been extremely professional at all times with"
          +" excellent overall service. Reidin is the foremost tool for anyone requiring up to date Market insights. "
          }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/02-prathyusha-gurrapu.jpg"
            alt="Prathyusha Gurrapu Photo" />
          <h1 class="commit-header">Prathyusha Gurrapu</h1>
          <p class="sub-header">Head of Research & Consultancy at Cushman Wakefield Core</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " REIDIN is an important data tool that we use extensively to analyse the wider" 
            + " macro-market as well as drill into sub-markets and individual projects." 
            + " It helps us to uncover the latest trends along with gaining an in-depth" 
            + " and nuanced understanding of the market. The intuitive platform continues to evolve," 
            + " with an easy user interface and features while the service from the team is exceptional."
            : " REIDIN is an important data tool that we use extensively to analyse the wider" 
            + " macro-market as well as drill into sub-markets and individual projects." 
            + " It helps us to uncover the latest trends along with gaining an in-depth" 
            + " and nuanced understanding of the market. The intuitive platform continues to evolve," 
            + " with an easy user interface and features while the service from the team is exceptional."
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/03-akhil-chimnani.jpeg"
            alt="Second slide" />
          <h1 class="commit-header">Akhil CHIMNANI</h1>
          <p class="sub-header">Vice-President at Raine & Horne</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " Reidin has been a tremendous asset to our business. Apart from providing us"
            +" information on transactions, supply, trends and even project information, Reidin"
            +" helps us prepare Market Appraisal Reports & Presentations. "
            : " Reidin has been a tremendous asset to our business. Apart from providing us"
            +" information on transactions, supply, trends and even project information, Reidin"
            +" helps us prepare Market Appraisal Reports & Presentations. "
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/04-haider-tuaima.jpeg"
            alt="Haider TUAIMA Photo" />
          <h1 class="commit-header">Haider TUAIMA</h1>
          <p class="sub-header">Head of Real Estate Research at Valustrat</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " I think REIDIN is one the most important transactional data providers catering"
            +" to the Dubai real estate market, and it has certainly come a long way since I"
            +" first started using the service back in 2007. "
            : " Reidin has been a tremendous asset to our business. Apart from providing us"
            +" information on transactions, supply, trends and even project information, Reidin"
            +" helps us prepare Market Appraisal Reports & Presentations."
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image" src="../../../assets/customers/customer-pictures/05-taimur-khan.jpeg"
            alt="Taimur KHAN Photo" />
          <h1 class="commit-header">Taimur KHAN</h1>
          <p class="sub-header">Head of Research at CBRE</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>{{
            this.languageChose
            ? " REIDIN’s intuitive platform allows access to their comprehensive residential and"
            +" commercial real estate data, allowing CBRE to decipher the trends within the market"
            +" from a city level overview to submarket trends and even individual projects. "
            : " REIDIN’s intuitive platform allows access to their comprehensive residential and"
            +" commercial real estate data, allowing CBRE to decipher the trends within the market"
            +" from a city level overview to submarket trends and even individual projects. "
            }}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image"
            src="../../../assets/customers/customer-pictures/06-faisal-durrani.jpg"
            alt="Faisal Durrani Photo" />
          <h1 class="commit-header">Faisal Durrani​</h1>
          <p class="sub-header">Head of Middle East Research at Knight Frank</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>
            {{this.languageChose? " REIDIN’s intuitive Insight portal provides a superb platform for us to explore the "
            +" latest data trends in Dubai’s residential market. Additional drilldown features help to unpick submarket"
            +" level nuances that are critical in understanding Dubai’s complex residential market landscape. "
            : " REIDIN’s intuitive Insight portal provides a superb platform for us to explore the "
            +" latest data trends in Dubai’s residential market. Additional drilldown features help to unpick submarket"
            +" level nuances that are critical in understanding Dubai’s complex residential market landscape. "}}
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
      <div class="carousel-item">
        <div style="text-align: center" class="container">
          <img class="d-block profile-image"
            src="../../../assets/customers/customer-pictures/07-kashesh-jashnani.jpg"
            alt="Faisal Durrani Photo" />
          <h1 class="commit-header">Kashesh Jashnani​</h1>
          <p class="sub-header">Research Manager at Wasl Group</p>
          <p class="p-style">
            <i style="color: #5bb25e" class="fas fa-quote-left"></i>
            {{this.languageChose? " Since partnering with REIDIN, understanding of the Dubai Real Estate Market has become significantly more efficient. "
            +" Their high-quality data allows us to make data-driven decisions,"
            +" identify lucrative opportunities as well as implement strategic decisions accordingly. "
            : " Since partnering with REIDIN, understanding of the Dubai Real Estate Market has become significantly more efficient. "
            +" Their high-quality data allows us to make data-driven decisions,"
            +" identify lucrative opportunities as well as implement strategic decisions accordingly. "}}
              
            <i style="color: #5bb25e" class="fas fa-quote-right"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-top: 15%" id="mobile-customer" class="customer">
    <img class="customer-logo" src="../../../assets/customers/01-savills.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/02-first-abu-dhabi-bank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/03-dubai-holding.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/04-dubai-properties.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/05-standard-chartered.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/06-deloitte.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/08-uabank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/09-mashreqbank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/10-keller-williams.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/11-central-bank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/12-hsbc.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/13-yapikredi.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/14-ziraat-bankasi.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/15-akbank.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/16-papa-johns.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/17-sur-yapi.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/18-turknet.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/19-teb.png" alt="" />
    <img class="customer-logo" src="../../../assets/customers/20-garanti.png" alt="" />
    <img style="margin-right: 0px !important" class="customer-logo" src="../../../assets/customers/21-houza.png"
      alt="" />
  </div>
  <img style="background-color: #f7f8fa; width: 100%" src="../../../assets/images/reidin-logo-insight-login.svg"
    alt="" />
</div>