<h1 mat-dialog-title>Save Filter</h1>
<div mat-dialog-content>
    <mat-form-field appearance="outline" class="filter-name-input">
        <mat-label>Filter Name</mat-label>
        <input matInput [formControl]="filterName">
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button (click)="saveDashboardFilter()" color="accent">Save</button>
</div>