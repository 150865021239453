import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-policy-on-protection-of-personal-data',
  templateUrl: './policy-on-protection-of-personal-data.component.html',
  styleUrls: ['./policy-on-protection-of-personal-data.component.scss']
})
export class PolicyOnProtectionOfPersonalDataComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}
  navigateToLogin() {
    this.router.navigateByUrl('/auth/login');
  }
}
