<ol style="list-style-type: none">
  <li>
      <p>The terms “we", "our", "us" means REIDIN FZ LLC or herein as “REIDIN” and its
          subsidiaries, employees, officers, agents, affiliates or assigned parties.</p>
      <p>The information contained on <a href="http://www.reidin.com">www.reidin.com</a>
          website (the "Service") is for general
          information purposes only.</p>
      <p>The information on web pages have been prepared and processed with reasonable care and is
          believed by us to be honest, reliable, no warrant, expressed or implied is made
          regarding accuracy, adequacy, completeness, legality, reliability or usefulness of any
          information.</p>
      <p>REIDIN FZ LLC assumes no responsibility for errors or omissions in the contents on the
          Service and information available to users.</p>
      <p>In no event shall REIDIN be liable for any special, direct, indirect, consequential, or
          incidental damages or any damages whatsoever, whether in an action of contract,
          negligence or other tort, arising out of or in connection with the use of the Service or
          the contents of the Service. REIDIN reserves the right to make additions, deletions, or
          modification to the contents on the Service at any time without prior notice. </p>
      <p>You agree that the material downloaded or otherwise accessed through the use of our web
          pages is obtained entirely at your own risk and that you will be entirely responsible
          for any resulting damage to software or computer systems and/or any resulting loss of
          data even if we have been advised of the possibility of any such damage. </p>

  </li>
  <li>
      <h4 class="privacyPolicyHeader">External links disclaimer</h4>
      <p><a href="http://www.reidin.com">www.reidin.com </a>website may contain links to
          external websites that are not
          provided or maintained by or in any way affiliated with REIDIN</p>
      <p>Please note that the REIDIN does not guarantee the accuracy, relevance, timeliness, or
          completeness of any information on these external websites.</p>
  </li>
</ol>