import { inject, DestroyRef, signal } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { ToastrService } from "ngx-toastr"
import { AuthenticationService } from "@insight-services/authentication.service"
import {
    NotificationFilter,
    NotificationFilterResponse,
    NotificationService
} from "@insight-services/notification.service"
import { PowerBIService } from "@insight-services/power-bi.service"
import { FormControl } from "@angular/forms"
import { MatTableDataSource } from "@angular/material/table"
import { PowerBIReportEmbedComponent } from "powerbi-client-angular"
import { models } from "powerbi-client"
import { FilterSchema } from "@insight-models/filter-schema"
import { IHttpPostMessageResponse } from 'http-post-message';
import { tap } from "rxjs/operators"

export abstract class BaseNotification<T extends NotificationFilter, K extends NotificationFilterResponse> {
    protected readonly authService = inject(AuthenticationService)
    protected readonly powerBiService = inject(PowerBIService)
    protected readonly notificationService = inject(NotificationService)
    protected readonly toastrService = inject(ToastrService)
    protected readonly destroyRef = inject(DestroyRef)
    protected readonly dialog = inject(MatDialog)

    protected filterMapping: Record<string, string | string[]>

    protected filterForm = signal<Partial<T>>(undefined)
    protected frequencyForm = new FormControl<1 | 7 | 30>(7)
    protected email = this.authService.getUser().email
    protected edittingNotification = signal<number>(undefined);

    protected data = new MatTableDataSource<K>();
    protected powerBIReportComponent = signal<PowerBIReportEmbedComponent>(undefined)

    protected GetNotificationFilters(dashboardId: number) {
        return this.notificationService
            .getNotificationFilters<K>(dashboardId);
    }

    protected setPowerBIComponent(reportComponent: PowerBIReportEmbedComponent) {
        this.powerBIReportComponent.set(reportComponent);
    }

    protected async getSlicers(reportComponent: PowerBIReportEmbedComponent) {
        const report = reportComponent.getReport()
        const slicerStates = await this.powerBiService.getSelectedSlicerStates(report)
        const notification: Partial<T> = {}

        for (let slicerState of slicerStates) {
            const filters = slicerState.filters;
            const columnName = (slicerState.targets[0] as models.IFilterColumnTarget).column
            const propertyName: string | string[] = this.filterMapping[columnName]

            if (this.isFilterHierarchy(filters[0])) {
                const filter = filters[0] as models.IHierarchyFilter
                this.getHierarchyFilterData(filter, notification, propertyName as string[])
            }

            if (this.isFilterBasic(filters[0])) {
                const filter = filters[0] as models.IBasicFilter
                this.getBasicFilterData(filter, notification, propertyName as string)
            }
        }

        this.filterForm.set(notification)
    }

    protected saveNotification() {
        if (this.filterForm() && Object.keys(this.filterForm()).length > 0) {
            this.notificationService.saveNotification(this.filterForm(), this.frequencyForm.value)
                .subscribe({
                    next: savedNotification => {
                        this.toastrService.success("Notification created succesfully", "Successful")
                        const data = this.data.data;
                        const notification = {
                            ...savedNotification.data.filter,
                            id: savedNotification.pk,
                            email: savedNotification.data.user_mail,
                            frequency: savedNotification.data.frequency
                        }
                        data.unshift(notification)
                        this.data.data = data;
                    },
                    error: (err) => {
                        this.toastrService.error("Notification with given filters is already exist", "Error")
                    }
                });
        }
        else {
            this.toastrService.warning("At least one filter must be applied", "Warning")
        }
    }

    protected saveEdit() {
        this.notificationService.updateNotification(this.filterForm(),
            this.frequencyForm.value
            , this.edittingNotification())
            .subscribe({
                next: edittedNotification => {
                    this.toastrService.success("Notification edited succesfully");
                    const tableData = this.data.data;
                    const notificationToEditIndex = tableData.findIndex(x => x.id === this.edittingNotification())
                    const notification = {
                        ...edittedNotification.data.filter,
                        id: edittedNotification.pk,
                        email: edittedNotification.data.user_mail,
                        frequency: edittedNotification.data.frequency
                    }
                    tableData[notificationToEditIndex] = notification
                    this.data.data = tableData
                    this.edittingNotification.set(undefined)
                },
                error: _ => {
                    this.toastrService.error("Notification could not edited");
                }
            })
    }

    protected deleteNotification(notification: K) {
        /* const dialogRef = this.dialog.open(
          DeleteNotificationModalComponent<SaleTransactionNotificationFilterResponse>,
          {
            data: notification,
          }
        )
    
        dialogRef.afterClosed().pipe(
          takeUntilDestroyed(this.destroyRef),
          filter(Boolean),
          tap((id: number) => {
            const indexOfItem = this.data.data.findIndex(notification => notification.id === id)
            const data = this.data.data
            data.splice(indexOfItem, 1);
            this.data.data = data;
          })
        ).subscribe() */

        this.notificationService.deleteNotification(notification)
            .pipe(tap((id: number) => {
                const indexOfItem = this.data.data.findIndex(notification => notification.id === id)
                const data = this.data.data
                data.splice(indexOfItem, 1);
                this.data.data = data;
            }))
            .subscribe({
                next: () => this.toastrService.success("Notification is deleted successfully", "Success"),
                error: () => this.toastrService.success("Notification could not deleted", "Error"),
            })
    }


    protected async edit(notification: K, isTargetHierarchyFunc: (slicerState: models.ISlicerState) => boolean) {
        if (!this.powerBIReportComponent()) {
            this.toastrService.warning("Please wait until the filters get loaded", "Warning")
            return
        }

        this.edittingNotification.set(notification.id)
        this.frequencyForm.setValue(notification.frequency)

        const report = this.powerBIReportComponent().getReport()
        const slicers = await this.powerBiService.getSlicers(report)
        const setStates: Promise<IHttpPostMessageResponse<void>>[] = []

        for (let slicer of slicers) {
            const slicerState = await slicer.getSlicerState();
            const slicerColumnName = (slicerState.targets[0] as models.IFilterColumnTarget).column
            const propertyName: string | string[] = this.filterMapping[slicerColumnName]
            const isTargetHierarchy = isTargetHierarchyFunc(slicerState)

            if (!isTargetHierarchy) {
                const table = slicerState.targets[0].table
                const value = notification[propertyName as string]

                if (!value) {
                    const setState = slicer.setSlicerState({ filters: [] })
                    setStates.push(setState)
                    continue
                }

                const basicfilter: models.IBasicFilter = {
                    $schema: FilterSchema.basic,
                    target: {
                        table: table,
                        column: slicerColumnName
                    },
                    filterType: 1,
                    operator: "In",
                    values: [
                        value
                    ]
                }

                const setState = slicer.setSlicerState({
                    filters: [
                        basicfilter
                    ]
                })
                setStates.push(setState)
                continue;
            }

            if (isTargetHierarchy) {
                const parentPropertyName = (propertyName as string[])[0]
                const childPropertyName = (propertyName as string[])[1]

                const parentValue = notification[parentPropertyName]
                const childValue = notification[childPropertyName]

                if (!parentValue) {
                    const setState = slicer.setSlicerState({ filters: [] })
                    setStates.push(setState)
                    continue
                }

                const hierarchyData: models.IHierarchyFilterNode = {
                    children: childValue ? [
                        {
                            operator: "Selected",
                            value: childValue
                        }
                    ] : undefined,
                    operator: childValue ? "Inherited" : "Selected",
                    value: parentValue
                }

                const hierarchyFilter: models.IHierarchyFilter = {
                    $schema: FilterSchema.hierarchy,
                    target: slicerState.targets,
                    filterType: 9,
                    hierarchyData: [
                        hierarchyData
                    ]
                }

                const setState = slicer.setSlicerState({
                    filters: [
                        hierarchyFilter
                    ]
                })

                setStates.push(setState)
                continue;
            }
        }
        await Promise.all(setStates)
    }

    protected exitEdit() {
        this.edittingNotification.set(undefined)
    }

    protected isEditting(): boolean {
        return this.edittingNotification() !== undefined
    }

    protected getBasicFilterData(filter: models.IBasicFilter, notification: Partial<T>, propertyName: string) {
        const basicData = filter.values[0]

        notification[propertyName] = basicData
    }

    protected getHierarchyFilterData(filter: models.IHierarchyFilter, notification: Partial<T>, propertyName: string[]) {
        const hierarchyData = filter.hierarchyData[0];

        notification[propertyName[0]] = hierarchyData.value

        if (hierarchyData.children)
            notification[propertyName[1]] = hierarchyData.children[0].value
    }

    protected isFilterBasic(filter: models.ISlicerFilter) {
        return filter.$schema === FilterSchema.basic
    }

    protected isFilterHierarchy(filter: models.ISlicerFilter) {
        return filter.$schema === FilterSchema.hierarchy
    }
}