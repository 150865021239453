import { HttpClient } from '@angular/common/http';
import { Injectable, WritableSignal } from '@angular/core';
import { environment } from '@insight-environments/environment';
import { interval, Observable } from 'rxjs';
import { switchMap, tap, filter, takeWhile } from 'rxjs/operators';
import { models } from 'powerbi-client'
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { IPowerBiPDFExportResult } from '@insight-models/power-bi-pdf-export-result';
import { IPowerBiPDFExportStatusResult } from '@insight-models/power-bi-pdf-export-status-result';
import { Report } from 'powerbi-client';
import { IPowerBiEmbedTokenResponse } from '@insight-models/powerbi-embed-token-response';
import { IPowerBiReportResponse } from '@insight-models/powerbi-report-response';
import { IPowerBiTokenResponse } from '@insight-models/powerbi-token-response';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { CurrentReportInfo } from '@insight-models/current-report-info';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService {
  capturedBookmark: models.IReportBookmark;
  currentReport: CurrentReportInfo;
  report: Report;

  constructor(
    private readonly http: HttpClient,
    private toastrService: NbToastrService,
    public translateService: TranslateService,
  ) { }

  private exportToFile(dashboardId: string, currentReport: CurrentReportInfo, type: 'pdf' = 'pdf') {
    const body = {
      format: type,
      powerBIReportConfiguration: {
        defaultBookmark: {
          state: currentReport.capturedBookmark.state
        },
        pages: [
          {
            /* bookmark: {
              name: "",
              state: ""
            }, */
            pageName: currentReport.pageName,
          }
        ]
      }
    }
    return this.http.post<IPowerBiPDFExportResult>(`${environment.powerBIRestAPIUrl}reports/${dashboardId}/ExportTo`, body)
  }

  private checkExportStatus(dashboardId: string, exportResultId: string) {
    return this.http.get<IPowerBiPDFExportStatusResult>(`${environment.powerBIRestAPIUrl}reports/${dashboardId}/exports/${exportResultId}`)
  }

  exportDashboard(fileName: string, dashboardId?: string, type: 'pdf' = 'pdf') {
    let percentComplete = 0;
    return this.exportToFile(dashboardId, this.currentReport, type)
      .pipe(
        switchMap(exportResult =>
          interval(2500)
            .pipe(
              switchMap(_ =>
                this.checkExportStatus(dashboardId, exportResult.id)
                  .pipe(
                    tap(exportStatus => {
                      percentComplete = exportStatus.percentComplete
                      if (exportStatus.status === 'Failed')
                        this.toastrService.danger(this.translateService.instant('main.general.messages.error.pdf-export-failed'), this.translateService.instant('main.general.messages.title.error'));
                    }),
                    filter(exportStatus => exportStatus.percentComplete >= 100 && exportStatus.status == 'Succeeded'),
                    tap(exportStatus => {
                      this.downloadFile(exportStatus.resourceLocation, fileName)
                    })
                  )
              ),
              takeWhile((exportStatus) => percentComplete < 100 && exportStatus.status === 'Running'),
            )
        ),
      )
  }

  private downloadFile(resourceUrl: string, fileName: string) {
    this.http.get(resourceUrl, { responseType: 'blob' })
      .subscribe({
        next: (response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.download = fileName + '.pdf';

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // window.open(url);
          URL.revokeObjectURL(url);
        }
      })
  }

  getPowerBiToken(): Observable<IPowerBiTokenResponse> {
    return this.http
      .get<IPowerBiTokenResponse>(environment.backendAPIUrl + "/api/powerbi-token")
  }

  getReport(reportId: string) {
    return this.http.get<IPowerBiReportResponse>(`${environment.powerBIRestAPIUrl}reports/${reportId}`)
  }

  getEmbedToken(datasetId: string, reportId: string) {
    const body = {
      datasets: [
        {
          id: datasetId
        }
      ],
      reports: [
        {
          id: reportId
        }
      ]
    }
    return this.http.post<IPowerBiEmbedTokenResponse>(`${environment.powerBIRestAPIUrl}GenerateToken`, body)
  }

  async onLoaded(reportComponent: PowerBIReportEmbedComponent) {
    const report = reportComponent.getReport()
    report.setComponentTitle('Embedded report')
  }

  async onRendered(reportComponent: PowerBIReportEmbedComponent, isPowerBIEmbedRendered: WritableSignal<boolean>) {
    const report = reportComponent.getReport()
    this.report = report
    const activePage = await report.getActivePage()
    const capturedBookmark = await report.bookmarksManager.capture()
    this.capturedBookmark = capturedBookmark
    const currentReport: CurrentReportInfo = {
      capturedBookmark: capturedBookmark,
      pageName: activePage.name
    }
    this.currentReport = currentReport
    isPowerBIEmbedRendered.set(true)
    report.element.style.setProperty("visibility", "visible")
  }

  async getSelectedSlicerStates(report: Report) {
    let filteredSlicers: models.ISlicerState[] = [];

    const page = await report.getActivePage()

    const visuals = await page.getVisuals()

    const slicers = visuals.filter(visual => visual.type === "slicer")

    for (let slicer of slicers) {
      //console.log((((await slicer.getFilters())[0])))
      const slicerState = await slicer.getSlicerState();

      if (slicerState.filters.length > 0 && (slicerState.filters[0].target as models.IFilterColumnTarget).column !== "Date")
        filteredSlicers.push(slicerState)
    }

    //const notificationFilter: Record<string, string> = {}

    /* for (let slicer of filteredSlicers) {
      const columnNameWords = (slicer.filters[0].target as models.IFilterColumnTarget).column.split(" ").map((word, index) => {
        if (index === 0)
          return word.toLocaleLowerCase()
        return word
      })
      const propertyName = columnNameWords.join("")
      console.log(propertyName + "  --  " + (slicer.filters[0] as models.IBasicFilter).values[0])
    } */

    return filteredSlicers
  }

  async getSlicers(report: Report) {
    const page = await report.getActivePage()

    const visuals = await page.getVisuals()

    const slicers = visuals.filter(visual => visual.type === "slicer")

    return slicers;
  }

  private async getSlicersAndSetSliceState(report: Report) {
    let filteredSlicers: models.ISlicerState[] = [];

    const page = await report.getActivePage()

    const visuals = await page.getVisuals()

    const slicers = visuals.filter(visual => visual.type === "slicer")

    slicers.forEach(async slicer => {

      //console.log((((await slicer.getFilters())[0])))

      const slicerState = await slicer.getSlicerState();

      if ((slicerState.targets[0] as any).column === "Transaction Type") {
        filteredSlicers.push(slicerState)
        const slicerStateFromLocalStorage = JSON.parse(localStorage.getItem("slicerState")) as any
        try {
          /* await slicer.setSlicerState({
            filters: slicerStateFromLocalStorage.filters
          }) */

          const test: models.IBasicFilter = {
            "$schema": "http://powerbi.com/product/schema#basic",
            "target": {
              "table": "append_dld_trx_rents",
              "column": "Transaction Type"
            },
            "filterType": 1,
            "operator": "In",
            "values": [
              "Sales - Off-Plan"
            ]
          }

          /* await slicer.setSlicerState({
            filters: [
              test
            ]
          }) */

        } catch (error) {
          console.log(error)
        }
      }
      //filteredSlicers.push(slicerState)
    })

    console.log(filteredSlicers)

  }
}
