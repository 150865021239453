import { Component, OnInit } from '@angular/core';
import { BlogfeedService } from '../../../../services/blogfeed.service';
// import { NbPopoverDirective } from '@nebular/theme';

@Component({
  selector: 'ngx-notificationlist',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  providers: [BlogfeedService]
})
export class NotificationListComponent implements OnInit {
  // @Input() popover: NbPopoverDirective;

  news = [];
  placeholders = [];
  resp: any;
  displayControl: boolean=false
  constructor(private blogService: BlogfeedService) {

  }

  ngOnInit() {
    this.blogService.load()
      .subscribe(data => {
        console.log(data);
        this.resp = data;
        this.news = data.items;
        if(data.items!=null) {
          this.displayControl=true;
        } else{
          this.displayControl=false;
        }
      });
  }
}
