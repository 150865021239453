export const tr = {
  "main": {
    "language": {
      "eng": "English",
      "tur": "Türkçe"
    },
    "general": {
      "buttons": {
        "apply": "Uygula",
        "remember-me": "Beni Hatırla",
        "sign-in": "Giriş",
        "sign-up": "Üye Ol",
        "logout": "Çıkış",
        "preferences": "Tercihler",
        "forgot-password": "Şifremi unuttum",
        "help": "Yardım",
        "privacy": "Gizlilik",
        "clear": "Temizle",
        "all": "Tümü",
        "search": "Ara",
        "save": "Kaydet",
        "okay": "Tamam",
        "approve": "Onayla",
        "register": "Kaydol",
        "multiple-device": "Coklu Giris",
        "delete": "Sil",
        "clear-all": "Tümünü Temizle",
        "clear-layers": "Katmanları Temizle",
        "clear-drawings": "Çizimleri Temizle",
        "cancel": "İptal",
        "close": "Kapat",
        "edit": "Düzenle",
        "filter": "Filtrele",
        "show-map": "Harita Göster",
        "upload": "Yükle",
        "show-project-details": "Proje Detaylarını Göster",
        "show-all": "Tümünü Göster",
        "export-to-pdf": "PDF Aktar",
        "save-filter": "Filte Kaydet",
        "my-filter": "Filtrelerim",
        "print": "Yazdır",
        "export-to-excel": "Excel'e Aktar",
        "all-stok-analysis": "Tüm Stok Analizleri",
        "search-nearest-to-me": "En Yakınımda Ara",
        "show-on-map": "Haritada Göster",
        "my-location": "Konumum",
        "zoom-in": "Yakınlaş",
        "zoom-out": "Uzaklaş",
        "return-to-project-summary": "Proje Özetine Dön",
        "detailed-filter": "Detaylı Filtreleme",
        "similar-qrids": "Benzer QRIDler",
        "compare": "Karşılaştır",
        "all-time": "Tüm Zamanlar",
        "refresh-data": "Yenile",
        "back-to-login": "Girişe geri dön",
        "privacy-terms": "Gizlilik & Şartlar",
        "privacy-policy": "Gizlilik Politikası",
        "terms-conditions": "Site Kullanım Koşulları",
        "policy-on-protection-of-personal-data": "Kişisel Verilerin Korunması"
      },
      "tooltips": {
        "residence": "Konutlar",
        "office": "Ofisler",
        "market-analysis": "Market Analizi",
        "urban-planning": "Kent Planlama",
        "transactions": "İşlemler",
        "listings": "İlanlar",
        "pois": "Önemli Noktalar",
        "map-types": "Harita Tipi",
        "qrid-layers": "QRID Katmanları",
        "customization": "Özelleştir",
        "layers": "Katmanlar",
        "my-workspace": "Çalışma Alanım",
        "delete": "Sil",
        "show": "Göster",
        "show-all-filter": "Seçimleri Göster",
        "hide": "Gizle",
        "smaller-details": "Küçült",
        "shows-socio-economic-degree": "Projenin bulunduğu mahallenin Sosyo Ekonomik Derece'sini gösterir.",
        "no-photo-found": "FOTOĞRAF YOK",
        "click-to-show-on-map": "Bunu haritada göster",
        "start-date": "Başlangıç Tarihi",
        "end-date": "Bitiş Tarihi",
        "my-data": "Benim Verilerim",
        "rivals-data": "Rakiplerimin Verileri",
        "send-all-to-the-map": "Hepsini Haritaya Gönder",
        "back-to-login": "Giriş sayfasına dön"
      },
      "autocomplete": {
        "choose-city": "Şehir seçin",
        "choose-county": "İlçe seçin",
        "choose-district": "Mahalle seçin"
      },
      "menu-items": {},
      "messages": {
        "title": {
          "success": "Başarılı",
          "error": "Hata",
          "warning": "Uyarı",
          "info": "Bilgi"
        },
        "error": {
          "page-not-found": "Aradığınız sayfa mevcut değil.",
          "go-home": "ANASAYFAYA DÖN",
          "100": "Device ID bulunamadi, lütfen sayfayı yenileyip tekrar deneyiniz!",
          "102": "Geçersiz bir kullanıcı adı girdiniz.",
          "106": "Hesabınız henüz etkinleştirilmemiş görünüyor. Daha önce bir aktivasyon e-postası almış olmalısınız.",
          "108": "Device ID kısıtlamaları yüzünden giriş izni kaldırılmış!",
          "400": "Mevcut oturum ve Device ID ile ilgili bir sorun var!",
          "401": "Geçersiz bir şifre girdiniz. Şifrelerin harf duyarlı olduğunu unutmayınız.",
          "404": "404 hatası oluştu!",
          "409": "Kullanıcı adı sistemde mevcut! Lütfen başka mail adresi deneyiniz.",
          "500": "Server hatası oluştu!",
          "101": "Bu kullanıcı hesabı için başka bir cihazda aktif oturumunuz bulunmaktadır.",
          "103": "Kullanıcı hesabınız kapatılmıştır.",
          "104": "Kullanıcı hesabınız kapatılmıştır.",
          "105": "Şifreniz en az 6 karakter olmalı",
          "107": "Coklu cihaz girisi",
          "109": "Insight ürününe erişiminiz yok. Detaylı bilgi için info@reidin.com ile iletişime geçebilirsiniz.",
          "1024": "Ip kısıtlanmış alan. Lütfen tekrar deneyiniz",
          "general-error": "Bilinmeyen hata!",
          "unauthorized-usage": "Lütfen tekrar giriş yapın!",
          "unauthorized-product": "Bu ürün için erişiminiz yoktur!",
          "invalid-activation-key": "Geçersiz bir aktivasyon anahtarı girdiniz.",
          "error-details": "Hata detayları",
          "check-your-email-password": "Email veya şifre yanlış.",
          "error": "Hata",
          "session-expired": "Aynı hesapla birden fazla giriş yaptınız. Lütfen tekrar giriş yapınız.",
          "session-not-found": "Oturum bulunamadı. Lütfen tekrar giriş yapınız.",
          "pdf-export-failed": "PDF oluşturulurken hata oluştu. Lütfen tekrar deneyiniz.",
          "2005": "Email veya şifre yanlış.",
          "1014": "Şifreniz bloke edildi. Lütfen şifremi unuttum seçeneğine tıklayın ve yeni bir şifre oluşturup tekrar deneyin.",
          "unvalid-password": "Lütfen geçerli bir şifre giriniz!",
          "unvalid-email": "Lütfen geçerli bir email giriniz!",
        },
        "warning": {
          "element-required": "{{element}} alanı zorunludur!",
          "username-required": "Kullanıcı adınızı giriniz!",
          "email-required": "E-posta zorunludur!",
          "username-invalid": "Lütfen geçerli bir e-posta adresi giriniz",
          "password-required": "Şifrenizi giriniz!",
          "password-confirmation-required": "Şifre onayı gerekli!",
          "password-confirmation-match-required": "Şifre onaylama şifresiyle eşleşmiyor.",
          "phone-required": "Telefon numarasi zorunludur!",
          "code-required": "Kodu girmeniz gereklidir!",
          "password-character-valid": "Şifreniz en az 4 karakter içermelidir",
          "full-name-character-valid": "Ad Soyad en az 6 karakter içermelidir",
          "full-name-required": "Ad Soyad Zorunludur!",
          "first-name-required": "Ad Zorunludur!",
          "second-name-required": "Soyad Zorunludur!",
          "phone-number": "Telefon numarası zorunludur!",
          "phone-number-invalid": "Lütfen geçerli bir telefon numarası giriniz",
          "company-required": "Şirket Zorunludur!",
          "form-missing": "Form bilgileri eksik!",
        },
        "success": {
          "device-id-updated": "Device ID güncellendi, sayfa yeniden yükleniyor. Tekrar giriş yapıız!",
          "successfully-login": "Başarıyla giriş yaptınız.",
          "successfully-activated": "Hesabınız başarılı bir şekilde aktifleştirilmiştir. Lütfen giriş yapınız.",
          "get-demo": "Ücretsiz Demonuzu Alın"
        },
        "info": {
          "activation-mail-sent": "Aktivasyon e-postası altta vermiş olduğunuz e-posta adresinize gönderildi. Hesabınızı etkinleştirmek için lütfen e-postada gelen linke tıklayınız.",
          "another-device-logout": "Aktif olan oturumunuz sonlandırıldı. Başarıyla giriş yaptınız.",
        }
      },
      "misc": {
        "select-all": "Tümünü Seç",
        "deselect-all": "Seçimleri Kaldır",
        "type-for-search": "Aramak için yazınız",
        "choosen-category": " kategori seçildi",
        "choose-category": "Kategori Seçiniz",
        "choosen-status": " durum seçildi",
        "choose-status": "Durum Seçiniz",
        "choosen-type": " tip seçildi",
        "choose-type": "Tip Seçiniz",
        "less": "Az",
        "more": "Çok",
        "months": {
          "01": "Ocak",
          "02": "Şubat",
          "03": "Mart",
          "04": "Nisan",
          "05": "Mayıs",
          "06": "Haziran",
          "07": "Temmuz",
          "08": "Ağustos",
          "09": "Eylül",
          "10": "Ekim",
          "11": "Kasım",
          "12": "Aralık"
        }
      },
      "placeholders": {
        "search-in-reidimap": "ReidiMap'de ara",
        "choose": "Seçiniz",
        "select": "Seçiniz",
        "all": "Tümü",
        "min": "min",
        "max": "max",
        "enter-your-message": "Mesajınızı giriniz...",
        "search-market-analysis": "Pazar Analizi ara",
        "enter-name-for-search": "Kayıt için isim giriniz",
        "search-line": "Hat Ara...",
        "search-in-defined-distance": "Belirli mesafede ara... (metre)",
        "end-date": "Bitiş Tarihi",
        "choose-type": "Tip Seçiniz",
        "select-province": "İl Seçiniz"
      },
      "search-subtitle": {
        "location" : "Konum",
        "building" : "Proje",
        "developer": "Geliştirici",
        "district": "Mahalle",
        "county": "İlçe",
        "city": "İl",
        "reports" : "Raporlar",
        "more" : "Daha fazla",
        "no-result-found" : "Sonuç bulunamadı"
      }
    },
    "views": {
      "login-page": {
        "sign-in": "Oturum Aç",
        "please-enter-your-sign-in-informations": "Lütfen oturum açma bilgilerinizi giriniz.",
        "email-or-username": "Kullanıcı Adı (E-Posta)",
        "password": "Şifre",
        "account-check": "Hesabınız yok mu?",
        "multiple-device": {
          "title": "Çoklu Giriş Onayı",
          "content": "Lütfen giriş yapabilmek için kayıtlı E-posta adresinize gönderilen kodu asağıdakı alana giriniz ve 'Onayla' butonuna basınız."
        },
        "error-detail": "Detayı görmek için tıklayın."
      },
      "logout": {
        "logout-text": "Çıkış yapılıyor. Lütfen bekleyiniz..."
      },
      "request-password": {
        "forgot-password": "Şifremi Unuttum",
        "send-link-message": "E-posta adresinizi girin, şifrenizi sıfırlamak için bir bağlantı gönderelim",
        "enter-email": "E-Posta adresinizi giriniz",
        "request-password": "Şifre İste",
        "email-address": "E-Posta adresi"
      },
      "register": {
        "sign-up-message": "Henüz REIDIN üyesi değilseniz, aşağıdan ÜCRETSİZ KAYIT olabilirsiniz ya da yetkili ofisimizden Premium Hesap isteyebilirsiniz.",
        "full-name": "Ad Soyad",
        "first-name": "Ad",
        "second-name": "Soyad",
        "email-address": "E-posta Adresi",
        "phone-number": "Telefon Numarası",
        "password": "Şifre",
        "confirm-password": "Şifreyi Onaylayın",
        "reg_button": "KAYDOLUN",
        "register": "Kaydolun",
        "already-account": "Zaten bir hesabınız var mı? "
      },
      "request-demo": {
        "request-demo": "Demo Talebi",
        "first-name": "Ad",
        "second-name": "Soyad",
        "email-address": "E-posta Adresi",
        "phone-number": "Telefon Numarası",
        "company": "Şirket",
        "industry": "Sektör",
      },
      "reset-password": {
        "title": "Şifreyi Yenile",
        "current-password": "Mevcut Şifre",
        "new-password": "Yeni Şifre",
        "confirm-password": "Şifre Onay",
        "change-password": "Şifre Degiştir"
      },
      "language-selection": {
        "title": "Dil Seçimi",
        "turkish": "Türkçe",
        "english": "English"
      },
      "locations": {
        "title": "İl/İlçe Seçimi",
        "city": "İl",
        "county": "İlçe",
        "select-city": "İl Seçin",
        "select-county": "İlçe Seçin"
      },
      "header": {
        "home": "ANASAYFA",
        "map": "HARİTA",
        "analytics": "RAKİP PAZAR ANALİZİ",
        "my-workspace": "ÇALIŞMA ALANIM",
        "data": "VERİ",
        "city": "Şehir",
        "rebis": "REBIS",
        "county": "İlçe",
        "district": "Mahalle",
        "soon-text": "Yakında",
        "user-preferences": "Kullanıcı Tercihleri",
        "select-location": {
          "title": "Bölge Seçimi"
        },
        "select-language": {
          "title": "Dil Seçimi",
          "turkish": "Türkçe",
          "english": "English"
        },
        "select-currency": {
          "title": "Para Birimi Seçimi",
          "turkish-lira": "Türk Lirası (TL)",
          "american-dollar": "Amerikan Doları (USD)",
          "euro": "Euro (EUR)",
          "uae-dirhem": "BAE Dirhemi (AED)"
        },
        "measurement-types": {
          "title": "Ölçü Birimleri",
          "sqm": "SQM",
          "sqf": "SQF"
        },
        "feedback": {
          "title": "Geri Bildirim",
          "heading": "Geri Bildirim Formu",
          "subject": "Konu",
          "submit-error": "Hata Bildirimi",
          "suggestion": "Öneri",
          "wish": "Talep",
          "thanks": "Teşekkür",
          "take-screenshot": "Ekran görüntüsü al"
        }
      },
      "overview": {
        "title": "GENEL BAKIŞ"
      },
      "residential": {
        "title": "KONUT",
        "category": "Kategori",
        "project-category": "Konut Kategorisi",
        "project-status": "Konut Durumu",
        "property-type": "Konut Tipi",
        "start-date": "Başlangıç Tarihi",
        "end-date": "Bitiş Tarihi",
        "unit-count": "Ünite Sayısı",
        "gla": "Brüt Kullanılabilir Alan (GLA)",
        "maintenance-fee": "Aidat",
        "occupancy-rate": "Doluluk Oranı",
        "rent": "Kira",
        "sale": "Satış",
        "type": "Tip",
        "price-type": "Fiyat Tipi",
        "filter-by-price": "Fiyata Göre Listele",
        "detail-filter": "Detaylı Filtreleme",
        "unit-type": "Ünite Tipi",
        "studio": "Stüdyo",
        "area": "Alan",
        "gross": "Brüt",
        "net": "Net",
        "tax-status": "KDV Durumu",
        "included": "Dahil",
        "excluded": "Hariç",
        "tax-rate": "KDV Oranı",
        "overview": "Bölge Analizi",
        "transaction": "İşlemler",
        "listing": "İlan Analizi",
        "new-home": "REIDIN™ Yeni Konut Analizi",
        "property": "Bina",
        "occupancy": "Doluluk Oranı",
        "servicecharge": "Aidat",
        "supply": "Stok",
        "markettrends": "REIDIN™ Trend Verileri",
        "areareport": "Bölge Raporu",
        "launch-trends": "Launch Trends",
        "trendanalysis": "REIDIN™ Trend Analizi"
      },
      "detail_listing": {
        "title": "DETAYLI İLAN ARAMA"
      },
      "offices": {
        "title": "OFİSLER",
        "office-category": "Ofis Kategorisi"
      },
      "office": {
        "title": "OFİS",
        "overview": "Genel Bakış",
        "listing": "İlan Analizi",
        "markettrends": "REIDIN™ Ofis Trend Verileri",
        "tranaction": "İşlemler",
        "pricetrends": "Price Trends",
        "indextrends": "Index Trends",
        "supplytrends": "Supply Trends"
      },
      "plot": {
        "title": "ARSA",
        "overview": "Genel Bakış",
        "listing": "İlan Analizi"
      },
      "industry": {
        "title": "ENDÜSTRİ",
        "overview": "Genel Bakış",
        "listing": "İlan Analizi"
      },
      "demography": {
        "title": "SOSYO DEMOGRAFİK GÖSTERGELER"
      },
      "retail": {
        "title": "PERAKENDE",
        "overview": "Genel Bakış",
        "listing": "İlan Analizi",
        "shoppingcentersupply": "Shopping Center Supply"
      },
      "market-analysis": {
        "title": "PAZAR ANALİZİ",
        "popular-searchs": "Popüler Aramalar",
        "analysis-type": "Analiz Tipi",
        "rent-market": "Kira Piyasası",
        "region": "Bölge",
        "data-type": "Veri Tipi",
        "start-date": "Başlangıç Tarihi",
        "end-date": "Bitiş Tarihi",
        "modal": {
          "edit-popular-searchs": {
            "title": "Popüler Aramaları Düzenle",
            "content": "Kayıtlı popüler aramalarınızın ismini düzenleyebilir veya kaydı tamamen silebilirsiniz."
          },
          "save-to-popular-searchs": {
            "title": "Popüler Aramalara Kaydet",
            "content": "Aramanıza isim vererek 'Popüler Aramalar' bölümüne ekleyebilirsiniz.",
            "search-name": "Arama Adı"
          }
        }
      },
      "urban-planning": {
        "title": "KENT PLANLAMA",
        "content": "Simgeler 18. zoom seviyesinde harita üzerinde gösterilmektedir.",
        "zone-plans": "İmar Planları",
        "field-usage": "Arazi Kullanımı",
        "urban-regeneration": "Kentsel Dönüşüm",
        "mega-projects": "Mega Projeler",
        "rail-systems": "Raylı Sistemler",
        "complation-status": "Tamamlanma Durumu",
        "completed": "Tamamlanan",
        "planning": "Planlanan",
        "under-contruction": "İnşaat Halinde",
        "filter-by-time": "Zamana Göre Filtrele",
        "detailed-search": "Detaylı Arama",
        "metro": "Metro",
        "metrobus": "Metrobüs",
        "select-province": "İl Seç"
      },
      "transactions": {
        "title": "İŞLEMLER",
        "sell": "Satış",
        "mortgage": "Mortgage",
        "off-plan-sell": "Off Plan Satış",
        "off-plan-mortgage": "Off Plan Mortgage",
        "rent": "Kira",
        "date": "Tarih",
        "property-type": "Emlak Tipi",
        "map-customization": "Harita Özelleştirme",
        "avarage-price-size": "Ortalama Fiyat / Ebat",
        "transaction-volume": "İşlem Hacmi",
        "number-of-transaction": "İşlem Sayısı",
        "transaction-amount": "İşlem Adedi",
        "options": "Seçenekler",
        "geometry-type": "GEOMETRİ TİPİ",
        "structure": "Yapı",
        "area": "Alan",
        "point": "Noktasal",
        "heatmap": "Sıcaklık Haritası",
        "additional-criterias": "EK KRİTERLER",
        "size": "Ebat - m²",
        "price": "Fiyat - USD",
        "price-size": "Fiyat / Ebat - USD / m²",
        "source": "Kaynak",
        "approved-transaction": "Doğrulanmış İşlem"
      },
      "listings": {
        "title": "İLANLAR",
        "sell": "Satış",
        "rent": "Kira",
        "date": "Tarih",
        "property-type": "Emlak Tipi",
        "map-customization": "Harita Özelleştirme",
        "additional-criterias": "EK KRİTERLER",
        "size": "Ebat - m²",
        "price": "Fiyat - USD",
        "price-size": "Fiyat / Ebat - USD / m²",
        "room-amount": "Oda Sayısı",
        "bathroom-count": "Banyo Sayısı",
        "avarage-price-size": "Ortalama Fiyat / Ebat",
        "listing-volume": "İlan Hacmi",
        "listing-count": "İlan Sayısı",
        "number-of-listings": "İlan Sayısı",
        "number-of-rooms": "Oda Sayısı",
        "number-of-bathroom": "Banyo Sayısı"
      },
      "important-points": {
        "title": "ÖNEMLİ NOKTALAR",
        "content": "Simgeler 18. zoom seviyesinde harita üzerinde gösterilmektedir.",
        "site-center": "Kent Merkezi",
        "industry": "Sanayi",
        "property-area": "Konut Alanı",
        "religious-facility": "Dini Tesis",
        "health": "Sağlık",
        "green-area": "Yeşil Alan",
        "turism": "Turizm",
        "entertainment": "Eğlence",
        "cultural-activity": "Kültürel Aktivite",
        "retail": "Perakende",
        "eat-drink": "Yeme İçme",
        "transportation": "Ulaşım",
        "education": "Eğitim",
        "public": "Kamu",
        "bank": "Banka",
        "business": "İş Dünyası",
        "sport-facilities": "Spor Tesisleri",
        "military-facility": "Askeri Tesis",
        "night-life": "Gece Hayatı"
      },
      "map-type": {
        "title": "HARİTA TİPİ",
        "roadmap": "Yol Haritasi",
        "satellite": "Uydu",
        "terrain": "Arazi",
        "hybrid": "Hibrit"
      },
      "qrid-layers": {
        "title": "QRID KATMANLARI",
        "social-media": "Sosyal Medya",
        "population": "Nüfus",
        "transportation": "Ulaşım",
        "poi": "POI",
        "election": "Seçim",
        "rental-price": "Kira Fiyatları",
        "qrid": "QRID",
        "score": "Skor",
        "Rehex": "QRID",
        "Social": "Sosyal",
        "Poi": "POI",
        "Transport": "Ulaşım",
        "Population": "Nüfus",
        "qrid-score": "QRID SKORU",
        "qrid-point": "QRID Puanı",
        "see-details": "Detayı Gör",
        "region-summary": "Bölge Özeti",
        "transport-density": "Ulaşım İmkanı",
        "population-density": "Nüfus Yoğunluğu",
        "social-density": "Sosyal Yoğunluk",
        "poi-density": "POI Yoğunluğu",
        "population-score": "Nüfus Skor",
        "transport-score": "Ulaşım Skor",
        "social-score": "Sosyal Skor",
        "poi-score": "POI Skor",
        "rental-prices": "Kira Fiyatları",
        "show-on-map": "Haritada Göster",
        "name": "İsim",
        "number": "Sayı",
        "distance": "Min. Mesafe",
        "point": "Puan",
        "category": "Kategori",
        "min-distance": "Min. Uzaklık",
        "closest": "En Yakında"
      },
      "customization": {
        "title": "ÖZELLEŞTİR",
        "content": "Simgeler 18. zoom seviyesinde harita üzerinde gösterilmektedir.",
        "projects": "Projeler",
        "filter": "Filtrele",
        "sell-price": "Satış Fiyatı",
        "urban-regeneration": "Kentsel Dönüşüm",
        "opacity": "Saydamlık",
        "data-selection": "Veri Seçimi",
        "stock": "Stok",
        "price": "Fiyat",
        "compilation-year": "Tamamlanma Yılı",
        "project-type": "Proje Tipi",
        "display": "Gösterim",
        "heatmap": "Sıcaklık Haritası",
        "by-size": "Büyüklüğe Göre",
        "by-type": "Tipe Göre",
        "add-tag": "Etiket Ekle",
        "yes": "EVET",
        "no": "HAYIR",
        "from-11": "11 den 15 e",
        "from-7": "7 den 11 e",
        "from-4": "4 ten 7 ye",
        "from-2": "2 den 4 e",
        "offices": "Ofisler",
        "geometry-type": "Geometri Tipi",
        "regional": "BÖLGESEL",
        "point": "NOKTASAL",
        "category": "Kategori",
        "down": "Alt",
        "up": "Üst",
        "avarage": "Ortalama",
        "rent": "Kira",
        "occupancy-rate": "Doluluk Oranı",
        "maintain-cost": "Aidat"
      },
      "layers": {
        "title": "KATMANLARI DÜZENLE",
        "show-all-tooltips": "Tüm Tooltiplei Göster",
        "open-all-layers": "Tüm Katmanları Aç",
        "development-plans": "İmar Planları",
        "field-usage": "Arazi Kullanımı",
        "urban-regeneration": "Kentsel Dönüşüm",
        "mega-projects": "Mega Projeler",
        "opacity": "Opaklık"
      },
      "my-works": {
        "title": "ÇALIŞMA ALANIM",
        "my-reports": "Raporlarım",
        "my-drawings": "Çizimlerim",
        "storage": "Depolama Alanı"
      },
      "my-data": {
        "title": "BENİM VERİLERİM",
        "uploading": "Yükleniyor",
        "eta": "Kalan",
        "done": "Tamamlandı",
        "send-to-map": "Haritaya Gönder",
        "first-row": "İlk satır kolon isimleri olmalı",
        "first-three-columns": "İlk 3 kolon isimleri 'Name’, 'Lat’, ‘Lng’ olmalı",
        "cell-values": "Hücre değerleri Value olmalı Format değil",
        "supported-file": "Desteklenen dosya tipleri 'xls' ve 'xlsx'",
        "modal": {
          "edit-file-name-title": "Dosya Adını Düzenle",
          "edit-file-name": "Dosya adını duzenleyip kaydedebilirsiniz.",
          "file-name": "Dosya Adı",
          "enter-file-name": "Dosya adı giriniz"
        }
      },
      "rivals-data": {
        "title": "RAKİPLERİMİN VERİLERİ"
      },
      "data": {
        "project": "PROJE",
        "transaction": "SATIŞLAR",
        "listing": "İLAN",
        "company": "ŞİRKET",
        "trend": "TREND",
        "agent_broker": "EMLAKÇI",
        "developer": "GELİŞTİRİCİ",
        "ressupplydata": "KONUT STOK VERİSİ"
      },
      "analytics": {
        "title": "Analitik",
        "overview": "Bölge Raporu",
        "cma": "Rakip Pazar Analizi",
        "areareport": "BÖLGE RAPORU"
      }
    },
    "project-details": {
      "population": "NÜFUS",
      "age": "Yaş",
      "average-age": "YAŞA GÖRE NÜFUS DAĞILIMI",
      "married": "Evli",
      "single": "Bekar",
      "education": "EĞİTİM",
      "college": "Üniversite",
      "secondary-school": "Orta Öğretim",
      "primary-school": "İlk Öğretim",
      "uneducated": "Eğitimsiz",
      "transportation": "ULAŞIM",
      "filter": "FİLTRELE",
      "total-checkin": "TOPLAM CHECK-IN",
      "unique-visitor": "TEKİL ZİYARETÇİ",
      "footfall": "FOOTFALL",
      "total-checkin-small": "Toplam Check-In",
      "unique-visitor-small": "Tekil Ziyaretçi",
      "footfall-small": "Footfall",
      "categories": "Kategoriler",
      "top-categories-big-caps": "EN YOĞUN 5 KATEGORİ",
      "select-all": "Hepsini Seç",
      "deselect-all": "Seçimleri Kaldır",
      "search-categories": "Kategori adı yazınız",
      "no-data": "Veri bulunamadı!",
      "popular-places": "POPÜLER MEKANLAR",
      "place": "Mekan",
      "category": "Kategori",
      "check-in-difference": "Check-in Değişimi",
      "total": "Toplam",
      "total-user": "Toplam Ziyaret",
      "price": "Ücret",
      "rating": "Puan",
      "region-total": "QRID TOPLAM CHECK-IN GRAFİĞİ",
      "count": "Check-in Toplamı",
      "month": "Ay",
      "user-count": "Toplam Ziyaret",
      "total-checkin-small-caps": "Toplam Check-in",
      "category-graph": "KATEGORİ GRAFİĞİ",
      "all": "TÜMÜ",
      "graph": "QRID GRAFİĞİ",
      "on-graph": " - Grafikte Göster",
      "one-year-data": "SON 12 AYLIK VERİ",
      "election-data": "Seçim Özeti"
    }
  }
}


export const eng = {
  "main": {
    "language": {
      "eng": "English",
      "tur": "Türkçe",
    },
    "general": {
      "buttons": {
        "apply": "Apply",
        "remember-me": "Remember Me",
        "sign-in": "Sign In",
        "sign-up": "Sign Up",
        "logout": "Logout",
        "preferences": "Preferences",
        "forgot-password": "Forgot password",
        "help": "Help",
        "privacy": "Privacy",
        "clear": "Clear",
        "all": "All",
        "close": "Close",
        "search": "Search",
        "save": "Save",
        "okay": "OK",
        "approve": "Approve",
        "register": "Register",
        "multiple-device": "Multiple Device",
        "delete": "Delete",
        "clear-all": "Clear All",
        "clear-layers": "Clear Layers",
        "clear-drawings": "Clear Drawings",
        "cancel": "Cancel",
        "edit": "Edit",
        "filter": "Filter",
        "show-map": "Show Map",
        "upload": "Upload",
        "show-project-details": "Show Project Details",
        "show-all": "Show All",
        "export-to-pdf": "Export PDF",
        "save-filter": "Save Filters",
        "my-filter": "My Filters",
        "print": "Print",
        "export-to-excel": "Export to Excel",
        "all-stok-analysis": "All Stock Analysis",
        "search-nearest-to-me": "Search Nearest to me",
        "show-on-map": "Show on Map",
        "my-location": "My Location",
        "zoom-in": "Zoom In",
        "zoom-out": "Zoom Out",
        "return-to-project-summary": "Return to Project Summary",
        "detailed-filter": "Detailed Filter",
        "similar-qrids": "Similar QRIDs",
        "compare": "Compare",
        "all-time": "All Time",
        "refresh-data": "Refresh Data",
        "back-to-login": "Back to Log In",
        "privacy-terms": "Privacy & Terms",
        "privacy-policy": "Privacy Policy",
        "terms-conditions": "Terms and Conditions",
        "policy-on-protection-of-personal-data": "Policy on Protection of Personal Data"
      },
      "tooltips": {
        "residence": "Residences",
        "office": "Offices",
        "market-analysis": "Market Analysis",
        "urban-planning": "Urban Planning",
        "transactions": "Transactions",
        "listings": "Listings",
        "pois": "Important Points (POIs)",
        "map-types": "Map Type",
        "qrid-layers": "QRID Layers",
        "customization": "Customization",
        "layers": "Layers",
        "my-workspace": "My Workspace",
        "delete": "Delete",
        "show": "Show",
        "show-all-filter": "Show All Filter",
        "hide": "Hide",
        "smaller-details": "Smaller Details",
        "shows-socio-economic-degree": "Shows Socio Economical Degree of the district of the project.",
        "no-photo-found": "NO PHOTO FOUND",
        "click-to-show-on-map": "Click to show on map",
        "start-date": "Start Date",
        "end-date": "End Date",
        "my-data": "My Data",
        "rivals-data": "Rivals Data",
        "send-all-to-the-map": "Send All to the Map"
      },
      "autocomplete": {
        "choose-city": "Select city",
        "choose-county": "Select county",
        "choose-district": "Select district"
      },
      "menu-items": {},
      "messages": {
        "title": {
          "success": "Success",
          "error": "Error",
          "warning": "Warning",
          "info": "Info"
        },
        "error": {
          "page-not-found": "The page you're looking for doesn't exist.",
          "go-home": "GO TO HOMEPAGE",
          "100": "Empty Device ID!",
          "102": "Oops! It seems like you have entered an invalid username.",
          "106": "Your account has not been activated yet. An activation email was sent to you earlier.",
          "108": "This session is not enable because of the Device ID restrictions!",
          "400": "There is an error with session and Device ID!",
          "401": "Oops! It seems like you have entered an invalid password. Note that passwords are case sensitive.",
          "404": "404 error occurred!",
          "409": "User already exists! Please try different User or Recover old Account",
          "500": "Server error occurred!",
          "101": "Oops! It seems like you have another active session for this account on another device.",
          "103": "Oops! It seems like your user account is closed.",
          "104": "Oops! It seems like your user account is closed.",
          "105": "Password must contain at least six characters!",
          "107": "Multiple device option",
          "109": "You do not have access to the Insight product. For detailed information, you can contact info@reidin.com.",
          "1024": "Ip restricted area. Please try again",
          "701": "Oops! It seems like you have entered an invalid key.",
          "general-error": "Unknown error!",
          "unauthorized-usage": "Please login again!",
          "unauthorized-product": "It seems like you don’t have access to this product!",
          "invalid-activation-key": "Oops! It seems like your activation key is wrong.",
          "error-details": "Error Details",
          "check-your-email-password": "Email or password is wrong.",
          "error": "Error",
          "session-expired": "You have multiple active sessions. Please login again.",
          "session-not-found": "Session not found. Please login again.",
          "pdf-export-failed": "An error occurred while creating the PDF",
          "2005": "Email or password is wrong.",
          "1014": "Your password is blocked. Please click forgot my password and create new one and try again.",
          "unvalid-password": "Please enter a valid password!",
          "unvalid-email": "Please enter a valid email!",
        },
        "warning": {
          "element-required": "{{element}} is required!",
          "username-required": "Username is required!",
          "email-required": "Email is required!",
          "username-invalid": "Please type a valid email address",
          "password-required": "Password is required!",
          "password-confirmation-required": "Password confirmation is required!",
          "password-confirmation-match-required": "Password does not match the confirm password.",
          "code-required": "Code is required!",
          "password-character-valid": "Password should contains at least 4 characters",
          "full-name-character-valid": "Full name should contains at least 6 characters",
          "full-name-required": "Full name is required!",
          "first-name-required": "First name is required!",
          "second-name-required": "Second name is required!",
          "phone-number": "Phone number is required!",
          "phone-number-invalid": "Phone number is invalid!",
          "company-required": "Company is required!",
          "form-missing": "Form information is missing!",
        },
        "success": {
          "device-id-updated": "Device ID updated, please re-Login after refresh!",
          "successfully-login": "You have been successfully logged in.",
          "successfully-activated": "Your account has been successfully activated. Please Login.",
          "get-demo": "Get Free Demo"
        },
        "info": {
          "activation-mail-sent": "An activation email has been successfully sent to your email registered below. Kindly click on the link included in the email to activate your account.",
          "another-device-logout": "Your active session has been logged out. You have been successfully logged in."
        }
      },
      "misc": {
        "select-all": "Select All",
        "deselect-all": "Deselect All",
        "type-for-search": "Type for search",
        "choosen-category": " choosen category",
        "choose-category": "Choose Category",
        "choosen-status": " choosen status",
        "choose-status": "Choose Status",
        "choosen-type": " choosen type",
        "choose-type": "Choose Type",
        "less": "Less",
        "more": "More",
        "months": {
          "01": "January",
          "02": "February",
          "03": "March",
          "04": "April",
          "05": "May",
          "06": "Jun",
          "07": "July",
          "08": "August",
          "09": "September",
          "10": "October",
          "11": "November",
          "12": "December"
        }
      },
      "placeholders": {
        "search-in-reidimap": "Search in ReidiMap",
        "choose": "Choose",
        "select": "Please Select",
        "all": "All",
        "min": "min",
        "max": "max",
        "enter-your-message": "Enter your message...",
        "search-market-analysis": "Search Market Analysis",
        "enter-name-for-search": "Enter name for search",
        "search-line": "Search line...",
        "search-in-defined-distance": "Search in pre-defined distance... (meter)",
        "end-date": "End Date",
        "choose-type": "Choose Type",
        "select-province": "Select Province"
      },
      "search-subtitle": {
        "location" : "Location",
        "building" : "Building",
        "developer": "Developer",
        "district": "District",
        "county": "County",
        "city": "City",
        "reports" : "Reports",
        "more" : "More",
        "no-result-found" : "No result found"
      }
    },
    "views": {
      "login-page": {
        "sign-in": "SIGN IN",
        "please-enter-your-sign-in-informations": "Please enter your sign in informations.",
        "email-or-username": "Username (E-mail)",
        "password": "Password",
        "account-check": "Don't have an account? ",
        "multiple-device": {
          "title": "Multiple Device Confirmation",
          "activation-key-input": "Activation Key",
          "content": "To Login the system enter the code that has been sent to your registered E-mail address then click the 'Approve' button. Once the page refresh you can login to the system."
        },
        "error-detail": "Click here to see details."
      },
      "logout": {
        "logout-text": "Logging out. Please wait..."
      },
      "request-password": {
        "forgot-password": "Forgot Password",
        "send-link-message": "Enter your email address and we’ll send a link to reset your password",
        "enter-email": "Enter your email address",
        "request-password": "Request Password",
        "email-address": "Email address"
      },
      "register": {
        "sign-up-message": "If you are not A REIDIN Member yet, you can REGISTER for FREE below or request a Premium Account from your local office.",
        "full-name": "Full name",
        "email-address": "Email Adress",
        "phone-number": "Phone Number",
        "password": "Password",
        "confirm-password": "Confirm Password",
        "reg_button": "REGISTER",
        "register": "Register",
        "already-account": "Already have an account? "
      },
      "request-demo": {
        "request-demo": "Request Demo",
        "first-name": "First name",
        "second-name": "Second name",
        "email-address": "Email Adress",
        "phone-number": "Phone Number",
        "company": "Company",
        "industry": "Industry",
      },
      "reset-password": {
        "title": "Reset Password",
        "current-password": "Current Password",
        "new-password": "New Password",
        "confirm-password": "Confirm Password",
        "change-password": "Change Password"
      },
      "language-selection": {
        "title": "Language Selection",
        "turkish": "Türkçe",
        "english": "English"
      },
      "locations": {
        "title": "Location Settings",
        "city": "City",
        "county": "County",
        "select-city": "Select  City",
        "select-county": "Select County"
      },
      "header": {
        "home": "DASHBOARD",
        "map": "MAP",
        "analytics": "ANALYTICS",
        "my-workspace": "MY WORKSPACE",
        "data": "DATA",
        "city": "City",
        "county": "County",
        "rebis": "REBIS",
        "district": "District",
        "soon-text": "Soon",
        "user-preferences": "User Preferences",
        "select-location": {
          "title": "Region Selection"
        },
        "select-language": {
          "title": "Language Selection",
          "turkish": "Türkçe",
          "english": "English"
        },
        "select-currency": {
          "title": "Currency Selection",
          "turkish-lira": "Turkish Lira (TL)",
          "american-dollar": "American Dollar (USD)",
          "euro": "Euro (EUR)",
          "uae-dirhem": "UAE Dirhem (AED)"
        },
        "measurement-types": {
          "title": "Measurement Types",
          "sqm": "SQM",
          "sqf": "SQF"
        },
        "feedback": {
          "title": "Feedback",
          "heading": "Feedback Form",
          "subject": "Subject",
          "submit-error": "Submit an Error",
          "suggestion": "Suggestion",
          "wish": "Wish",
          "thanks": "Thanks",
          "take-screenshot": "Take screenshot"
        }
      },
      "residential": {
        "title": "RESIDENTIAL",
        "overview": "Overview",
        "category": "Category",
        "project-category": "Residential Category",
        "project-status": "Residential Status",
        "property-type": "Residential Type",
        "start-date": "Start Date",
        "end-date": "End Date",
        "unit-count": "Number of Units",
        "gla": "G.L.A.",
        "maintenance-fee": "Maintenance Fee",
        "occupancy-rate": "Occupancy Rate",
        "rent": "Rent",
        "sale": "Sale",
        "type": "Tip",
        "price-type": "Price Type",
        "filter-by-price": "Filter by Price",
        "detail-filter": "Detailed Filter",
        "unit-type": "Unit Type",
        "studio": "Studio",
        "area": "Area",
        "gross": "Gross",
        "net": "Net",
        "tax-status": "Tax Status",
        "included": "Included",
        "excluded": "Excluded",
        "tax-rate": "Tax Rate",
        "transaction": "Transactions",
        "listing": "Listings",
        "new-home": "REIDIN™ New Home™",
        "property": "Property",
        "occupancy": "Occupancy Rates",
        "servicecharge": "Service Charges",
        "supply": "Supply Trends",
        "price1": "Price Trends",
        "index": "Index Trends",
        "grossyield": "Gross Rental Yields",
        "price_to_rent": "Price-to-Rent Ratios",
        "markettrends": "REIDIN Market Trends™",
        "areareport": "REIDIN Trends Comparison™",
        "launch-trends": "Launch Trends",
        "trendanalysis": "REIDIN™ Trend Analysis"
      },
      "detail_listing": {
        "title": "DETAILED LISTING SEARCH"
      },
      "offices": {
        "title": "OFFICES",
        "office-category": "Office Category"
      },
      "office": {
        "title": "OFFICE",
        "overview": "Overview",
        "listing": "Listings",
        "markettrends": "REIDIN Office Report™",
        "occupancy": "Occupancy Rates",
        "transaction": "Transactions",
        "servicecharge": "Service Charges",
        "pricetrends": "Price Trends",
        "indextrends": "Index Trends",
        "supplytrends": "Supply Trends"
      },
      "industry": {
        "title": "INDUSTRIAL",
        "overview": "Overview"
      },
      "plot": {
        "title": "LAND",
        "transactions": "Transactions",
        "listing": "Listings"
      },
      "retail": {
        "title": "RETAIL",
        "transactions": "Transactions",
        "listing": "Listings",
        "shoppingcentersupply": "Shopping Center Supply"
      },
      "custom": {
        "title": "MY DASHBOARD",
        "overview": "MY DASHBOARD"
      },
      "market-analysis": {
        "title": "MARKET ANALYSIS",
        "popular-searchs": "Popular Searchs",
        "analysis-type": "Analysis Type",
        "rent-market": "Rent Market",
        "region": "Region",
        "data-type": "Data Type",
        "start-date": "Start Date",
        "end-date": "End Date",
        "modal": {
          "edit-popular-searchs": {
            "title": "Edit Popular Searchs",
            "content": "Edit or delete your saved popular searchs."
          },
          "save-to-popular-searchs": {
            "title": "Save to Popular Searchs",
            "content": "Give a name to your popular search and add it to the 'Popular Search' section.",
            "search-name": "Search Name"
          }
        }
      },
      "urban-planning": {
        "title": "URBAN PLANNING",
        "content": "Symbols will be shown on the map at 18th zoom level.",
        "zone-plans": "Zone Plans",
        "field-usage": "Field Usage",
        "urban-regeneration": "Urban Regeneration",
        "mega-projects": "Mega Projects",
        "rail-systems": "Rail Systems",
        "complation-status": "Complation Status",
        "completed": "Completed",
        "planning": "Planning",
        "under-contruction": "Under Construction",
        "filter-by-time": "Filter by Time",
        "detailed-search": "Detailed Search",
        "metro": "Metro",
        "metrobus": "Metrobus",
        "select-province": "Select Province"
      },
      "transactions": {
        "title": "TRANSACTIONS",
        "sell": "Sell",
        "mortgage": "Mortgage",
        "off-plan-sell": "Off Plan Sell",
        "off-plan-mortgage": "Off Plan Mortgage",
        "rent": "Rent",
        "date": "Date",
        "property-type": "Property Type",
        "map-customization": "Map Customization",
        "avarage-price-size": "Avarage Price / Size",
        "transaction-volume": "Transaction Volume",
        "number-of-transaction": "Number of Transaction",
        "transaction-amount": "Transaction Amount",
        "options": "Options",
        "geometry-type": "GEOMETRY TYPE",
        "structure": "Structure",
        "area": "Area",
        "point": "Point",
        "heatmap": "Heatmap",
        "additional-criterias": "ADDITIONAL CRITERIAS",
        "size": "Size - m²",
        "price": "Price - USD",
        "price-size": "Price / Size - USD / m²",
        "source": "Source",
        "approved-transaction": "Approved Transaction"
      },
      "listings": {
        "title": "LISTINGS",
        "sell": "Sell",
        "rent": "Rent",
        "date": "Date",
        "property-type": "Property Type",
        "map-customization": "Map Customization",
        "additional-criterias": "ADDITIONAL CRITERIAS",
        "size": "Size - m²",
        "price": "Price - USD",
        "price-size": "Price / Size - USD / m²",
        "number-of-rooms": "Number of Rooms",
        "number-of-bathroom": "Number of Bathroom",
        "avarage-price-size": "Avarage Price / Size",
        "listing-volume": "Listing Volume",
        "listing-count": "Listing Count",
        "number-of-listings": "Number of Listings",
        "room-amount": "Number of Rooms",
        "bathroom-count": "Number of Bathroom"
      },
      "important-points": {
        "title": "IMPORTANT POINTS (POIs)",
        "content": "Symbols will be shown on the map at 18th zoom level.",
        "site-center": "Site Center",
        "industry": "Industry",
        "property-area": "Property Area",
        "religious-facility": "Religious Facility",
        "health": "Health",
        "green-area": "Green Area",
        "turism": "Turism",
        "entertainment": "Entertainment",
        "cultural-activity": "Cultural Activity",
        "retail": "Retail",
        "eat-drink": "Eat Drink",
        "transportation": "Transportation",
        "education": "Education",
        "public": "Public Places",
        "bank": "Bank",
        "business": "Business",
        "sport-facilities": "Sport Facilities",
        "military-facility": "Military Facility",
        "night-life": "Night Life"
      },
      "map-type": {
        "title": "MAP TYPE",
        "roadmap": "Roadmap",
        "satellite": "Satellite",
        "terrain": "Terrain",
        "hybrid": "Hybrid"
      },
      "qrid-layers": {
        "title": "QRID LAYERS",
        "social-media": "Social Media",
        "population": "Population",
        "transportation": "Transportation",
        "poi": "POI",
        "election": "Election",
        "rental-price": "Rental Price",
        "qrid": "QRID",
        "score": "Score",
        "Rehex": "QRID",
        "Social": "Social",
        "Poi": "POI",
        "Population": "Population",
        "Transport": "Transport",
        "qrid-score": "QRID SCORE",
        "qrid-point": "QRID Point",
        "region-summary": "Region Summary",
        "see-details": "See Details",
        "population-density": "Population Density",
        "transport-density": "Transportation Density",
        "social-density": "Social Density",
        "poi-density": "POI Density",
        "population-score": "Population Score",
        "transport-score": "Transportation Score",
        "social-score": "Social Score",
        "poi-score": "POI Score",
        "rental-prices": "Rental Prices",
        "show-on-map": "Show on Map",
        "name": "Name",
        "number": "Count",
        "distance": "Min. Distance",
        "point": "Point",
        "category": "Category",
        "min-distance": "Min. Distance",
        "closest": "Closest",
        "cancel": "Cancel",
        "okay": "Okay"
      },
      "customization": {
        "title": "CUSTOMIZATION",
        "content": "Symbols will be shown on the map at 18th zoom level.",
        "projects": "Projects",
        "filter": "Filter",
        "sell-price": "Sell Price",
        "urban-regeneration": "Urban Regeneration",
        "opacity": "Opacity",
        "data-selection": "Data Selection",
        "stock": "Stock",
        "price": "Price",
        "compilation-year": "Compilation Year",
        "project-type": "Project Type",
        "display": "Display",
        "heatmap": "Heatmap",
        "by-size": "by Size",
        "by-type": "by Type",
        "add-tag": "Add Tag",
        "yes": "YES",
        "no": "NO",
        "from-11": "from 11 to 15",
        "from-7": "from 7 to 11",
        "from-4": "from 4 to 7",
        "from-2": "from 2 to 4",
        "offices": "Offices",
        "geometry-type": "Geometry Type",
        "regional": "REGIONAL",
        "point": "POINT",
        "category": "Category",
        "down": "Down",
        "up": "Up",
        "avarage": "Avarage",
        "rent": "Rent",
        "occupancy-rate": "Occupancy Rate",
        "maintain-cost": "Maintain Cost"
      },
      "layers": {
        "title": "EDIT LAYERS",
        "show-all-tooltips": "Show All Tooltips",
        "open-all-layers": "Open All Layers",
        "development-plans": "Development Plans",
        "field-usage": "Field Usage",
        "urban-regeneration": "Urban Regeneration",
        "mega-projects": "Mega Projects",
        "opacity": "Opacity"
      },
      "my-works": {
        "title": "MY WORKS",
        "my-reports": "My Reports",
        "my-drawings": "My Drawings",
        "storage": "Storage"
      },
      "my-data": {
        "title": "MY DATA",
        "uploading": "Uploading",
        "eta": "ETA",
        "done": "Done",
        "send-to-map": "Send to the Map",
        "first-row": "First row should be column names",
        "first-three-columns": "First 3 columns should be 'Name’, 'Lat’, ‘Lng’",
        "cell-values": "Cell Values should be Value not Formula",
        "supported-file": "Supported file types are 'xls' and 'xlsx'",
        "modal": {
          "edit-file-name-title": "Edit File Name",
          "edit-file-name": "You can edit file name and save as new name.",
          "file-name": "File Name",
          "enter-file-name": "Enter file name"
        }
      },
      "rivals-data": {
        "title": "RIVALS DATA"
      },
      "data": {
        "project": "PROJECT",
        "developer": "DEVELOPER",
        "secondhand": "SECONDHAND",
        "transaction": "TRANSACTION DATA",
        "listing": "LISTING DATA",
        "company": "COMPANY",
        "agent_broker": "AGENT & BROKER",
        "trend": "TREND",
        "ressupplydata": "RESIDENTIAL SUPPLY DATA",
        "officesupplydata": "OFFICE SUPPLY DATA",
        "retailsupplydata": "RETAIL SUPPLY DATA",
        "populationstatistics": "POPULATION STATISTICS"
      },
      "analytics": {
        "title": "Analytics",
        "overview": "AREA ANALYTICS",
        "cma": "CMA",
        "areareport": "AREA REPORT"
      }
    },
    "project-details": {
      "population": "POPULATION",
      "age": "Age",
      "average-age": "POPULATION BY AGE",
      "married": "Married",
      "single": "Single",
      "education": "EDUCATION",
      "college": "College",
      "secondary-school": "High School",
      "primary-school": "Primary School",
      "uneducated": "Uneducated",
      "transportation": "TRANSPORTATION",
      "filter": "FILTER",
      "total-checkin": "TOTAL CHECK-IN",
      "unique-visitor": "UNIQUE VISITOR",
      "footfall": "FOOTFALL",
      "total-checkin-small": "Total Check-In",
      "unique-visitor-small": "Unique Visitor",
      "footfall-small": "Footfall",
      "categories": "Categories",
      "top-categories-big-caps": "TOP 5 CATEGORIES",
      "select-all": "Select All",
      "deselect-all": "Deselect All",
      "search-categories": "Write to select categories",
      "no-data": "There is no data!",
      "popular-places": "POPULAR PLACES",
      "place": "Place",
      "category": "Category",
      "check-in-difference": "Check-in Difference",
      "total": "Total",
      "total-user": "Total User",
      "price": "Price",
      "rating": "Rating",
      "region-total": "QRID TOTAL CHECK-INS",
      "count": "Check-in Count",
      "month": "Month",
      "user-count": "Total Visit",
      "total-checkin-small-caps": "Total Check-in",
      "category-graph": "GRAPHIC BY CATEGORY",
      "all": "ALL",
      "graph": "QRID GRAPH",
      "on-graph": " - Show Graph",
      "one-year-data": "LAST 12 MONTHS DATA",
      "election-data": "Election Summary"
    }
  }
}
