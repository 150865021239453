import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AuthenticationService } from '@insight-services/authentication.service';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxRegisterComponent {
  redirectDelay: number = 1500;
  showMessages: any = {};
  // strategy: string = '';
  public strategy = 'email'

  submitted = false;
  lang = 'eng';
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};

  constructor(cd: ChangeDetectorRef,
    router: Router,
              public service: AuthenticationService,
              public translateService: TranslateService) {

    this.lang = this.translateService.currentLang;
  }

  register(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    const body = {
      User: {
        UserPassNew: this.user.password,
        Name: this.user.name,
        UserID: this.user.email,
        Phone: this.user.mobile_phone,
        password_confirm: this.user.confirmPassword,
        lang: this.lang
      },
      MySetting: {
        user_id: this.user.email,
        product: 'INSIGHT',
        currency: (this.lang === 'tur') ? 'TRY' : 'USD',
        measurement: 'INT',
        country: (this.lang === 'tur') ? 'TR' : 'AE',
        created_on: new Date(Date.now()).toLocaleString(),
        created_by: 'INSIGHT WEB',
        decimal_seperator: (this.lang === 'tur') ? ',' : '.',
        date_format: (this.lang === 'tur') ? 'd M, Y' : 'M d, Y',
        language: this.lang
      },
      Product: {
        UserStartDate: moment().format('YYYY-MM-DD'),
        UserExpDate: moment().add(1, 'y').format('YYYY-MM-DD'),
        UserType: 'C',
        UserTypeNew: 'R',
        Location: (this.lang === 'tur') ? 'TR' : 'AE',
        CurrCredit: 100
      }
    };
    this.service.register(this.strategy, body).subscribe(result => {
      this.submitted = false;
      if (result.data) {
        // console.log('isSuccess');
        // console.log('messages', result.getMessages());
        this.translateService
          .stream('main.general.messages.info.activation-mail-sent')
          .subscribe(translatedText => (this.messages = [translatedText]));
        // this.messages = result.getMessages();
        this.showMessages['success'] = true;
      } else {
        const res_response = result.code;
        // console.log('res_response', res_response);
        if (res_response === 409) {
          this.translateService
            .stream('main.general.messages.error.409')
            .subscribe(translatedText => (this.errors = [translatedText]));
          this.showMessages['error'] = true;
        } else {
          this.errors = ['Unknown Error!'];
          this.showMessages['error'] = true;
        }
        // this.errors = result.getErrors();
      }
      // this.cd.detectChanges();
    });
  }

  // getConfigValue(key: string): any {
  //   return getDeepFromObject(this.options, key, null);
  // }

}
