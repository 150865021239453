import { Component, computed, inject } from "@angular/core";
import { NbMenuItem, NbIconLibraries } from "@nebular/theme";
import { MenuService } from "@insight-services/menu.service";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "@insight-models/menuItem";
import { Dashboard } from "@insight-models/dashboard";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
	selector: "sidebar-menu",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {
	private readonly menuService = inject(MenuService);
	private readonly translateService = inject(TranslateService);
	private readonly nbIcon = inject(NbIconLibraries);

	currentDashboard$ = this.menuService.currentDashboard$;
	navigateToSearch$ = this.menuService.navigateToSearch$;

	nbMenuItems = computed(() => {
		const selectedTopMenuItem = this.menuService.selectedTopMenuItem();
		const topMenuItems = this.menuService.topMenuItems();

		if (selectedTopMenuItem?.id) {
			const parent = topMenuItems.find(menuItem => menuItem.id === selectedTopMenuItem.id);
			const nbMenuItems = parent.child.map((child) => {
				return this.transformToNbMenuItem(child, selectedTopMenuItem);
			});
			return nbMenuItems;
		}
	});

	constructor() {
		this.nbIcon
			.getPack("eva")
			.icons.set(
				"land-market",
				"<img src='../../../assets/icons/land_market_icon.svg' width='15px'/>"
			);

		this.currentDashboard$.pipe(filter(Boolean)).subscribe({
			next: (dashboard: Dashboard) => {
				this.findAndSetMenuItemSelectedAndExpanded(
					this.nbMenuItems(),
					dashboard.id
				);
			},
		});

		this.navigateToSearch$.pipe(takeUntilDestroyed()).subscribe({
			next: () => {
				this.findAndSetMenuItemSelectedAndExpanded(
					this.nbMenuItems(),
					0
				);
			},
		});
	}

	expandAndSelectFirstChild(menuItem: NbMenuItem) {
		menuItem.selected = true;
		menuItem.expanded = true;
		if (menuItem.children && menuItem.children.length) {
			this.expandAndSelectFirstChild(menuItem.children[0]);
		}
	}

	private findAndSetMenuItemSelectedAndExpanded(
		menuItems: NbMenuItem[],
		id: number
	) {
		for (const menuItem of menuItems) {
			this.expandMenuItems(menuItem, id);
		}
	}

	private expandMenuItems(menuItem: NbMenuItem, id: number) {
		const isSelected = menuItem.data.id === id;

		menuItem.selected = isSelected;
		menuItem.expanded = isSelected;

		if (isSelected) {
			this.expandParents(menuItem);
		}

		if (menuItem.children && !isSelected) {
			for (const child of menuItem.children) {
				child.parent = menuItem;
				this.expandMenuItems(child, id);
			}
		}
	}

	private expandParents(menuItem: NbMenuItem) {
		menuItem.expanded = true;
		menuItem.selected = true;
		if (menuItem.parent) this.expandParents(menuItem.parent);
	}

	private transformToNbMenuItem(
		item: MenuItem,
		topMenu: MenuItem
	): NbMenuItem {
		const menuItem = new NbMenuItem();
		const userLanguage = this.translateService.currentLang;
		menuItem.title = userLanguage === "tur" ? item.title_tr : item.title_en;

		menuItem.data = item;

		if (item.child && item.child.length) {
			const childs = item.child
				.filter((childItem) => !childItem.tab_menu)
				.map((childItem) => {
					if (!childItem.tab_menu) {
						return this.transformToNbMenuItem(childItem, topMenu);
					}
				});
			if (childs.length) {
				menuItem.children = childs;
			}
		}

		if (item.link !== null && item.link?.includes("notification"))
			menuItem.link = "/home/notification/" + item.id;
		else if (item.link === "search") menuItem.link = "/home/dashboard";
		else menuItem.link = "/home/dashboard/" + item.id;

		switch (menuItem.title) {
			case "SEARCH":
				menuItem.icon = "search-outline";
				break;
			case "RESIDENTIAL":
				menuItem.icon = "home-outline";
				break;
			case "LAND MARKET":
				menuItem.icon = "land-market";
				break;
			case "ARSA PAZARI":
				menuItem.icon = "land-market";
				break;
			case "COMMERCIAL":
				menuItem.icon = "shopping-cart-outline";
				break;
			case "ARA":
				menuItem.icon = "search-outline";
				break;
			case "TICARI":
				menuItem.icon = "shopping-cart-outline";
				break;
			case "ECONOMY REPORTS":
				menuItem.icon = "trending-up-outline";
				break;
			case "REIDIN REPORTS":
				menuItem.icon = "file-text-outline";
				break;
			case "PRODUCT UPDATES":
				menuItem.icon = "flip-2-outline";
				break;
			case "KONUT":
				menuItem.icon = "home-outline";
				break;
			case "TİCARİ":
				menuItem.icon = "shopping-cart-outline";
				break;
			case "EKONOMI RAPORLARI":
				menuItem.icon = "trending-up-outline";
				break;
			case "REIDIN RAPORLARI":
				menuItem.icon = "file-text-outline";
				break;
			case "URUN GUNCELLEMELERI":
				menuItem.icon = "flip-2-outline";
				break;
			default:
				// menuItem.icon = 'star-outline'
				break;
		}

		return menuItem;
	}
}
