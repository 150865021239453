import {
  ChangeDetectionStrategy,
  Component, OnDestroy,
  OnInit, effect,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuService } from '@insight-services/menu.service';
import { Router, RouterModule } from '@angular/router';
import { NbSidebarService } from '@nebular/theme';
import { Notification } from '@insight-models/notification';
import { NotificationService } from '@insight-services/notification.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'ngx-notification',
  standalone: true,
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatTabsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
  private readonly menuService = inject(MenuService)
  private readonly nbSidebarService = inject(NbSidebarService)
  private readonly router = inject(Router)
  private readonly notificationService = inject(NotificationService)

  notificationList = this.notificationService.notificationList;

  selectedNotification = this.notificationService.selectedNotification;

  constructor() {
    effect(() => {
      const notification = this.selectedNotification()
      if (notification) this.router.navigate(["/home/notification/", notification.value])
    })
  }

  ngOnInit(): void {
    this.closeSidebar();
  }

  setSelectedNotification(notification: Notification) {
    this.notificationService.setSelectedNotification(notification)
  }

  ngOnDestroy(): void {
    this.openSidebar();
  }

  openSidebar() {
    this.menuService.openSidebar()
    this.nbSidebarService.expand('menu-sidebar');
  }

  closeSidebar() {
    this.menuService.closeSidebar()
    this.nbSidebarService.collapse('menu-sidebar');
  }
}
