<div class="notification-container">
    <nav mat-tab-nav-bar class="nav-bar" [tabPanel]="tabPanel">
        <div mat-tab-link (click)="setSelectedNotification(notification)"
            [active]="selectedNotification()?.dashboardId == notification.dashboardId" *ngFor="let notification of notificationList">
            <span class="tab-text">{{notification.name}}</span>
        </div>
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

    <router-outlet></router-outlet>
</div>