import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'ngx-save-filter-modal',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './save-filter-modal.component.html',
  styleUrls: ['./save-filter-modal.component.scss']
})
export class SaveFilterModalComponent {
  filterName = new FormControl<string>("")

  constructor(private dialogRef: MatDialogRef<SaveFilterModalComponent>) { }

  saveDashboardFilter() {
    this.dialogRef.close(`${this.filterName.value}`)
  }

  cancel() {
    this.dialogRef.close(undefined)
  }
}
