<nb-card class="notification" [ngStyle]="{'display': (displayControl) ? '' : 'none'}"  size="small">
  <nb-list>
    <nb-list-item *ngFor="let newsPost of news">
      <ngx-news-post [post]="newsPost"></ngx-news-post>
    </nb-list-item>
    <nb-list-item *ngFor="let _ of placeholders">
      <ngx-news-post-placeholder></ngx-news-post-placeholder>
    </nb-list-item>
  </nb-list>
</nb-card>


