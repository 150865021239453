import { MenuItem } from "./menuItem";

const searchMenuItem: MenuItem = {
    countr: "",
    dashboard_id: "",
    full_path: "",
    full_path_tr: "",
    id: 0,
    is_active: true,
    is_external: false,
    is_print: true,
    link: "search",
    parent_menu: 0,
    sort_order: 1,
    tab_menu: true,
    title_en: "SEARCH",
    title_tr: "ARA",
    url: "",
    child: [],
    icon: "SEARCH"
}

export { searchMenuItem };