<div class="modal fade" tabindex="-1" role="dialog" id="terms-and-conditions" aria-labelledby="terms-and-conditions"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{this.language === 'eng' ? 'Terms and Conditions'
          : 'Kullanım Koşulları'}}</h5>
      </div>
      <div class="modal-body english"
        [ngStyle]="{'display':this.language === 'eng' ? 'block' : 'none'}">
        <ol>
          <li class="modal-body-text">
            <span class="modal-body-title">TERMS</span>
            <ol>
              <li class="modal-body-text">
                In these terms and conditions for users (“Terms of Use”) the terms
                “we", "our", "us" means REIDIN FZ LLC and its subsidiaries,
                employees, officers, agents, affiliates or assigned parties.
              </li>
              <li class="modal-body-text">"Website" refers to REIDIN.com.</li>
              <li class="modal-body-text">
                By accessing and using this Website, you are agreeing to be bound
                by the Website’s Terms of Use and the Privacy Policy (together the
                “Terms”), all applicable laws and regulations, and agree that you
                are responsible for compliance with any applicable local laws. If
                you do not agree with any of these Terms, your sole option is to
                immediately cease your use of this Website. The materials
                contained in this Website are protected by applicable copyright
                and trademark law.
              </li>
              <li class="modal-body-text">
                You may not use the Website and may not accept these Terms if (a)
                you are not of eighteen (18) years of age, or (b) you are a person
                who is either barred or otherwise legally prohibited from
                receiving or using the Website under the laws of the country in
                which you are a resident or from which you access or use the
                Website.
              </li>
              <li class="modal-body-text">
                These Terms are effective between you and us as of the date you
                accept these Terms, and you do so by default through the use of
                the Website.
              </li>
            </ol>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">USE LICENSE & RESTRICTIONS</span>
            <ol>
              <li class="modal-body-text">
                Permission is granted to temporarily download copies of the
                materials (information or software) on the Website for personal,
                non-commercial transitory viewing only. This is the grant of a
                license, not a transfer of title, and under this license you may
                not:
                <ol>
                  <li class="modal-body-text">modify or copy the materials;</li>
                  <li class="modal-body-text">
                    use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial);
                  </li>
                  <li class="modal-body-text">
                    attempt to decompile or reverse engineer any software
                    contained on the Website;
                  </li>
                  <li class="modal-body-text">
                    remove any copyright or other proprietary notations from the
                    materials; or
                  </li>
                  <li class="modal-body-text">
                    transfer the materials to another person or "mirror" the
                    materials on any other server.
                  </li>
                </ol>
              </li>
              <li class="modal-body-text">
                In accessing or using our Website you irrevocably agree and
                undertake to ensure that you will not:

                <ol>
                  <li class="modal-body-text">
                    use any automated device, software process or means to access,
                    retrieve, scrape, or index our Website or any content on our
                    Website;
                  </li>
                  <li class="modal-body-text">
                    use any device, software, process or means to interfere or
                    attempt to interfere with the proper working on our Website;
                  </li>
                  <li class="modal-body-text">
                    undertake any action that will impose a burden or make
                    excessive traffic demands on our infrastructure that we deem,
                    in our sole discretion to be unreasonable or disproportionate
                    Website usage;
                  </li>
                  <li class="modal-body-text">
                    use or index any content or data on our Website for purposes
                    of:
                    <ol>
                      <li class="modal-body-text">
                        constructing or populating a searchable database of
                        properties,
                      </li>
                      <li class="modal-body-text">building a database of property information; or</li>
                      <li class="modal-body-text">
                        competing with us in any manner that we have not
                        specifically authorized;
                      </li>
                    </ol>
                  </li>
                  <li class="modal-body-text">
                    transmit spam, chain letters, contents, junk email, surveys,
                    or other mass messaging, whether commercial in nature or not;
                  </li>
                  <li class="modal-body-text">
                    use our Website or any content from our Website in any manner
                    which we in our sole discretion determine as not reasonable
                    and/or not for the purpose which it is made available;
                  </li>
                  <li class="modal-body-text">
                    violate the rights of any person, including copyright, trade
                    secret, privacy right, or any other intellectual property or
                    proprietary right;
                  </li>
                  <li class="modal-body-text">
                    pose as any person or entity or attempt to solicit money,
                    passwords or personal information from any person;
                  </li>
                  <li class="modal-body-text">
                    act in violation of any such terms or other condition posed by
                    us or any applicable law;
                  </li>
                  <li class="modal-body-text">
                    reproduce, republish, retransmit, modify, adapt, distribute,
                    translate, create derivative works or adaptation of, publicly
                    display, sell, trade, or in any way exploit our Website or any
                    content on our Website, except as expressly authorised by us;
                    or
                  </li>
                  <li class="modal-body-text">
                    transmit or attempt to transmit any computer viruses, worms,
                    defects or other items of a destructive manner.
                  </li>
                </ol>
              </li>
              <li class="modal-body-text">
                We reserve the right to exercise whatever means we deem necessary
                to prevent unauthorised access to our use of the Website,
                including but not limited to, instituting technological barriers,
                or reporting your conduct to any person or entity.
              </li>
              <li class="modal-body-text">
                This license shall automatically terminate if you violate any of
                these restrictions and may be terminated by us at any time. Upon
                terminating your viewing of these materials or upon the
                termination of this license, you must destroy any downloaded
                materials in your possession whether in electronic or printed
                format.
              </li>
            </ol>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">YOUR RESPONSIBILITIES</span>
            <ol>
              <li class="modal-body-text">
                Details are compiled and hosted by us in good faith but
                information providers are third parties and have not been verified
                by us. You are responsible for making your own enquiries and we
                provide no guarantee and accept no responsibility for the accuracy
                or completeness of any information contained within the Details.
              </li>
              <li class="modal-body-text">
                You are responsible for checking, confirming and satisfying
                yourself as to the accuracy of any Details.
              </li>
              <li class="modal-body-text">
                You are responsible for ensuring that you act in good faith
                towards any other parties.
              </li>
              <li class="modal-body-text">
                You represent and warrant that your use of our Website will comply
                at all times with these Terms of Use and any further terms that
                may apply to you in relation to your use of our Website, including
                all amendments and revisions to these Terms in accordance with
                Clause 8 herein;
              </li>
            </ol>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">LIMITATIONS</span>
            <ol>
              <li class="modal-body-text">
                In no event will we be liable for any damages (including, without
                limitation, damages for loss of data or profit, or due to business
                interruption) arising out of the use or inability to use the
                materials on the Website, even if one of our authorized
                representatives has been notified orally or in writing of the
                possibility of such damage.
              </li>
              <li class="modal-body-text">
                We will not be liable for any loss or damage arising under or in
                connection with:
                <ol>
                  <li class="modal-body-text">the use of, or inability to use, our Website;</li>
                  <li class="modal-body-text">
                    the reliance on any content or information displayed on our
                    Website;
                  </li>
                  <li class="modal-body-text">
                    any direct, consequential, special or punitive loss, damage,
                    costs and expenses;
                  </li>
                  <li class="modal-body-text">loss of profit;</li>
                  <li class="modal-body-text">loss of business;</li>
                  <li class="modal-body-text">loss of reputation;</li>
                  <li class="modal-body-text">depletion of goodwill; or</li>
                  <li class="modal-body-text">loss of, damage to or corruption of data.</li>
                </ol>
              </li>
              <li class="modal-body-text">
                Unless we otherwise expressly agree in writing, you agree not to
                use our Website for any commercial or business purposes.
              </li>
              <li class="modal-body-text">
                We will not be liable for any loss or damage caused by a virus,
                distributed denial of service attack or other technological
                harmful material that may infect your computer equipment, computer
                programmes, data or other proprietary material due to your use of
                our Website or to your downloading of any content on it, or any
                website linked to it.
              </li>
            </ol>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">REVISIONS AND ERRATA</span>
            <p class="modal-body-text">
              The materials appearing on the Website could include technical,
              typographical, or photographic errors. We do not warrant that any of
              the materials on its Website are accurate, complete, or current. We
              may make changes to the materials contained on the Website at any
              time without notice.
            </p>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">AVAILABILITY OF WEBSITE</span>
            <p class="modal-body-text">
              We strive to ensure that our Website and the services are available
              to you at all times but cannot guarantee that either the Website or
              the services will operate continuously, without interruptions or be
              fault free. On occasion, necessary maintenance or upgrade work
              requires us to make the Website and the services unavailable without
              notice, but we aim to keep downtime to a minimum. We accept no
              liability for any interruption or loss of service. We reserve the
              absolute right to alter, suspend or discontinue any part of our
              Website or the services, including your access to it.
            </p>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">LINKS & THIRD PARTIES</span>
            <p class="modal-body-text">
              Our Website may contain links, hyperlinks and pointers to third
              party products, services and/or websites that are not affiliated
              with Us. We have no control over the products, services or websites
              of these third parties and We do not guarantee or take
              responsibility for them. Our Website may also contain advertising
              from third parties and we are not responsible, nor do we make any
              warranties or representations for any misleading or inaccurate
              advertisements which are the sole responsibility of the advertiser.
            </p>
            <p class="modal-body-text">
              Any links on our Website should not be taken as an endorsement by us
              of any kind. Furthermore, our Website contains data provided by
              third parties and we accept no responsibility, nor do we make any
              warranties or representations for any inaccuracies in this material.
              You agree to release us from any claims or disputes of any kind
              arising from or in any way connected to such disputes with third
              parties.
            </p>
            <p class="modal-body-text">
              By using the Website, you grant us an irrevocable, world-wide,
              royalty free license to commercialse, copy, license to other
              persons, use and adapt for any purpose any material you generate or
              submit to make use of the Website. We do not warrant that the
              content, links, or sub-domains contained on, or associate with our
              Website will be available and accessible to you at all times.
              Information on our publications, posts, inserts, information,
              content should not be regarded as a substitute for professional
              legal, financial or real estate advice.
            </p>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">SITE TERMS OF USE MODIFICATIONS</span>
            <p class="modal-body-text">
              We may revise these Terms of Use and any such Terms for the Website
              at any time without notice. By using this Website you are agreeing
              to be bound by the Terms of Use.
            </p>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">INTELLECTUAL PROPERTY</span>
            <ol>
              <li class="modal-body-text">
                Unless otherwise expressly stated, all contents of the Website are
                copyrights, trademarks, trade dress and/or other intellectual
                property owned, controlled or licensed by us, or one of our
                affiliates or by third parties who have licensed their materials
                to us and are protected by the applicable laws.
              </li>
              <li class="modal-body-text">
                We, together with our suppliers and licensors expressly reserve
                all intellectual property rights in all text, programs, products,
                processes, technology, content and other materials, which appear
                on this Website. Access to this Website does not confer and shall
                not be considered as conferring upon anyone any license under any
                of our or any third party's intellectual property rights. Any use
                of this Website or its contents, including copying or storing it
                or them in whole or part, other than for your own personal,
                non-commercial use, is prohibited without our permission. You may
                not modify, distribute or re-post anything on this Website for any
                purpose.
              </li>
              <li class="modal-body-text">
                Our names and logos and all related product and service names,
                design marks and slogans are the trademarks or service marks of us
                or our licensors. No trademark or service mark license is granted
                in connection with the materials contained on this Website.
              </li>
              <li class="modal-body-text">
                Access to this Website does not authorise anyone to use any name,
                logo or mark in any manner whatsoever.
              </li>
            </ol>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">ELECTRONIC COMMUNICATIONS</span>
            <p class="modal-body-text">
              When you use the Website or send emails to us, you are communicating
              with us electronically. You consent to receive electronically any
              communications related to your use of this Website. We will
              communicate with you by email or by posting notices on this Website.
              You agree that all agreements, notices, disclosures and other
              communications that are provided to you electronically satisfy any
              legal requirement that such communications be in writing. All
              notices from us intended for receipt by you shall be deemed
              delivered and effective when sent to the email address you provide
              on the Website for your account.
            </p>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">INDEMNITY</span>
            <p class="modal-body-text">
              You agree to indemnify and hold us and our affiliates (and our
              officers, agents, partners and employees) against any and all loss,
              liability, claim or demand, including reasonable attorney’s fees,
              arising out of, or in connection with your use of and access to our
              Website or making Contributions not in accordance with the Terms.
            </p>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">DISCLAIMER</span>
            <p class="modal-body-text">
              The materials on our Website are provided on an "as is" and “as
              available” basis and we make no warranties, expressed or implied,
              and hereby disclaim and negate all other warranties, including
              without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights. Further, we do not warrant or make any representation
              concerning the accuracy, likely results, or reliability of the use
              of the materials on the Website or otherwise relating to such
              materials or on any site linked to this Website.
            </p>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">GOVERNING LAW</span>
            <ol>
              <li class="modal-body-text">
                These Terms of Use and your access to the Website is subject to
                and governed by the laws of the United Arab Emirates as applied in
                the Emirate of Dubai. You agree to submit to the exclusive
                jurisdiction of the Courts of Dubai.
              </li>
              <li class="modal-body-text">
                If any term of the Agreement is or may become for any reason
                invalid or unenforceable at law, the validity and enforceability
                of the remainder will not be affected.
              </li>
            </ol>
          </li>

          <br />

          <li class="modal-body-text">
            <span class="modal-body-title">CONTACT US</span>
            <p class="modal-body-text">
              REIDIN.com subscribers are licensed organizations. To report any
              suspected or misleading information on our site please send us an
              email with the details to cs&#64;REIDIN.com If you have any queries,
              complaints or recommendations about these Terms of Use, please
              contact us at the following address: info&#64;REIDIN.com
            </p>
          </li>
        </ol>
      </div>
      <div class="modal-body turkish"
        [ngStyle]="{'display':this.language === 'tur' ? 'block' : 'none'}">
        <h3 style="font-size: 15.8px;">LÜTFEN REIDIN SİTESİNİ KULLANMADAN ÖNCE BU KULLANIM KOŞULLARI’NI DİKKATLİCE
          OKUYUNUZ.
        </h3>
        <h3>REIDIN sitesi üzerinden sunulan hizmet ve servislerle ilgili diğer tüm bilgileri help&#64;reidin.com adresine
          e-posta göndererek edinebilirsiniz .</h3>
        <ol>
          <li class="modal-body-text">
            REIDIN sitesi, kişi ve kurumlar için gayrimenkul verilerinin, bu alanda detaylı gayrimenkul bilgi, analiz ve
            araştırmaların sunulduğu bir platformdur. Siteyi doğrudan ziyaret eden ya da çeşitli avantajlarından dolayı
            Üyelik hesabı açan herkes, Kullanıcı sıfatını taşır. Kullanıcılara ücretli ve ücretsiz olarak sunulan tüm
            hizmetlerin Kullanıcı’lar tarafından kullanımı ve üyeliği, bu kullanım koşullarına tabidir. Sitede yer alan
            tüm servisler, Maslak Mah. AOS 55. Sok. 42 Maslak B Blok Sit. No:4/756-757 Kat:7 Sarıyer, 34396 İstanbul,
            Türkiye adresinde kayıtlı Reidin Bilgi ve Data Teknolojileri Ticaret Anonim Şirketi’nin (REIDIN’in)
            mülkiyetindedir ve REIDIN tarafından işletilir.
          </li>
          <li class="modal-body-text">
            REIDIN sitesini ziyaret etmekle ve hizmetleri kullanmakla; bağlı olduğunuz yasalara göre sözleşme imzalama
            hakkına, yetkisine ve hukuki ehliyetine sahip ve 18 yaşın üzerinde olduğunuzu, bu kullanım koşullarını
            okuduğunuzu, anladığınızı ve burada yazan şartlarla bağlı olduğunuzu kabul ettiğiniz anlamına gelir. Aksi
            halde REIDIN bu durumdan dolayı sorumluluk kabul etmez. Sitede yayınlanan Gizlilik Politikası, bu kullanım
            koşullarının ayrılmaz bir parçasıdır.
          </li>
          <li>
            <h4 class="subtitle">SİTENİN KULLANIMINA İLİŞKİN KOŞULLAR VE YÜKÜMLÜLÜKLER:
            </h4>
            <ol>
              <li class="modal-body-text">
                REIDIN, site üzerinde sunulan hizmet ve servislerde değişiklik yapma hakkını saklı tutar. Kullanıcılar
                bu değişiklikler veya iptallerden dolayı uğrayacakları doğrudan ve/veya dolaylı zararlar için her ne nam
                altında olursa olsun REIDIN’den tazminat talep edemeyeceklerini kabul ve beyan ederler.
              </li>
              <li class="modal-body-text">
                Kullanıcılar, sitede yayınladıkları her türlü görsel ve yazılı içerikten sorumludur. 5651 sayılı yasa
                gereği REIDIN yer sağlayıcıdır ve içeriklerden sorumlu değildir. Kullanıcılar, yayınladıkları
                içeriklerin hukuka ve ahlaka aykırı olmayacağını, üçüncü kişilerin kişilik hakları ve telif haklarını
                ihlal etmeyeceğini, haksız rekabet kurallarına aykırı davranmayacağını aksi halde doğacak tazminat
                taleplerinden kendilerinin sorumlu olacağını peşinen kabul eder.
              </li>
              <li class="modal-body-text">
                Kullanıcı, site içindeki faaliyetlerinde, sitenin herhangi bir bölümünde veya iletişimlerinde veya
                yayınladığı içeriklerde genel ahlaka ve adaba aykırı, kanuna aykırı, 3. kişilerin haklarını zedeleyen,
                yanıltıcı, saldırgan, çocukları, yaşlıları ve engellileri cinsel yönden istismar eden, kişilerin kişilik
                haklarını zedeleyen, telif haklarına aykırı, yasa dışı faaliyetleri teşvik eden, tehdit edici, taciz
                edici içerikler üretmeyeceğini, paylaşmayacağını kabul eder. Aksi halde oluşacak zarardan tamamen
                kendisi sorumludur. REIDIN söz konusu içerikleri kontrol etmekle yükümlü değildir. REIDIN bu tür
                eylemleri tespit ederse bu tür hesapları askıya alabilir, sona erdirebilir, yasal süreç başlatma hakkını
                saklı tutar. Bu sebeple yargı mercilerinden etkinlik veya kullanıcı hesapları ile ilgili bilgi talepleri
                gelirse paylaşma hakkını saklı tutar.
              </li>
              <li class="modal-body-text">
                Kullanıcı, sitenin kullanımında tersine mühendislik yapmayacağını ya da bunların kaynak kodunu bulmak
                veya elde etmek amacına yönelik herhangi bir başka işlemde bulunmayacağını herhangi bir bilgisayar
                şebekesinin güvenliğini ihlal etmeyeceğini, güvenlik şifreleme kodlarını kırmayacağını, SPAM mail veya
                zararlı yazılım girişiminde bulunmayacağını, aksi halde REIDIN ve 3. Kişiler nezdinde doğacak
                zararlardan sorumlu olacağını, hakkında hukuki ve cezai işlem yapılacağını peşinen kabul eder.
              </li>
              <li class="modal-body-text">
                Kullanıcı, sitedeki ve site üzerinden eriştiği üçüncü taraf sitelerdeki şifre ve hesap güvenliğinden
                kendisi sorumludur. Aksi halde oluşacak veri kayıplarından ve güvenlik ihlallerinden veya donanım ve
                cihazların zarar görmesinden REIDIN sorumlu tutulamaz.
              </li>
              <li class="modal-body-text">
                REIDIN; hile, kuralların kötüye kullanılması veya ihlali şüphesinin bulunduğu hallerde, Kullanıcı’nın
                varsa üyeliğinin sona erdirilmesi durumunda bağlayıcı ve kesin kararı verecek tek yetkili mercidir.
              </li>
            </ol>
          </li>
          <li>
            <h4 class="subtitle">GARANTİ</h4>
            <ol>
              <li class="modal-body-text">
                HİZMETLER, İÇERİK VE SİTE; İSTER SARİH (AÇIK) İSTER ZIMNİ (ÖRTÜK), HERHANGİ BİR SINIRLAMA VE TİCARETE
                ELVERİŞLİLİK GARANTİSİ OLMAYAN, BELİRLİ BİR AMACA UYGUNLUK GİBİ YA DA HERHANGİ BİR İHLALİ ÖNLEYİCİ
                SINIRLAMA KOYMADAN, TAMAMEN “OLDUĞU GİBİ” İLKESİNE GÖRE SUNULMUŞTUR. REIDIN, SİTEDE YAYINLANAN
                İÇERİKLERİN VE İLANLARIN VE FİYAT BİLGİLERİNİN, DOĞRULUĞU, GÜVENİLİRLİĞİ VE HUKUKA UYGUNLUĞUNU GARANTİ
                ETMEZ, BU İÇERİKLERLE İLGİLİ SORUMLULUĞU BULUNMAMAKTADIR.
              </li>
              <li class="modal-body-text">
                REIDIN, KENDİ SİTESİNDEN BAĞLANTI YAPILARAK ULAŞILAN, HERHANGİ BİR WEB SİTESİ TARAFINDAN
                GERÇEKLEŞTİRİLEN HERHANGİ BİR İLETİŞİMİN GİZLİLİĞİ VEYA DOKUNULMAZLIĞI KONUSUNDA GARANTİ VERMEZ. Daha
                fazla bilgi için lütfen “Gizlilik Bildirimi”ne göz atın.
              </li>
            </ol>
          </li>
          <li>
            <h4 class="subtitle">SONA ERME</h4>
            <ol>
              <li class="modal-body-text">
                Kullanıcılar, dilediği zaman kullanım koşullarını reddedebilir ve konudaki kabul iradesini sona
                erdirebilir. Kullanıcının bu kullanım koşullarında belirtilen madde veya koşullardan birini ihlal etmesi
                halinde, REIDIN, önceden bildirme mecburiyeti olmaksızın, bazı veya tüm hesapları, servisleri, siteye
                erişimi, modifiye edebilir, askıya alabilir, derhal sona erdirebilir veya durdurabilir. REIDIN, bu
                sebeple doğacak zararlardan ya da varsa üye olunmuş hesaba erişememenizden sorumlu değildir. Kullanıcı
                ile bağımsız olarak imzalanan hizmet sözleşmesine ilişkin hükümler saklıdır.
              </li>
            </ol>
          </li>
          <li>
            <h4 class="subtitle">KİŞİSEL VERİLERİN KORUNMASI</h4>
            <ol>
              <li class="modal-body-text">
                REIDIN 6698 sayılı Kişisel Verilerin Korunması Kanunu ve alt mevzuatları çerçevesinde web sitesi
                ziyaretçileri ve Kullanıcılar’ın bazı kişisel verilerini tamamen hukuka uygun olarak işlemektedir. İşbu
                Kullanım Koşulları web sitesi kullanım hizmetinin REIDIN kullanıcılara sunulması ve tarafların bundan
                doğan hak ve yükümlülüklerini düzenleyen bir sözleşme olup, sözleşmenin kurulması veya hizmetin ifası
                için gereken kişisel veriler bu kapsamda işlenecektir.
              </li>
              <li class="modal-body-text">
                REIDIN yasal zorunluluk halinde ya da gerekli gördüğü diğer hallerde 6698 sayılı Kişisel Verileri Koruma
                Kanunu’nun belirlemiş olduğu çerçeve dahilinde Kullanıcı’nın çeşitli formlar aracılığıyla beyan ettiği
                bilgiler ile tarayıcı / mobil uygulama aracılığıyla toplanan kişisel veri niteliğinde olmayan bilgileri
                resmi veya özel kurumlara veya üçüncü şahıslara verebilir. Kullanıcı bu sebeple REIDIN’den her ne nam
                altında olursa olsun tazminat talep edemeyeceğini kabul eder.
              </li>
              <li class="modal-body-text">
                Web sitesi kullanıcılarının kişisel verilerinin işlenmesi ve ilgili kişilerin hakları ile ilgili
                ayrıntılı bilgilendirme “Web Sitesi Kulklanıcıları için Aydınlatma Metni”nde bulunmaktadır.
              </li>
            </ol>
          </li>
          <li>
            <h4 class="subtitle">FİKRİ MÜLKİYET HAKLARI</h4>
            <ol>
              <li class="modal-body-text">
                REIDIN’e ait sitenin ve sitedeki her türlü içeriğin kodlarıyla birlikte, ticari markası, logosu, alanadı
                ve diğer tüm unsurlarının tüm fikri mülkiyet hakları Reidin Bilgi ve Data Teknolojileri Ticaret Anonim
                Şirketi ’ne aittir. Kullanıcılar, web sitesi üzerindeki hizmetleri ve servisleri, Reidin Bilgi ve Data
                Teknolojileri Ticaret Anonim Şirketi’nin telif hakkı sahibi olduğu unsurları yeniden satamaz,
                paylaşamaz, dağıtamaz, sergileyemez veya başkasının hizmetlere / servislere erişmesine veya kullanmasına
                izin veremez, çoğaltamaz, dağıtamaz, işleme eser haline getiremez. Aksi takdirde Kullanıcılar; REIDIN
                tarafından doğacak zarara ilişkin talep edilen tazminat miktarını, mahkeme masrafları ve avukatlık
                ücreti de dahil olmak üzere karşılamakla yükümlü olacaklardır.
              </li>
              <li class="modal-body-text">
                Kullanıcılar, sitede yayınlanan gayrimenkul değerleme bilgilerini ve bunlar kullanılarak türetilecek
                hiçbir sonucu verilen hizmetin amacı dışında kullanamazlar.
              </li>
              <li class="modal-body-text">
                İşbu “Kullanım Koşulları” dahilinde REIDIN tarafından sarahaten yetki verilmediği hallerde REIDIN;
                REIDIN hizmetleri, REIDIN bilgileri, REIDIN telif haklarına tabi çalışmaları, REIDIN ticari markaları,
                REIDIN ticari görünümü veya bu site vasıtasıyla sağladığı başkaca varlık ve bilgilere yönelik tüm
                haklarını saklı tutmaktadır.
              </li>
            </ol>
          </li>
          <li>
            <h4 class="subtitle">MÜCBİR SEBEPLER</h4>
            <ol>
              <li class="modal-body-text">
                REIDIN, Kullanıcılara sunduğu hizmetin kesintisiz, hatasız ve güvenli bir şekilde çalışması için elinden
                geleni yapar. REIDIN, kendisinin kontrolü dışında doğal afet veya internet kullanımında yaşanacak
                arızalar
                gibi mücbir sebeplerden kaynaklanan aksaklıklardan sorumlu değildir. Ayrıca bilgi güvenliği önlemi
                almasına
                rağmen, sistemlerine saldırı olması durumu da bu kapsamdadır.
              </li>
            </ol>
          </li>
          <li>
            <h4 class="subtitle">UYGULANACAK HUKUK VE YETKİ</h4>
            <ol>
              <li class="modal-body-text">
                İşbu Kullanım Koşulları’nın uygulanmasında, ihtilaflar Türk Hukuku’na göre çözümlenecektir. Kullanım
                Koşulları’ndan doğan her türlü ihtilafın çözümünde İstanbul Çağlayan Mahkemeleri ile İcra Daireleri
                yetkili ve
                görevlidir.
              </li>
            </ol>
          </li>
          <li>
            <h4 class="subtitle">YÜRÜRLÜK VE KABUL</h4>
            <ol>
              <li class="modal-body-text">
                İş bu kullanım şartları sitede yayınlandığı tarihte yürürlük kazanır. Kullanıcılar siteyi kullanmakla
                şartları
                kabul etmiş sayılırlar. Kullanıcı, işbu kullanım koşullarını kabul etmiyorsa, siteden herhangi bir
                şekilde
                yararlanmamalıdır. REIDIN, dilediği zaman sitede ilan etmek suretiyle işbu kullanım koşullarının bir
                kısmında
                veya tamamında değişiklik yapabilir.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary modal-footer-button" data-dismiss="modal">
          {{this.language === 'eng' ? 'Close' : 'Kapat'}}
        </button>
      </div>
    </div>
  </div>
</div>