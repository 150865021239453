import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbInputModule,
  NbLayoutModule,
  NbSelectModule,
  NbActionsModule,
  NbSidebarModule,
  NbDialogModule
} from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { AlertsModule } from '../alerts/alerts.module';
import { ActivateAccountComponent } from './activate-account/activate-account.component';

import { NgxAuthRoutingModule } from './auth-routing.module';
import { AuthenticationService } from '@insight-services/authentication.service';
import { NgxLogoutComponent } from './logout/logout.component';
import { NgxRegisterComponent } from './register/register.component';
import { NgxAuthComponent } from './ngx-auth.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxLoginComponent } from './login/login.component';
import { NgxRequestDemoComponent } from './request-demo/request-demo.component';
import { NgxPrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NgxTermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { NgxLandingPromotionComponent } from './landing-promotion/landing-promotion.component';
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component'

@NgModule({
    declarations: [
        NgxAuthComponent,
        NgxRegisterComponent,
        NgxLogoutComponent,
        ActivateAccountComponent,
        NgxLoginComponent,
        NgxRequestDemoComponent,
        NgxPrivacyPolicyComponent,
        NgxTermsAndConditionsComponent,
        NgxLandingPromotionComponent,
        ForgotPasswordComponent,
    ],
    providers: [
        AuthenticationService,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NbAlertModule,
        ThemeModule.forRoot(),
        NbLayoutModule,
        NbActionsModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        NbCardModule,
        NbSelectModule,
        NbDialogModule,
        NbSidebarModule,
        NgxAuthRoutingModule,
        AlertsModule,
        NbAlertModule,
        TranslateModule,
        LoginFormComponent,
        ResetPasswordComponent
    ]
})
export class AuthModule { }
