import { NgClass, NgIf } from "@angular/common";
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	inject,
	signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { concat, from, interval, of } from "rxjs";
import {
	concatMap,
	debounceTime,
	delay,
	ignoreElements,
	map,
	repeat,
	take,
} from "rxjs/operators";
import { Icon } from "../../models/models";

@Component({
	selector: "ngx-search-bar",
	standalone: true,
	imports: [NgIf, NgClass, ReactiveFormsModule],
	templateUrl: "./search-bar.component.html",
	styleUrls: ["./search-bar.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);

	@Input() borderRounded: "all" | "top" = "all";
	@Input() icon: Icon = "search";
	@Input() placeholders: string[];
	@Output() onSearch = new EventEmitter<string>();
	@Output() onBack = new EventEmitter();

	search = new FormControl<string>("");
	placeholder = signal("");

	@ViewChild("searchInput") searchInput: HTMLInputElement;

	constructor() {
		this.search.valueChanges
			.pipe(takeUntilDestroyed(), debounceTime(500))
			.subscribe({
				next: (search: string) => this.onSearch.emit(search),
			});
	}

	ngOnInit(): void {
		this.typeWriterEffect().subscribe({
			next: (word) => {
				this.placeholder.set(word);
			},
		});
	}

	back() {
		this.onBack.emit();
	}

	private typeWriterEffect() {
		return from(this.placeholders).pipe(
			concatMap((word) => this.typeEffect(word)),
			repeat(),
			takeUntilDestroyed(this.destroyRef)
		);
	}

	private typeEffect(word: string) {
		return concat(
			this.type(word, 70),
			of("").pipe(delay(1500), ignoreElements()),
			this.type(word, 30, true),
			of("").pipe(delay(300), ignoreElements())
		);
	}

	private type(word: string, speed: number, backward: boolean = false) {
		return interval(speed).pipe(
			map((x) =>
				backward
					? word.slice(0, word.length - x - 1)
					: word.slice(0, x + 1)
			),
			take(word.length)
		);
	}

	get border() {
		if (this.borderRounded === "all") return "rounded-top rounded-bottom";

		return "rounded-top";
	}

	get shadow() {
		if (this.borderRounded === "all") return "shadow-lg";
		return "";
	}
}
