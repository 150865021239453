import { Component, Input, Output, signal, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

type LoginForm = {
  email: FormControl<string>
  password: FormControl<string>
}

@Component({
  selector: 'ngx-login-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule
  ],
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  @Input() id: string
  @Input() loginForm: FormGroup<LoginForm>
  @Input() languageChose: boolean
  @Input() waitLogin: boolean
  @Input() submitted: boolean
  @Output() onSubmit = new EventEmitter();

  isPasswordVisible = signal<boolean>(false);

  get inputId() {
    return "input-email" + this.id
  }

  get passwordId() {
    return "input-password" + this.id
  }

  get checkboxId() {
    return "input-remember-me" + this.id
  }

  getPasswordInputType() {
    if (this.isPasswordVisible()) return 'text'
    return 'password'
  }

  showPassword() {
    this.isPasswordVisible.set(true);
  }

  hidePassword() {
    this.isPasswordVisible.set(false);
  }

  login() {
    this.onSubmit.emit();
  }

  get password() {
    return this.loginForm.controls.password
  }

  get email() {
    return this.loginForm.controls.email
  }

  get passwordHasErrors() {
    return (this.password!.dirty || this.password!.touched) && this.password!.errors
  }

  get emailHasErrors() {
    return (this.email!.dirty || this.email!.touched) && this.email!.errors
  }
}
