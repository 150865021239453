import { Component, OnInit, inject, signal } from '@angular/core';
import { AuthenticationService } from '@insight-services/authentication.service';
import { FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

const { required, email } = Validators

@Component({
  selector: 'ngx-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  private readonly authService = inject(AuthenticationService)
  private readonly toastrService = inject(ToastrService)

  public languageChose: boolean = true;
  public static passData: boolean

  email = new FormControl<string>("", [required, email])
  isLoading = signal<boolean>(false);
  isMailSent = signal<boolean>(false)

  ngOnInit(): void {
    if (sessionStorage.getItem('language') == null) {
      this.languageChose = this.languageChose;
    } else {
      if (sessionStorage.getItem('language') === 'true') {
        this.languageChose = true;
      } else {
        this.languageChose = false;
      }
      ForgotPasswordComponent.passData = this.languageChose;
    }
  }

  forgotPassword() {
    this.isLoading.set(true)
    this.authService.forgotPassword(this.email.value)
      .subscribe({
        next: result => {
          this.toastrService.success(result.message)
          this.isLoading.set(false)
          this.isMailSent.set(true)
        },
        error: error => {
          this.toastrService.error(error.error.message)
          this.isLoading.set(false)
        }
      })
  }
}

