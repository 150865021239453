import { NonNullableFormBuilder } from "@angular/forms";
import {
	Component,
	ViewChild,
	TemplateRef,
	HostListener,
	ChangeDetectionStrategy,
	signal,
	computed,
} from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import {
	NbMenuService,
	NbSidebarService,
	NbDialogService,
	NbDialogRef,
} from "@nebular/theme";
import { NbMenuItem } from "@nebular/theme/components/menu/menu.service";
import { TranslateService } from "@ngx-translate/core";
import { filter, map } from "rxjs/operators";
import { MenuService } from "@insight-services/menu.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { tap } from "rxjs/operators";
import { AuthenticationService } from "@insight-services/authentication.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";
import { FiltersComponent } from "../filters/filters.component";
import { MenuItem } from "@insight-models/menuItem";
import { Observable } from "rxjs";
import { Country } from "@insight-models/country";
import { ProductCodes } from "@insight-models/product-codes";

@Component({
	selector: "ngx-header",
	styleUrls: ["./header.component.scss"],
	templateUrl: "./header.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	screenHeight: number;
	screenWidth: number;
	iconClass: string = "menu-outline";
	isMenuItemsLoaded: boolean = false;

	currentUser$ = this.authService.getCurrentUser().pipe(takeUntilDestroyed());

	isSideMenuToggleButtonActive = computed(
		() => !!this.menuService.selectedTopMenuItem()
	);

	menuItems = computed(() => {
		const menuItems = this.menuService.topMenuItems();
		if (menuItems && menuItems.length > 0) return menuItems;
	});

	activeTab = computed(() => {
		const menuItem = this.menuService.selectedTopMenuItem();
		return menuItem?.id ?? 0;
	});

	@ViewChild("preferencesModalTemplate", { static: false })
	preferencesModal: TemplateRef<any>;

	userMenu: NbMenuItem[] = [
		{
			title: "main.general.buttons.preferences",
			icon: "options-outline",
			target: "preferences",
		},
		{
			title: "main.general.buttons.logout",
			icon: "log-out-outline",
			target: "logout",
		},
	];

	public availableLanguages: string[] = ["eng", "tur"];
	public availableCountries: string[] = ["AE", "TR"];

	modalOption: NgbModalOptions = {
		backdrop: "static",
		keyboard: false,
	};
	modalRef: NbDialogRef<any>;
	languageForm = this.formBuilder.group({
		languageValue: [this.translateService.currentLang],
	});

	constructor(
		private readonly nbSidebarService: NbSidebarService,
		private readonly router: Router,
		private readonly nbMenuService: NbMenuService,
		private readonly authService: AuthenticationService,
		public readonly translateService: TranslateService,
		public readonly menuService: MenuService,
		public readonly modalService: NgbModal,
		private readonly nbDialogService: NbDialogService,
		private readonly formBuilder: NonNullableFormBuilder,
		private readonly dialog: MatDialog,
		private readonly activatedRoute: ActivatedRoute
	) {
		this.getScreenSize();

		this.translateMenuItems();

		this.onNbMenuItemClick();

		this.activatedRoute.data.pipe(filter(Boolean)).subscribe({
			next: (menuItems: Data) => {
				this.menuService.setTopMenuItems(menuItems.menuItem);
			},
		});
	}

	@HostListener("window:resize", ["$event"])
	getScreenSize(event?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
		try {
			if (
				this.screenWidth < 1200 &&
				this.isCollapsed === false &&
				!this.menuService.isSidemenuCollapsed()
			) {
				this.nbSidebarService.collapse("menu-sidebar");
				//this.nbSidebarService.onCollapse();
				this.menuService.isSideMenuCollapsed.set(true);
			} else if (
				this.screenWidth > 1200 &&
				this.isCollapsed === true &&
				!this.menuService.isSidemenuCollapsed()
			) {
				this.nbSidebarService.expand("menu-sidebar");
				this.menuService.isSideMenuCollapsed.set(false);
				//this.nbSidebarService.onExpand();
			}
			this.iconClass = this.isCollapsed
				? "menu-outline"
				: "arrow-back-outline";
		} catch {}
	}

	translateMenuItems() {
		this.userMenu.forEach((menuItem) => {
			this.translateService
				.stream(menuItem.title)
				.pipe(takeUntilDestroyed())
				.subscribe(
					(translatedText) => (menuItem.title = translatedText)
				);
		});
	}

	onNbMenuItemClick() {
		return this.nbMenuService
			.onItemClick()
			.pipe(
				takeUntilDestroyed(),
				map(({ item: { target } }) => target),
				tap((target) => {
					if (target === "logout") {
						return this.router.navigateByUrl("/auth/logout");
					} else if (target === "reset-password") {
						return this.router.navigateByUrl(
							"/auth/forgot-password"
						);
					} else if (target === "preferences") {
						this.languageForm.controls.languageValue.setValue(
							this.translateService.currentLang
						);
						this.modalRef = this.nbDialogService.open(
							this.preferencesModal
						);
					}
				})
			)
			.subscribe();
	}

	public switchLanguage(language: string) {
		this.translateService.use(language);
	}

	toggleSidebar(): boolean {
		this.screenWidth = window.innerWidth;
		this.nbSidebarService.toggle(false, "menu-sidebar");
		if (this.screenWidth < 1200 && this.isCollapsed === true) {
			this.nbSidebarService.onExpand();
			this.menuService.isSideMenuCollapsed.set(false);
		} else if (this.screenWidth < 1200 && this.isCollapsed === false) {
			this.nbSidebarService.onCollapse();
			this.menuService.isSideMenuCollapsed.set(true);
		} else {
			this.menuService.isSideMenuCollapsed.set(
				!this.menuService.isSideMenuCollapsed()
			);
		}
		this.iconClass = this.isCollapsed
			? "menu-outline"
			: "arrow-back-outline";
		return true;
	}

	get isCollapsed() {
		return this.menuService.isSideMenuCollapsed();
	}

	setSelectedTopMenuItem(menuItem: MenuItem, i: number) {
		this.menuService.setSelectedTopMenuItem(menuItem);
		this.navigateToTheFirstDashboard(menuItem.child[1]);
	}

	private navigateToTheFirstDashboard(menuItem: MenuItem) {
		if (menuItem.child) {
			this.navigateToTheFirstDashboard(menuItem.child[0]);
		} else {
			if (menuItem.id)
				this.router.navigate(["/home/dashboard", menuItem.id]);
		}
	}

	setPreferences(formValues) {
		this.translateService.use(formValues.languageValue);
		this.modalRef.close();
		this.navigateToTheFirstDashboard(this.menuItems()[0].child[1]);
	}

	openFiltersDialog() {
		this.dialog.closeAll();
		this.dialog.open(FiltersComponent);
	}

	gotoNotifications() {
		this.menuService.setSelectedTopMenuItem(null);
		this.router.navigate(["/home/notification"]);
	}

	goToSearch() {
		this.menuService.navigateToSearch();
	}

	getCountry(): Observable<Country> {
		return this.authService.getCurrentUser().pipe(
			map((user) => {
				for (let product of user.products) {
					if (
						product.code.includes("insight") || product.code.includes("INSFULL")
					){
						return product.country as Country;
					}
				}
				return "AE";
			})
		);
	}
}
