import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'reidin-developer-icon',
    templateUrl: './developer-icon.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DeveloperIcon {
}