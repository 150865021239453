<!-- <nb-auth-block> -->

<h2 class="title"> {{ 'main.general.buttons.sign-up' | translate }}</h2>
<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger">
       <div *ngFor="let error of errors">{{ error }}</div>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success">
       <div *ngFor="let message of messages">{{ message }}</div>
</nb-alert>

<form (ngSubmit)="register()" #form="ngForm" autocomplete="off" *ngIf="!(showMessages.error || showMessages.success)">
       <h6>{{ 'main.views.register.sign-up-message' | translate }} </h6>
       <input autocomplete="false" name="hidden" type="text" style="display:none;">


       <div class="form-group">
              <label for="input-name" class="sr-only" translate="main.views.register.full-name"
                     translate-default="Full name">Full name</label>
              <input nbInput [(ngModel)]="user.name" #fullName="ngModel" id="input-name" name="name"
                     placeholder="{{ 'main.views.register.full-name' | translate }}" autofocus fullWidth
                     [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''" [required]=true [minlength]=4
                     [maxlength]=80>
              <small class="form-text error" *ngIf="fullName.invalid && fullName.touched && fullName.errors?.required"
                     translate="main.general.messages.warning.full-name-required"
                     translate-default="Full name is required!">
                     Full name is required!
              </small>
              <small class="form-text error"
                     *ngIf="fullName.invalid && fullName.touched && (fullName.errors?.minlength || fullName.errors?.maxlength)">
                     {{ 'main.general.messages.warning.full-name-character-valid' | translate: characterCheck }}
              </small>
       </div>

       <div class="form-group">
              <label for="input-email" class="sr-only" translate="main.views.register.email-address"
                     translate-default="Email address">Email address</label>
              <input nbInput [(ngModel)]="user.email" #email="ngModel" id="input-email" name="email" pattern=".+@.+..+"
                     placeholder="{{ 'main.views.register.email-address' | translate }}" fullWidth
                     [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
                     [required]="getConfigValue('forms.validation.email.required')">
              <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required"
                     translate="main.general.messages.warning.email-required" translate-default="Email is required!">
                     Email is required!
              </small>
              <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.pattern"
                     translate="main.general.messages.warning.username-invalid"
                     translate-default="Email should be the real one!">
                     Email should be the real one!
              </small>
       </div>
       <div class="form-group">
              <label for="input-phone" class="sr-only" translate="main.views.register.phone-number"
                     translate-default="Phone Number">Phone Number</label>
              <input nbInput [(ngModel)]="user.mobile_phone" #mobile_phone="ngModel" id="input-phone"
                     name="mobile_phone" placeholder="{{ 'main.views.register.phone-number' | translate }}" fullWidth
                     [required]=true>
              <small class="form-text error"
                     *ngIf="mobile_phone.invalid && mobile_phone.touched && mobile_phone.errors?.required"
                     translate="main.general.messages.warning.phone-required"
                     translate-default="Phone number is required!">
                     Phone number is required!
              </small>
       </div>

       <div class="form-group">
              <label for="input-password" class="sr-only" translate="main.views.register.password"
                     translate-default="Password">Password</label>
              <input nbInput [(ngModel)]="user.password" #password="ngModel" type="password" id="input-password"
                     name="password" autocomplete="new-password"
                     placeholder="{{ 'main.views.register.password' | translate }}" fullWidth
                     [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
                     [required]="getConfigValue('forms.validation.password.required')"
                     [minlength]="getConfigValue('forms.validation.password.minLength')"
                     [maxlength]="getConfigValue('forms.validation.password.maxLength')">
              <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required"
                     translate="main.general.messages.warning.password-required"
                     translate-default="Password is required!">
                     Password is required!
              </small>
              <small class="form-text error"
                     *ngIf="password.invalid && password.touched && (password.errors?.minlength || password.errors?.maxlength)">
                     {{ 'main.general.messages.warning.password-character-valid' | translate: characterCheck }}
              </small>
       </div>

       <div class="form-group">
              <label for="input-re-password" class="sr-only" translate="main.views.register.confirm-password"
                     translate-default="Repeat password">Repeat password</label>
              <input nbInput [(ngModel)]="user.confirmPassword" #rePass="ngModel" type="password" id="input-re-password"
                     name="rePass" placeholder="{{ 'main.views.register.confirm-password' | translate }}" fullWidth
                     [status]="email.dirty ? (email.invalid || password.value != rePass.value  ? 'danger' : 'success') : ''"
                     [required]="getConfigValue('forms.validation.password.required')">
              <small class="form-text error" *ngIf="rePass.invalid && rePass.touched && rePass.errors?.required"
                     translate="main.general.messages.warning.password-confirmation-required"
                     translate-default="Password confirmation is required!">
                     Password confirmation is required!
              </small>
              <small class="form-text error"
                     *ngIf="rePass.touched && password.value != rePass.value && !rePass.errors?.required"
                     translate="main.general.messages.warning.password-confirmation-match-required"
                     translate-default="Password does not match the confirm password.">
                     Password does not match the confirm password.
              </small>
       </div>

       <button nbButton status="success" fullWidth [disabled]="submitted || !form.valid" [class.btn-pulse]="submitted"
              translate="main.views.register.reg_button" translate-default="Register">
              Register
       </button>
</form>

<div class="links">

       <!--    <ng-container *ngIf="socialLinks && socialLinks.length > 0">
              <small class="form-text">Or connect with:</small>

              <div class="socials">
                <ng-container *ngFor="let socialLink of socialLinks">
                  <a *ngIf="socialLink.link"
                     [routerLink]="socialLink.link"
                     [attr.target]="socialLink.target"
                     [attr.class]="socialLink.icon"
                     [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
                  <a *ngIf="socialLink.url"
                     [attr.href]="socialLink.url"
                     [attr.target]="socialLink.target"
                     [attr.class]="socialLink.icon"
                     [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
                </ng-container>
              </div>
            </ng-container>-->

       <small class="form-text" translate="main.views.register.already-account"
              translate-default="Already have an account?">Already have an account?
              <a routerLink="../login"><strong translate="main.general.buttons.sign-in" translate-default="Sign in">Sign
                            in</strong></a>
       </small>
</div>
<!-- </nb-auth-block> -->