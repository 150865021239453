import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AlertType } from "@insight-models/alert-type";
import { TranslateService } from '@ngx-translate/core';
import { Alert } from '@insight-models/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private subject = new Subject<Alert>();
  private keepAfterRouteChange = false;

  constructor(private router: Router, private translate: TranslateService) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
      return;
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Success, message, keepAfterRouteChange);
  }

  error(message: string, keepAfterRouteChange = false, detail?: any) {
    this.alert(AlertType.Error, message, keepAfterRouteChange, detail);
  }

  info(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Info, message, keepAfterRouteChange);
  }

  warn(message: string, keepAfterRouteChange = false) {
    this.alert(AlertType.Warning, message, keepAfterRouteChange);
  }

  alert(
    type: AlertType,
    message: string,
    keepAfterRouteChange = false,
    detail?: any
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(<Alert>{ type: type, message: message, detail: detail });
  }

  clear() {
    // clear alerts
    this.subject.next();
  }

  userServiceErrorCheck(errorCode: string | number, detail?: any): void {
    let toBeTranslated = '';

    this.clear();

    switch (errorCode) {
      case '101':
      case '102':
      case '103':
      case '104':
      case '105':
      case '106':
      case '107':
      case '108':
      case 401:
        toBeTranslated = this.translate.instant(
          `main.general.messages.error.${errorCode}`
        );

        this.error(toBeTranslated);
        break;
      default:
        toBeTranslated = this.translate.instant(
          'main.general.messages.error.general-error'
        );
        this.error(toBeTranslated, false, detail);
        break;
    }
  }

  errorCheck(errorCode: number): void {
    let toBeTranslated = '';

    this.clear();

    switch (errorCode) {
      case 401:
        toBeTranslated = this.translate.instant(
          'main.general.messages.error.unauthorized-usage'
        );

        this.error(toBeTranslated, true);

        // this.logoutService.accountLogout();

        // this.storageService.removeObject('currentUser');

        // this.router.navigate(['/login']);
        break;
      case 402:
        toBeTranslated = this.translate.instant(
          'main.general.messages.error.unauthorized-product'
        );

        this.error(toBeTranslated, true);

        // this.logoutService.accountLogout();

        // this.storageService.removeObject('currentUser');

        // this.router.navigate(['/login']);
        break;
      default:
        /* toBeTranslated = this.translate.instant(
            'main.general.messages.error.general-error',
        );

        this.alertService.error(toBeTranslated);*/
        break;
    }
  }
}
