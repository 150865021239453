import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


export class NewsPost {
  title: string;
  link: string;
  author: string;
  description: string;
  thumbnail: string;
  pubDate: string;
}


@Injectable({
  providedIn: 'root'
})
export class BlogfeedService {
  private rss_url: string;
  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) {

  }

  load(): Observable<any> {
  // TO DO: to be added language URL mechanism
    switch (this.translateService.currentLang) {
      case ('tur'):
        this.rss_url = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fblog.reidin.com%2Ftr%2Ffeed%2F';
        break;
      default:
        this.rss_url = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fblog.reidin.com%2Fen%2Ffeed%2F';
        break;
    }
    return this.http.get(this.rss_url)
      .pipe(
        map(this.extractFeeds));

  }

  private  extractFeeds(res: Response) {
    const body = res;
    return body || {};
  }

}
