import { Component, OnInit, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Column, NotificationTableComponent } from '../notification-table/notification-table.component';
import {
  SupplyDataNotificationFilter,
  SupplyDataNotificationFilterResponse
} from '@insight-models/supply-data-notification-filter';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { PowerBIComponent } from '../../power-bi/power-bi.component';
import { of } from 'rxjs';
import { models } from 'powerbi-client';
import { BaseNotification } from '../base-notification';

@Component({
  selector: 'ngx-supply-data',
  standalone: true,
  templateUrl: './supply-data.component.html',
  styleUrls: ['./supply-data.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    PowerBIComponent,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    NotificationTableComponent
  ]
})
export class SupplyDataComponent extends BaseNotification<SupplyDataNotificationFilter, SupplyDataNotificationFilterResponse> implements OnInit {
  override filterMapping = {
    "City": "city",
    "Area": ["area", "community"],
    "Property Subtype": "propertyType",
    "Developer": "developer",
    "Status": "status"
  }

  selectedDashboard$ = of({
    menu_id: 325,
    dashboard_id: "4b667762-05df-4953-b643-d5d1ae0ea578"
  })

  columns: Column<SupplyDataNotificationFilterResponse>[] = [
    {
      header: "City",
      columnDef: "city",
      cell: (element: SupplyDataNotificationFilterResponse) => element.city
    },
    {
      header: "Area",
      columnDef: "area",
      cell: (element: SupplyDataNotificationFilterResponse) => element.area
    },
    {
      header: "Community",
      columnDef: "community",
      cell: (element: SupplyDataNotificationFilterResponse) => element.community
    },
    {
      header: "Developer",
      columnDef: "developer",
      cell: (element: SupplyDataNotificationFilterResponse) => element.developer
    },
    {
      header: "Property Type",
      columnDef: "propertyType",
      cell: (element: SupplyDataNotificationFilterResponse) => element.propertyType
    },
    {
      header: "Status",
      columnDef: "status",
      cell: (element: SupplyDataNotificationFilterResponse) => element.status
    },
  ]

  constructor() {
    super()
    effect(() => {
      if (this.powerBIReportComponent())
        this.getSlicers(this.powerBIReportComponent())
    })
  }

  ngOnInit(): void {
    this.notificationService.setSelectedNotification({
      value: 'supply-data',
      name: 'Supply Data',
      dashboardId: 325
    });

    this.GetNotificationFilters(325)
      .subscribe({
        next: (value: any[]) => {
          this.data.data = value
        }
      })
  }

  isTargetHierarchy(slicerState: models.ISlicerState) {
    const target = slicerState.targets[0] as models.IFilterColumnTarget
    const column = target.column;
    const keys = ["Area"]

    for (let key of keys) {
      if (column === key) return true
    }

    return false
  }
}
