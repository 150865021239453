import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { SearchBarComponent } from "../ui/search-bar/search-bar.component";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { SearchProxyService } from "../data-access/search-proxy.service";
import { SubCategory, Paginate, OpenDashboardParams } from "../models/models";
import { SearchService } from "../data-access/search.service";
import { Country } from "@insight-models/country";
import { MenuService } from "@insight-services/menu.service";
import { take, tap } from "rxjs/operators";
import { LocationIcon } from "../ui/location-icon/location-icon.component";
import { DeveloperIcon } from "../ui/developer-icon/developer-icon.component";
import { BuildingIcon } from "../ui/building-icon/building-icon.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CityIcon } from "../ui/city-icon/city-icon.component";

@Component({
	selector: "ngx-search",
	templateUrl: "./search.component.html",
	styleUrls: ["./search.component.scss"],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgFor,
		NgIf,
		NgClass,
		SearchBarComponent,
		LocationIcon,
		DeveloperIcon,
		BuildingIcon,
		TranslateModule,
		CityIcon
	],
	providers: [SearchProxyService, SearchService, TranslateService],
})
export class SearchComponent {
	private readonly searchService = inject(SearchService);
	private readonly menuService = inject(MenuService);

	country:Country;

	constructor() {
		this.setSelectedTopMenuItem();
		this.getCountry()
			.pipe(
				tap((country) => this.country = country),
				take(1)
			)
			.subscribe();	
	}

	readonly search = this.searchService.search;

	readonly result = this.searchService.result;

	readonly placeholders = this.searchService.placeholders;

	readonly paginationState = this.searchService.paginationState;

	readonly selected = this.searchService.selected;
	
	readonly isLoading = this.searchService.isLoading;

	readonly stage = this.searchService.stage;

	readonly icon = this.searchService.icon;

	back() {
		this.searchService.back();
	}

	getCountry() {
		return this.searchService.getCountry();
	}

	searchReport() {
		return this.searchService.searchReport();
	}

	onSearchReport(search: string) {
		if(search.length < 3) return;
		this.searchService.onSearchReport(search);
	}

	getMore(category:string){
		return this.searchService.getMore(category);
	}

	get searchInputBorder() {
		return this.searchService.searchInputBorder;
	}

	selectResult(
		type: keyof SubCategory,
		value: string[],
		locationLevel?: string
	) {
		this.searchService.selectResult(type, value, locationLevel);
	}

	openDashboard(openDashboardParams: OpenDashboardParams) {
		this.searchService.openDashboard(openDashboardParams);
	}

	resetState() {
		this.searchService.resetState();
	}

	setSelectedTopMenuItem() {
		if (!this.menuService.selectedTopMenuItem()) {
			this.menuService.setSelectedTopMenuItem(
				this.menuService.topMenuItems()[0]
			);
		}
	}
}
