<nb-card [style.width.%]="57">
  <form (ngSubmit)="f.form.valid && activateDevice()" #f="ngForm" aria-labelledby="title">
    <nb-card-body>
      <!-- <div class="form-control-group">
        <nb-alert status="danger" translate="main.views.login-page.multiple-device.content"
                  translate-default="DEvice Content">.
        </nb-alert>
        <label class="label" for="activation_key" translate="main.views.login-page.multiple-device.activation-key-input"
               translate-default="Activation Key">.</label>
        <input nbInput
               fullWidth
               name="activation_key"
               id="activation_key"
               [(ngModel)]="activation_key"
               placeholder="{{ 'main.views.login-page.multiple-device.activation-key-input' | translate }}"
               autofocus
               required>
      </div> -->
      <!--    <form
            #f="ngForm"
            (ngSubmit)="f.form.valid && addDevice()"
            novalidate>
            <rdm-alert></rdm-alert>
            <div class="modal-header layout-row flex-align-start-center">
              <span class="ricon-plus font-md padding-top-xs margin-right-md color-green"></span>
              <p
                class="paragraph-head-color font-xs no-margin no-margin-top padding-top-xs flex"
                translate="main.views.login-page.multiple-device.title"
                translate-default="Multiple Device Confirmation">>Çoklu Giriş Onay Ekranı</p>
            </div>
            <div class="modal-body">
              <div class="row" [ngClass]="{ 'has-error': f.submitted && !modalToken.valid }">
                <p
                  class="font-13 content-color col-12"
                  translate="main.views.login-page.multiple-device.content"
                  translate-default="Multiple Device Confirmation">To Login the system enter the code that has been sent to your registered E-mail address then click the 'Approve' button, please.</p>
                <input
                  class="mail-token-area col padding-md margin-left-md margin-right-md"
                  name="modalToken"
                  type="text"
                  [(ngModel)]="model.modalToken"
                  #modalToken="ngModel"
                  required/>

                <div
                  *ngIf="f.submitted && !modalToken.valid"
                  class="help-block col-12 color-red font-12"
                  translate="main.general.messages.warning.code-required"
                  translate-default="Code is required">Code is required!
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-default primary-background padding-md-top-bottom min-width-120 seperator-all"
                type="button"
                (click)="close('Close button click');"
                translate="main.general.buttons.cancel"
                translate-default="Cancel">Cancel</button>

              <button
                class="btn btn-default green-back padding-md-top-bottom min-width-120 font-semibold login-button send-button"
                type="submit"
                translate="main.general.buttons.approve"
                [disabled]="modalLoading"
                [ngClass]="{ 'sending': modalAnimationActive }"
                translate-default="Approve">Approve</button>
            </div>
          </form>-->
    </nb-card-body>
    <nb-card-footer>
      <button nbButton
              status="danger"
              class="pull-right"
              type="button"
              (click)="close()"
              translate="main.general.buttons.cancel"
              translate-default="Cancel">Cancel
      </button>

      <button nbButton status="success" class="pull-right"
              type="submit"
              [disabled]="modalLoading"
              translate="main.general.buttons.approve"
              translate-default="Approve">Approve
      </button>

    </nb-card-footer>
  </form>
</nb-card>

