import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { environment } from '@insight-environments/environment';
import { ListResultModel } from '@insight-models/result-model';
import { NotificationResponse } from '@insight-models/notification-response';
import { Notification } from '@insight-models/notification';
import { User } from '@insight-models/user';
import {
  SaleTransactionNotificationFilter,
  SaleTransactionNotificationFilterResponse
} from '@insight-models/sale-transaction-notification-filter';
import { catchError, map } from 'rxjs/operators';
import {
  RentTransactionNotificationFilter,
  RentTransactionNotificationFilterResponse
} from '@insight-models/rent-transaction-notification-filter';
import {
  ListingMonitorNotificationFilter,
  ListingMonitorNotificationFilterResponse
} from '@insight-models/listing-monitor-notification-filter';
import {
  SupplyDataNotificationFilter,
  SupplyDataNotificationFilterResponse
} from '@insight-models/supply-data-notification-filter';
import { EMPTY, Observable } from 'rxjs';

export type NotificationFilter = SaleTransactionNotificationFilter
  | RentTransactionNotificationFilter
  | ListingMonitorNotificationFilter
  | SupplyDataNotificationFilter

export type NotificationFilterResponse = SaleTransactionNotificationFilterResponse
  | RentTransactionNotificationFilterResponse
  | ListingMonitorNotificationFilterResponse
  | SupplyDataNotificationFilterResponse

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly httpClient = inject(HttpClient)
  private readonly authenticationService = inject(AuthenticationService)

  private readonly backendEndpoint = environment.backendAPIUrl + "/api";

  readonly #selectedNotification = signal<Notification>(undefined);
  readonly selectedNotification = this.#selectedNotification.asReadonly()

  readonly notificationList: Notification[] = [
    {
      dashboardId: 514,
      name: 'Sales Transactions',
      value: 'sales-transactions'
    },
    {
      dashboardId: 515,
      name: 'Rent Transactions',
      value: 'rent-transactions'
    },
    {
      dashboardId: 330,
      name: 'Listing Monitor',
      value: 'listing-monitor'
    },
    /*{
      dashboardId: 331,
      name: 'Listing Data',
      value: 'listing-data'
    },*/
    {
      dashboardId: 325,
      name: 'Supply Data',
      value: 'supply-data'
    }
  ];

  getEmail() {
    const user = JSON.parse(localStorage.getItem("user")) as User
    return user.email;
  }

  saveNotification(notification: NotificationFilter, frequency: 1 | 7 | 30) {
    const user = this.authenticationService.getUser();
    const notificationRequest: any = {
      user_id: user.id,
      company_name: user.company.company_name,
      frequency: frequency,
      dashboard_id: this.selectedNotification().dashboardId,
      is_active: true,
      notification_mail: user.email,
      notification_name: "test",
      user_mail: user.email,
      user_name: user.name,
      filter: notification
    }
    return this.httpClient.post<any>(`${this.backendEndpoint}/save_notification`, notificationRequest);
  }

  updateNotification(notification: NotificationFilter, frequency: 1 | 7 | 30, id: number) {
    const user = this.authenticationService.getUser();
    const notificationRequest: any = {
      user_id: user.id,
      company_name: user.company.company_name,
      frequency: frequency,
      dashboard_id: this.selectedNotification().dashboardId,
      is_active: true,
      notification_mail: user.email,
      notification_name: "test",
      user_mail: user.email,
      user_name: user.name,
      filter: notification
    }
    return this.httpClient.patch<any>(`${this.backendEndpoint}/save_notification/${id}/`, notificationRequest);
  }

  getNotifications(dashboardId: number) {
    const user = this.authenticationService.getUser();
    return this.httpClient.get<ListResultModel<NotificationResponse>>(`${this.backendEndpoint}/get_notification/${user.id}/?dashboard_id=${dashboardId}`)
  }

  getNotificationFilters<T extends NotificationFilter>(dashboardId: number): Observable<T[]> {
    return this.getNotifications(dashboardId)
      .pipe(
        catchError((error: HttpErrorResponse) => EMPTY),
        map(result => {
          const filters = result.data.filter(data => data.dashboard_id === dashboardId)
            .sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime())
            .map(data => {
              data.filter.id = data.id;
              return data
            }).map(data => ({
              ...data.filter,
              frequency: data.frequency,
              email: data.user_mail
            })) as T[];
          return filters;
        })
      );
  }

  deleteNotification(notification: NotificationFilterResponse): Observable<number> {
    const user = this.authenticationService.getUser();
    const notificationRequest: any = {
      user_id: user.id,
      company_name: user.company.company_name,
      frequency: notification.frequency,
      dashboard_id: this.selectedNotification().dashboardId,
      is_active: false,
      notification_mail: user.email,
      notification_name: "test",
      user_mail: user.email,
      user_name: user.name,
      filter: notification,
    }
    return this.httpClient.patch<any>(`${this.backendEndpoint}/save_notification/${notification.id}/`, notificationRequest)
      .pipe(
        map(data => data.pk)
      );
  }

  setSelectedNotification(notification: Notification) {
    this.#selectedNotification.set(notification)
  }
}
