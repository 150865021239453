<div class="container-fluid h-100 d-flex flex-column">
	<!-- Breadcrumb -->
	<div
		*ngIf="selectedDashboardMainPath"
		style="margin-top: 2px; margin-left: -10px"
		class="row align-items-left overflow-hidden"
	>
		<div class="col align-items-center breadcrumb">
			<span class="text-muted ml-2">{{ selectedDashboardMainPath }}</span>
			<span
				class="text-muted ml-2"
				style="font-family: 'Gilroy-Bold'; font-size: '1.2em'"
				>{{ selectedDashboardLastPath }}</span
			>
		</div>
	</div>

	<div
		*ngIf="selectedDashboard() as selectedDashboard"
		id="test"
		class="d-flex flex-column flex-fill"
	>
		<!-- Tabs -->
		<div
			id="test-innner"
			*ngIf="selectedDashboard.dashboard_id"
			class="d-flex flex-column flex-fill"
		>
			<!-- Tabs as buttons -->

			<div class="export-container">
				<div
					class="col align-self-end d-flex py-2 px-1 justify-content-end"
					style="gap: 1rem"
				>
					<button
						nbButton
						[status]="'success'"
						size="small"
						[nbSpinner]="isSaving()"
						[nbSpinnerStatus]="'warning'"
						[disabled]="!readyToExport()"
						[nbSpinnerMessage]="'Preparing..'"
						id="save-filter"
						(click)="openSaveDashobardFiltersDialog()"
					>
						<ng-container *ngIf="!isSaving()">
							<p class="save-filter-button-text">
								{{
									"main.general.buttons.save-filter"
										| translate
								}}
							</p>
						</ng-container>
					</button>
					<div *ngIf="isPrint">
						<button
						nbButton
						[status]="'danger'"
						size="small"
						[nbSpinner]="exporting()"
						[disabled]="exporting() || !readyToExport()"
						[nbSpinnerStatus]="'warning'"
						[nbSpinnerMessage]="'Preparing..'"
						(click)="
							exportDashboard(selectedDashboard.dashboard_id)
						"
						id="print-icon-label"
					>
						<span id="export-pdf-button-span" *ngIf="!exporting()">
							<img
								id="export-pdf-icon"
								src="../../../assets/icons/pdf-export-icon.svg"
							/>
							{{
								"main.general.buttons.export-to-pdf" | translate
							}}
						</span>
						<!-- Export to PDF -->
						</button>
					</div>			
				</div>
			</div>

			<!-- Dashboard -->
			<div
				class="col-12 p-0 align-items-center overflow-hidden d-flex flex-column flex-fill print-board"
			>
				<div class="h-100 w-100">
					<ngx-power-bi
						[selectedDashboard]="selectedDashboard$"
						(onReportRendered)="rendered()"
						(onLoading)="loading()"
					></ngx-power-bi>
				</div>
			</div>
		</div>
	</div>
</div>
