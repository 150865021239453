import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "@insight-environments/environment";
import { PaginatedResultModel } from "@insight-models/result-model";
import {
	Paginate,
	SubCategory,
} from "../models/models";
import { Country } from "@insight-models/country";
import { Observable, of } from "rxjs";

const BASE_URL = environment.backendAPIUrl + "/api";

@Injectable()
export class SearchProxyService {
	private readonly httpClient = inject(HttpClient);

	searchByCategoryName<T>(
		parameter:string,
		search: string,
		country: Country = "TR",
		paginate: Paginate
	):Observable<PaginatedResultModel<T>>{
		if(country == "AE" && parameter == "city") return of();
		return this.httpClient.get<PaginatedResultModel<T>>(
			`${BASE_URL}/search_${parameter=="building" ? "property": parameter}?params=${search}&country=${country}&page=${paginate.page}&page_size=${paginate.size}`
		);
	}

	getSubCategories(country:string,locationLevel:string): SubCategory {
			return environment.production 
			? this.getProdSubCategories(country,locationLevel)
			: this.getBetaSubCategories(country,locationLevel);
	}

	getProdSubCategories(country:string,locationLevel:string): SubCategory {
		if(country == "AE"){
			return {
				city:[],
				location: [
					{
						name: "Area Report",
						dashboardId: 754,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "ef6733cb8093963910eb",
							},
							{
								filterType: "basic",
								visualName: "e071baf48b0dd6a3da28",
							},
						],
					},
					{
						name: "Price Report",
						dashboardId: 758,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "141d7e507b2fe92f55d3",
							},
							{
								filterType: "basic",
								visualName: "01c7e6210d4fa2ba4600",
							},
						],
					},
					{
						name: "Sales Transactions",
						dashboardId: 840,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "2cd42840cf693689afe6",
							},
						],
					},
					{
						name: "Rent Transactions",
						dashboardId: 841,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "729af213157561186a1e",
							},
						],
					},
					{
						name: "Listing Monitor",
						dashboardId: 846,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "c59575a331d2d1914866",
							},
							{
								filterType: "basic",
								visualName: "ad8230a3d9da6cdb2942",
							},
						],
					},
					{
						name: "Listing Data",
						dashboardId: 847,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "59e43787659d2e2cee90",
							},
							{
								filterType: "basic",
								visualName: "8b662461a7bf2d60d6a6",
							},
						],
					},
					{
						name: "Sales Prices",
						dashboardId: 1117,
						slicers: [
							{
								filterType: "basic",
								visualName: "a8c3fbf58e1311ac67c0",
							},
						],
					},
					{
						name: "Rent Prices",
						dashboardId: 1117,
						slicers: [
							{
								filterType: "basic",
								visualName: "a8c3fbf58e1311ac67c0",
							},
						],
					},
					{
						name: "Rental Yields",
						dashboardId: 1118,
						slicers: [
							{
								filterType: "basic",
								visualName: "73f77620eb3c642b4e49",
							},
						],
					},
					{
						name: "Residential Supply",
						dashboardId: 763,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "6bad5a605378b0046378",
							},
							{
								filterType: "basic",
								visualName: "266ddb0154d9a4eb70eb",
							},
						],
					},
					{
						name: "Occupancy Rates",
						dashboardId: 759,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "22bf8f23400086390231",
							},
							{
								filterType: "basic",
								visualName: "e21e96e210e784583e2b",
							},
						],
					},
					{
						name: "Occupancy Rates",
						dashboardId: 760,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "48b1df0e456006991398",
							},
							{
								filterType: "basic",
								visualName: "b60018c953e8970639e3",
							},
						],
					},
				],
				building: [
					{
						name: "Residential Property Report",
						dashboardId: 755,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "8377ee3bed0c0060770e",
							},
							{
								filterType: "basic",
								visualName: "42daf6ac6bc0ac358d62",
							},
							{
								filterType: "basic",
								visualName: "ae5bb801e47320d472d0",
							},
						],
					},
					{
						name: "Sales Transaction",
						dashboardId: 840,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "2cd42840cf693689afe6",
							},
							{
								filterType: "basic",
								visualName: "0a209db726795b69c934",
							},
						],
					},
					{
						name: "Rent Transaction",
						dashboardId: 841,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "729af213157561186a1e",
							},
							{
								filterType: "basic",
								visualName: "121b895511ce90ec1128",
							},
						],
					},
					{
						name: "Listing Monitor",
						dashboardId: 846,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "c59575a331d2d1914866",
							},
							{
								filterType: "basic",
								visualName: "85dcfc364a7512a20177",
							},
							{
								filterType: "basic",
								visualName: "ad8230a3d9da6cdb2942",
							},
						],
					},
					{
						name: "Sales Prices",
						dashboardId: 996,
						cityAndCommunity: true,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "4bf1926451ad08ed7b04",
							},
							{
								filterType: "basic",
								visualName: "edb6f6401a043074a9ec",
							},
						],
					},
					{
						name: "Rent Prices",
						dashboardId: 996,
						cityAndCommunity: true,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "4bf1926451ad08ed7b04",
							},
							{
								filterType: "basic",
								visualName: "edb6f6401a043074a9ec",
							},
						],
					},
					{
						name: "Rental Yields",
						dashboardId: 997,
						cityAndCommunity: true,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "4bf1926451ad08ed7b04",
							},
							{
								filterType: "basic",
								visualName: "edb6f6401a043074a9ec",
							},
						],
					},
					{
						name: "Office Property Report",
						dashboardId: 769,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "d1711807e4d621727ae6",
							},
							{
								filterType: "basic",
								visualName: "96b77674a032d5509f72",
							},
							{
								filterType: "basic",
								visualName: "e6b7e3d0f3568ae6f44b",
							},
						],
					},
					{
						name: "Occupancy Rates",
						dashboardId: 957,
						cityAndCommunity: true,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "f9077611b8943f3577fd",
							},
						],
					},
					{
						name: "Service Charges",
						dashboardId: 958,
						cityAndCommunity: true,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "3fcd416029903750ba45",
							},
						],
					},
				],
				developer: [
					{
						name: "Developer Report",
						dashboardId: 756,
						slicers: [
							{
								filterType: "basic",
								visualName: "0b2a3714061bc3897b4e",
							},
						],
					},
					{
						name: "Sales Transactions",
						dashboardId: 840,
						slicers: [
							{
								filterType: "basic",
								visualName: "78f805dc121002bbdf7a",
							},
						],
					},
					{
						name: "Residential Supply",
						dashboardId: 763,
						slicers: [
							{
								filterType: "basic",
								visualName: "f1ea9b90290a12b7bcc5",
							},
						],
					},
					{
						name: "Construction Status Report",
						dashboardId: 764,
						slicers: [
							{
								filterType: "basic",
								visualName: "38c65ca7dc00e00a3010",
							},
						],
					},
					{
						name: "Payment Plans",
						dashboardId: 765,
						slicers: [
							{
								filterType: "basic",
								visualName: "8ec6b6730b00e32a582c",
							},
						],
					},
				],
			};
		}else{
			return {
				city: [
					{
						name: "Il Raporu",
						dashboardId: 881,
						slicers: [
							{
								filterType: "basic",
								visualName: "8096f158973969ed1a68",
							},
						],
					},
					{
						name: "Konut Satış Fiyatları",
						dashboardId: 931,
						slicers: [
							{
								filterType: "basic",
								visualName: "84fceade4e1027638c93",
							},
						],
					},
					{
						name: "Konut Kira Fiyatları",
						dashboardId: 932,
						slicers: [
							{
								filterType: "basic",
								visualName: "6d11f66736431ab7b839",
							},
						],
					},
					{
						name: "Konut Satış Endeksleri",
						dashboardId: 936,
						slicers: [
							{
								filterType: "basic",
								visualName: "9b36e22b6eb28954557d",
							},
						],
					},
					{
						name: "Konut Kira Endeksleri",
						dashboardId: 937,
						slicers: [
							{
								filterType: "basic",
								visualName: "9d854d80a4b0a20b994d",
							},
						],
					},
					{
						name: "Konut Sayıları",
						dashboardId: 883,
						slicers: [
							{
								filterType: "basic",
								visualName: "898890d300d2a0b7c822",
							},
						],
					},
					{
						name: "Konut Satışları",
						dashboardId: 884,
						slicers: [
							{
								filterType: "basic",
								visualName: "dbf4cf816a2915234958",
							},
						],
					},
					{
						name: "Yabancılara Satışlar",
						dashboardId: 885,
						slicers: [
							{
								filterType: "basic",
								visualName: "c29df53a3822077237a3",
							},
						],
					},
					{
						name: "Ofis Trendleri",
						dashboardId: 922,
						slicers: [
							{
								filterType: "basic",
								visualName: "bfd3c70e90f4911a9247",
							},
						],
					},
					{
						name: "Alışveriş Merkezi Stoğu",
						dashboardId: 924,
						slicers: [
							{
								filterType: "basic",
								visualName: "c768acf419fad1522b92",
							},
						],
					},
					{
						name: "Cinsiyete Göre Nüfus",
						dashboardId: 948,
						slicers: [
							{
								filterType: "basic",
								visualName: "85f89d45120a48cd3529",
							},
						],
					},
				],
				location: [
					{
						name: "Bölge Raporu",
						dashboardId: 879,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "d65b1a6c7a012a32cc7c",
							},
							{
								filterType: "basic",
								visualName: "9cf5643347b3ee28ae57",
							},
						],
					},
					{
						name: "Konut Satış Fiyatları",
						dashboardId: 931,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "095ae837470785ae1629",
							},
							{
								filterType: "basic",
								visualName: "7b15b0952569b9063450",
							}						
							] : [
							{
								filterType: "basic",
								visualName: "7529ca25880c589b7592",
							},
							{
								filterType: "basic",
								visualName: "453b8fbd29c2842a9289",
							},
							{
								filterType: "basic",
								visualName: "7282f27f80935001bcd0",
							}
						],
					},
					{
						name: "Konut Kira Fiyatları",
						dashboardId: 932,
						slicers: locationLevel != "District" ? [
								{
									filterType: "basic",
									visualName: "194151946c0e0034ee05",
								},
								{
									filterType: "basic",
									visualName: "f8d62cf92b358b047809",
								},					
							] : [
								{
									filterType: "basic",
									visualName: "03dc9cdb523a47e7d421",
								},
								{
									filterType: "basic",
									visualName: "5921868929a7175c5670",
								},
								{
									filterType: "basic",
									visualName: "20807136505671b6176e",
								}								
							],
					},
					{
						name: "Konut Yatırımı Brüt Getiri Oranları",
						dashboardId: 933,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "e21933c6c67209e76686",
							},
							{
								filterType: "basic",
								visualName: "f17e0f102340e00bdd38",
							}						
							] : [
							{
								filterType: "basic",
								visualName: "97a23624b600ad3695de",
							},
							{
								filterType: "basic",
								visualName: "13d66b8fc17abaac28b8",
							},
							{
								filterType: "basic",
								visualName: "a97785069d9d97729117",
							},
						]
					},
					{
						name: "Konut Satış Endeksleri",
						dashboardId: 936,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "395625635adee084a352",
							},
							{
								filterType: "basic",
								visualName: "9b36e22b6eb28954557d",
							}						
							] : [
							{
								filterType: "basic",
								visualName: "119319e2d2aa0d375146",
							},
							{
								filterType: "basic",
								visualName: "9b36e22b6eb28954557d",
							},
							{
								filterType: "basic",
								visualName: "395625635adee084a352",
							},
						],
					},
					{
						name: "Konut Kira Endeksleri",
						dashboardId: 937,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "50bf125ebeaa9e6d0a14",
							},
							{
								filterType: "basic",
								visualName: "9d854d80a4b0a20b994d",
							}						
							] : [
							{
								filterType: "basic",
								visualName: "7e4bec5a6d0b65487007",
							},
							{
								filterType: "basic",
								visualName: "9d854d80a4b0a20b994d",
							},
							{
								filterType: "basic",
								visualName: "50bf125ebeaa9e6d0a14",
							},
						],
					},
					{
						name: "Konut İlan Analizi",
						dashboardId: 882,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "74df7ca3cc29d9e40200",
							},
							{
								filterType: "basic",
								visualName: "3a621c89e4b9399f35b0",
							},
						],
					},
					{
						name: "Konut Satışları",
						dashboardId: 884,
						slicers: [
							{
								filterType: "basic",
								visualName: "222f05b6a51cd4c9d0bc",
							},
							{
								filterType: "basic",
								visualName: "dbf4cf816a2915234958",
							},
						]
					},
					{
						name: "Konut Sayıları",
						dashboardId: 883,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "7be2370710de2b908809",
							},
							{
								filterType: "basic",
								visualName: "898890d300d2a0b7c822",
							}
						]: [
							{
								filterType: "basic",
								visualName: "5331d672428abc3de476",
							},
							{
								filterType: "basic",
								visualName: "898890d300d2a0b7c822",
							},
							{
								filterType: "basic",
								visualName: "7be2370710de2b908809",
							},
						]
					},
					{
						name: "Detaylı İlan Verileri",
						dashboardId: 943,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "66ef1e27dde720b61290",
							},
							{
								filterType: "basic",
								visualName: "f7a69182dde2de1d0b18",
							},
						],
					},
					{
						name: "Bağımsız Bölüm Satışları",
						dashboardId: 972,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "dddacd5404cf4d50fa7a",
							},
							{
								filterType: "basic",
								visualName: "2ecea579f4b81c9bfa87",
							},
						],
					},
					{
						name: "Ana Taşınmaz Satışları",
						dashboardId: 968,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "dddacd5404cf4d50fa7a",
							},
							{
								filterType: "basic",
								visualName: "2ecea579f4b81c9bfa87",
							},
						],
					},
					{
						name: "Ofis İlan Analizi",
						dashboardId: 923,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "49102238750a2a9cea7a",
							},
							{
								filterType: "basic",
								visualName: "b61f13643abddcd9a65a",
							},
						],
					},
					{
						name: "Alışveriş Merkezi Stoğu",
						dashboardId: 924,
						slicers: [
							{
								filterType: "basic",
								visualName: "e4d0a0cd938212776174",
							},
							{
								filterType: "basic",
								visualName: "c768acf419fad1522b92",
							},
						],
					},
					{
						name: "REIDIN Sosyo-Ekonomik Derecelendirme",
						dashboardId: 945,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "bb35d7f1ceb9b866b509",
							},
							{
								filterType: "basic",
								visualName: "31369afc85c340cb5554",
							}
						] : [
							{
								filterType: "basic",
								visualName: "1fabca387a5abeeeac02",
							},
							{
								filterType: "basic",
								visualName: "31369afc85c340cb5554",
							},
							{
								filterType: "basic",
								visualName: "bb35d7f1ceb9b866b509",
							},
						],
					},
					{
						name: "Cinsiyete Göre Nüfus",
						dashboardId: 948,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "83833aee7159b03073b1",
							},
							{
								filterType: "basic",
								visualName: "85f89d45120a48cd3529",
							}
						] : [
							{
								filterType: "basic",
								visualName: "d672899cda2ce55c8b20",
							},
							{
								filterType: "basic",
								visualName: "85f89d45120a48cd3529",
							},
							{
								filterType: "basic",
								visualName: "83833aee7159b03073b1",
							},
						],
					},
					
				],
				building:[
					{
						name: "Proje Raporu",
						dashboardId: 880,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "8377ee3bed0c0060770e",
							},
							{
								filterType: "basic",
								visualName: "42daf6ac6bc0ac358d62",
							},
							{
								filterType: "basic",
								visualName: "ae5bb801e47320d472d0",
							}
						],
					},
					{
						name: "Bağımsız Bölüm Satışları",
						dashboardId: 972,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "dddacd5404cf4d50fa7a",
							},
							{
								filterType: "basic",
								visualName: "a98ed478e5a7412d61a9",
							},
							{
								filterType: "basic",
								visualName: "2ecea579f4b81c9bfa87",
							}
						],
					},
					{
						name: "Proje Karşılaştırma",
						dashboardId: 966,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "7e5b0464830799d19d6d",
							},
							{
								filterType: "basic",
								visualName: "81c580d0bd820ecd80a0",
							},
							{
								filterType: "basic",
								visualName: "c6c095a7108c80e5869c",
							}
						],
					},
					{
						name: "Konut İlan Analizi",
						dashboardId: 882,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "74df7ca3cc29d9e40200",
							},
							{
								filterType: "basic",
								visualName: "fbeb8901b7b866a46248",
							},
							{
								filterType: "basic",
								visualName: "3a621c89e4b9399f35b0",
							}
						],
					},
					{
						name: "Ofis İlan Analizi",
						dashboardId: 923,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "49102238750a2a9cea7a",
							},
							{
								filterType: "basic",
								visualName: "b61f13643abddcd9a65a",
							}						
						],
					},
					{
						name: "Proje Satış Fiyatları",
						dashboardId: 975,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "1bf628e91b0f81a08335",
							},
							{
								filterType: "basic",
								visualName: "183948f0f08feca1a404",
							},
							{
								filterType: "basic",
								visualName: "04b716274799ec027ef4",
							}
													
						],
					},
					{
						name: "Proje Kira Fiyatları",
						dashboardId: 976,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "9c78dd2e54583fd90455",
							},
							{
								filterType: "basic",
								visualName: "bb9e14b77d2ee7243672",
							},
							{
								filterType: "basic",
								visualName: "ab6ef881b280e274f454",
							}
													
						],
					},
					{
						name: "Proje Kira Getirileri",
						dashboardId: 978,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "ab9c7c183f31e86513cf",
							},
							{
								filterType: "basic",
								visualName: "6e1bae5ddb721240e0f7",
							},
							{
								filterType: "basic",
								visualName: "bbea452cba908f8dca3e",
							}
													
						],
					},
					{
						name: "Detaylı İlan Verileri",
						dashboardId: 943,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "66ef1e27dde720b61290",
							},
							{
								filterType: "basic",
								visualName: "f7a69182dde2de1d0b18",
							}
						],
					}
				],
				developer:[
					{
						name: "Geliştirici Karşılaştırma",
						dashboardId: 963,
						slicers: [
							{
								filterType: "basic",
								visualName: "8bfc02c38b0ccec30e05",
							}
						],
					}
				]
			}
		}
		
	}

	getBetaSubCategories(country:string,locationLevel:string): SubCategory {
		if(country == "AE"){
			return {
			city:[],
			location: [
				{
					name: "Area Report",
					dashboardId: 263,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "ef6733cb8093963910eb",
						},
						{
							filterType: "basic",
							visualName: "e071baf48b0dd6a3da28",
						},
					],
				},
				{
					name: "Price Report",
					dashboardId: 274,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "141d7e507b2fe92f55d3",
						},
						{
							filterType: "basic",
							visualName: "01c7e6210d4fa2ba4600",
						},
					],
				},
				{
					name: "Sales Transactions",
					dashboardId: 514,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "2cd42840cf693689afe6",
						},
					],
				},
				{
					name: "Rent Transactions",
					dashboardId: 515,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "729af213157561186a1e",
						},
					],
				},
				{
					name: "Listing Monitor",
					dashboardId: 330,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "c59575a331d2d1914866",
						},
						{
							filterType: "basic",
							visualName: "ad8230a3d9da6cdb2942",
						},
					],
				},
				{
					name: "Listing Data",
					dashboardId: 331,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "59e43787659d2e2cee90",
						},
						{
							filterType: "basic",
							visualName: "8b662461a7bf2d60d6a6",
						},
					],
				},
				{
					name: "Sales Prices",
					dashboardId: 308,
					slicers: [
						{
							filterType: "basic",
							visualName: "0b01842610825e750d78",
						},
					],
				},
				{
					name: "Rent Prices",
					dashboardId: 302,
					slicers: [
						{
							filterType: "basic",
							visualName: "cee38052835e84bc1890",
						},
					],
				},
				{
					name: "Rental Yields",
					dashboardId: 303,
					slicers: [
						{
							filterType: "basic",
							visualName: "bd8bbf87b43db5ae6bc8",
						},
					],
				},
				{
					name: "Residential Supply",
					dashboardId: 277,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "6bad5a605378b0046378",
						},
						{
							filterType: "basic",
							visualName: "266ddb0154d9a4eb70eb",
						},
					],
				},
				{
					name: "Occupancy Rates",
					dashboardId: 275,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "22bf8f23400086390231",
						},
						{
							filterType: "basic",
							visualName: "e21e96e210e784583e2b",
						},
					],
				},
				{
					name: "Service Charges",
					dashboardId: 273,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "48b1df0e456006991398",
						},
						{
							filterType: "basic",
							visualName: "b60018c953e8970639e3",
						},
					],
				},
			],
			building: [
				{
					name: "Residential Property Report",
					dashboardId: 344,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "8377ee3bed0c0060770e",
						},
						{
							filterType: "basic",
							visualName: "42daf6ac6bc0ac358d62",
						},
						{
							filterType: "basic",
							visualName: "ae5bb801e47320d472d0",
						},
					],
				},
				{
					name: "Sales Transaction",
					dashboardId: 514,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "2cd42840cf693689afe6",
						},
						{
							filterType: "basic",
							visualName: "0a209db726795b69c934",
						},
					],
				},
				{
					name: "Rent Transaction",
					dashboardId: 515,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "729af213157561186a1e",
						},
						{
							filterType: "basic",
							visualName: "121b895511ce90ec1128",
						},
					],
				},
				{
					name: "Listing Monitor",
					dashboardId: 330,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "c59575a331d2d1914866",
						},
						{
							filterType: "basic",
							visualName: "85dcfc364a7512a20177",
						},
						{
							filterType: "basic",
							visualName: "ad8230a3d9da6cdb2942",
						},
					],
				},
				{
					name: "Sales Prices",
					dashboardId: 0,
					cityAndCommunity: true,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "4bf1926451ad08ed7b04",
						},
						{
							filterType: "basic",
							visualName: "edb6f6401a043074a9ec",
						},
					],
				},
				{
					name: "Rent Prices",
					dashboardId: 0,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "4bf1926451ad08ed7b04",
						},
						{
							filterType: "basic",
							visualName: "edb6f6401a043074a9ec",
						},
					],
				},
				{
					name: "Rental Yields",
					dashboardId: 0,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "4bf1926451ad08ed7b04",
						},
						{
							filterType: "basic",
							visualName: "edb6f6401a043074a9ec",
						},
					],
				},
				{
					name: "Office Property Report",
					dashboardId: 348,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "d1711807e4d621727ae6",
						},
						{
							filterType: "basic",
							visualName: "96b77674a032d5509f72",
						},
						{
							filterType: "basic",
							visualName: "e6b7e3d0f3568ae6f44b",
						},
					],
				},
				{
					name: "Occupancy Rates",
					dashboardId: 0,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "f9077611b8943f3577fd",
						},
					],
				},
				{
					name: "Service Charges",
					dashboardId: 0,
					slicers: [
						{
							filterType: "hierarchy",
							visualName: "3fcd416029903750ba45",
						},
					],
				},
			],
			developer: [
				{
					name: "Developer Report",
					dashboardId: 265,
					slicers: [
						{
							filterType: "basic",
							visualName: "0b2a3714061bc3897b4e",
						},
					],
				},
				{
					name: "Sales Transactions",
					dashboardId: 514,
					slicers: [
						{
							filterType: "basic",
							visualName: "78f805dc121002bbdf7a",
						},
					],
				},
				{
					name: "Residential Supply",
					dashboardId: 277,
					slicers: [
						{
							filterType: "basic",
							visualName: "f1ea9b90290a12b7bcc5",
						},
					],
				},
				{
					name: "Construction Status Report",
					dashboardId: 278,
					slicers: [
						{
							filterType: "basic",
							visualName: "38c65ca7dc00e00a3010",
						},
					],
				},
				{
					name: "Payment Plans",
					dashboardId: 279,
					slicers: [
						{
							filterType: "basic",
							visualName: "8ec6b6730b00e32a582c",
						},
					],
				},
			],
		};
		}else{
			return {
				city: [
					{
						name: "Il Raporu",
						dashboardId: 548,
						slicers: [
							{
								filterType: "basic",
								visualName: "8096f158973969ed1a68",
							},
						],
					},
					{
						name: "Konut Satış Fiyatları",
						dashboardId: 562,
						slicers: [
							{
								filterType: "basic",
								visualName: "84fceade4e1027638c93",
							}
						],
					},
					{
						name: "Konut Kira Fiyatları",
						dashboardId: 564,
						slicers: [
							{
								filterType: "basic",
								visualName: "6d11f66736431ab7b839",
							}
						],
					},
					{
						name: "Konut Satış Endeksleri",
						dashboardId: 563,
						slicers: [
							{
								filterType: "basic",
								visualName: "9b36e22b6eb28954557d",
							}
						],
					},
					{
						name: "Konut Kira Endeksleri",
						dashboardId: 565,
						slicers: [
							{
								filterType: "basic",
								visualName: "9d854d80a4b0a20b994d",
							}
						],
					},
					{
						name: "Konut Sayıları",
						dashboardId: 550,
						slicers: [
							{
								filterType: "basic",
								visualName: "898890d300d2a0b7c822",
							}
						],
					},
					{
						name: "Konut Satışları",
						dashboardId: 551,
						slicers: [
							{
								filterType: "basic",
								visualName: "dbf4cf816a2915234958",
							}
						],
					},
					{
						name: "Yabancılara Satışlar",
						dashboardId: 553,
						slicers: [
							{
								filterType: "basic",
								visualName: "c29df53a3822077237a3",
							}
						],
					},
					{
						name: "Ofis Trendleri",
						dashboardId: 555,
						slicers: [
							{
								filterType: "basic",
								visualName: "bfd3c70e90f4911a9247",
							}
						],
					},
					{
						name: "Alışveriş Merkezi Stoğu",
						dashboardId: 557,
						slicers: [
							{
								filterType: "basic",
								visualName: "c768acf419fad1522b92",
							}
						],
					},
					{
						name: "Cinsiyete Göre Nüfus",
						dashboardId: 577,
						slicers: [
							{
								filterType: "basic",
								visualName: "85f89d45120a48cd3529",
							}
						],
					},
				],
				location: [
					{
						name: "Bölge Raporu",
						dashboardId: 547,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "d65b1a6c7a012a32cc7c",
							},
							{
								filterType: "basic",
								visualName: "9cf5643347b3ee28ae57",
							},
						],
					},
					{
						name: "Konut Satış Fiyatları",
						dashboardId: 562,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "095ae837470785ae1629",
							},
							{
								filterType: "basic",
								visualName: "7b15b0952569b9063450",
							}						
							] : [
							{
								filterType: "basic",
								visualName: "7529ca25880c589b7592",
							},
							{
								filterType: "basic",
								visualName: "453b8fbd29c2842a9289",
							},
							{
								filterType: "basic",
								visualName: "7282f27f80935001bcd0",
							}
						],
					},
					{
						name: "Konut Kira Fiyatları",
						dashboardId: 564,
						slicers: locationLevel != "District" ? [
								{
									filterType: "basic",
									visualName: "194151946c0e0034ee05",
								},
								{
									filterType: "basic",
									visualName: "f8d62cf92b358b047809",
								},					
							] : [
								{
									filterType: "basic",
									visualName: "03dc9cdb523a47e7d421",
								},
								{
									filterType: "basic",
									visualName: "5921868929a7175c5670",
								},
								{
									filterType: "basic",
									visualName: "20807136505671b6176e",
								}								
							],
					},
					{//Beta için Rapor Yok
						name: "Konut Yatırımı Brüt Getiri Oranları",
						dashboardId: 99,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "e21933c6c67209e76686",
							},
							{
								filterType: "basic",
								visualName: "f17e0f102340e00bdd38",
							}						
							] : [
							{
								filterType: "basic",
								visualName: "97a23624b600ad3695de",
							},
							{
								filterType: "basic",
								visualName: "13d66b8fc17abaac28b8",
							},
							{
								filterType: "basic",
								visualName: "a97785069d9d97729117",
							},
						]
					},
					{
						name: "Konut Satış Endeksleri",
						dashboardId: 563,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "395625635adee084a352",
							},
							{
								filterType: "basic",
								visualName: "9b36e22b6eb28954557d",
							}						
							] : [
							{
								filterType: "basic",
								visualName: "119319e2d2aa0d375146",
							},
							{
								filterType: "basic",
								visualName: "9b36e22b6eb28954557d",
							},
							{
								filterType: "basic",
								visualName: "395625635adee084a352",
							},
						],
					},
					{
						name: "Konut Kira Endeksleri",
						dashboardId: 565,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "50bf125ebeaa9e6d0a14",
							},
							{
								filterType: "basic",
								visualName: "9d854d80a4b0a20b994d",
							}						
							] : [
							{
								filterType: "basic",
								visualName: "7e4bec5a6d0b65487007",
							},
							{
								filterType: "basic",
								visualName: "9d854d80a4b0a20b994d",
							},
							{
								filterType: "basic",
								visualName: "50bf125ebeaa9e6d0a14",
							},
						],
					},
					{
						name: "Konut İlan Analizi",
						dashboardId: 549,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "74df7ca3cc29d9e40200",
							},
							{
								filterType: "basic",
								visualName: "3a621c89e4b9399f35b0",
							},
						],
					},
					{
						name: "Konut Satışları",
						dashboardId: 551,
						slicers: [
							{
								filterType: "basic",
								visualName: "222f05b6a51cd4c9d0bc",
							},
							{
								filterType: "basic",
								visualName: "dbf4cf816a2915234958",
							},
						]
					},
					{
						name: "Konut Sayıları",
						dashboardId: 550,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "7be2370710de2b908809",
							},
							{
								filterType: "basic",
								visualName: "898890d300d2a0b7c822",
							}
						]: [
							{
								filterType: "basic",
								visualName: "5331d672428abc3de476",
							},
							{
								filterType: "basic",
								visualName: "898890d300d2a0b7c822",
							},
							{
								filterType: "basic",
								visualName: "7be2370710de2b908809",
							},
						]
					},
					{
						name: "Detaylı İlan Verileri",
						dashboardId: 573,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "66ef1e27dde720b61290",
							},
							{
								filterType: "basic",
								visualName: "f7a69182dde2de1d0b18",
							},
						],
					},
					{//Beta için rapor yok
						name: "Bağımsız Bölüm Satışları",
						dashboardId: 101,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "dddacd5404cf4d50fa7a",
							},
							{
								filterType: "basic",
								visualName: "2ecea579f4b81c9bfa87",
							},
						],
					},
					{//Beta için rapor yok
						name: "Ana Taşınmaz Satışları",
						dashboardId: 102,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "dddacd5404cf4d50fa7a",
							},
							{
								filterType: "basic",
								visualName: "2ecea579f4b81c9bfa87",
							},
						],
					},
					{
						name: "Ofis İlan Analizi",
						dashboardId: 556,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "49102238750a2a9cea7a",
							},
							{
								filterType: "basic",
								visualName: "b61f13643abddcd9a65a",
							},
						],
					},
					{
						name: "Alışveriş Merkezi Stoğu",
						dashboardId: 557,
						slicers: [
							{
								filterType: "basic",
								visualName: "e4d0a0cd938212776174",
							},
							{
								filterType: "basic",
								visualName: "c768acf419fad1522b92",
							},
						],
					},
					{
						name: "REIDIN Sosyo-Ekonomik Derecelendirme",
						dashboardId: 574,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "bb35d7f1ceb9b866b509",
							},
							{
								filterType: "basic",
								visualName: "31369afc85c340cb5554",
							}
						] : [
							{
								filterType: "basic",
								visualName: "1fabca387a5abeeeac02",
							},
							{
								filterType: "basic",
								visualName: "31369afc85c340cb5554",
							},
							{
								filterType: "basic",
								visualName: "bb35d7f1ceb9b866b509",
							},
						],
					},
					{
						name: "Cinsiyete Göre Nüfus",
						dashboardId: 577,
						slicers: locationLevel != "District" ? [
							{
								filterType: "basic",
								visualName: "83833aee7159b03073b1",
							},
							{
								filterType: "basic",
								visualName: "85f89d45120a48cd3529",
							}
						] : [
							{
								filterType: "basic",
								visualName: "d672899cda2ce55c8b20",
							},
							{
								filterType: "basic",
								visualName: "85f89d45120a48cd3529",
							},
							{
								filterType: "basic",
								visualName: "83833aee7159b03073b1",
							},
						],
					},
					
				],
				building:[
					{
						name: "Proje Raporu",
						dashboardId: 546,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "8377ee3bed0c0060770e",
							},
							{
								filterType: "basic",
								visualName: "42daf6ac6bc0ac358d62",
							},
							{
								filterType: "basic",
								visualName: "ae5bb801e47320d472d0",
							}
						],
					},
					{//Beta için rapor yok
						name: "Bağımsız Bölüm Satışları",
						dashboardId: 103,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "dddacd5404cf4d50fa7a",
							},
							{
								filterType: "basic",
								visualName: "a98ed478e5a7412d61a9",
							},
							{
								filterType: "basic",
								visualName: "2ecea579f4b81c9bfa87",
							}
						],
					},
					{//Beta için rapor yok
						name: "Proje Karşılaştırma",
						dashboardId: 104,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "7e5b0464830799d19d6d",
							},
							{
								filterType: "basic",
								visualName: "81c580d0bd820ecd80a0",
							},
							{
								filterType: "basic",
								visualName: "c6c095a7108c80e5869c",
							}
						],
					},
					{
						name: "Konut İlan Analizi",
						dashboardId: 549,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "74df7ca3cc29d9e40200",
							},
							{
								filterType: "basic",
								visualName: "fbeb8901b7b866a46248",
							},
							{
								filterType: "basic",
								visualName: "3a621c89e4b9399f35b0",
							}
						],
					},
					{
						name: "Ofis İlan Analizi",
						dashboardId: 556,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "49102238750a2a9cea7a",
							},
							{
								filterType: "basic",
								visualName: "b61f13643abddcd9a65a",
							}						
						],
					},
					{//Beta için rapor yok
						name: "Proje Satış Fiyatları",
						dashboardId: 105,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "1bf628e91b0f81a08335",
							},
							{
								filterType: "basic",
								visualName: "183948f0f08feca1a404",
							},
							{
								filterType: "basic",
								visualName: "04b716274799ec027ef4",
							}
													
						],
					},
					{//Beta için rapor yok
						name: "Proje Kira Fiyatları",
						dashboardId: 106,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "9c78dd2e54583fd90455",
							},
							{
								filterType: "basic",
								visualName: "bb9e14b77d2ee7243672",
							},
							{
								filterType: "basic",
								visualName: "ab6ef881b280e274f454",
							}
													
						],
					},
					{//Beta için rapor yok
						name: "Proje Kira Getirileri",
						dashboardId: 107,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "ab9c7c183f31e86513cf",
							},
							{
								filterType: "basic",
								visualName: "6e1bae5ddb721240e0f7",
							},
							{
								filterType: "basic",
								visualName: "bbea452cba908f8dca3e",
							}
													
						],
					},
					{
						name: "Detaylı İlan Verileri",
						dashboardId: 573,
						slicers: [
							{
								filterType: "hierarchy",
								visualName: "66ef1e27dde720b61290",
							},
							{
								filterType: "basic",
								visualName: "f7a69182dde2de1d0b18",
							}
						],
					}
				],
				developer:[
					{//Beta için rapor yok
						name: "Geliştirici Karşılaştırma",
						dashboardId: 108,
						slicers: [
							{
								filterType: "basic",
								visualName: "8bfc02c38b0ccec30e05",
							}
						],
					}
				]
			}
		}
		
	}
}
