
import { Validators } from '@angular/forms';

export const OptionalTextValidation = [Validators.minLength(2), Validators.maxLength(50)];
export const RequiredTextValidation = OptionalTextValidation.concat([Validators.required]);
export const OneCharValidation = [Validators.minLength(1), Validators.maxLength(1)];
export const EmailValidation = [Validators.required, Validators.email];
export const PasswordValidation = [
  Validators.required,
  Validators.minLength(4),
  Validators.maxLength(50)
];
export const PhoneNumberValidation = [Validators.required, Validators.pattern("([()\\-x/+]*\\d[()\\-x+]*){8,16}")];
// export const USAZipCodeValidation = [
//   Validators.required,
//   Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/),
// ]
// export const USAPhoneNumberValidation = [
//   Validators.required,
//   Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/),
// ]
