<div class="table-container">
    <table mat-table [dataSource]="data" class="mat-elevation-z8">

        <ng-container *ngFor="let column of columns">
            <ng-container [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let row">
                    {{column.cell(row) ?? "All"}}
                </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef> Frequency </th>
            <td mat-cell *matCellDef="let element"> {{ frequencyMapping[element.frequency] }} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let notification">
                <div class="button-group" role="group">
                    <button mat-raised-button [color]="editButtonColor(notification)"
                        (click)="isEditingNotificationSameAsGiven(notification) ? exitEdit() : edit(notification)">Edit</button>
                    <button mat-raised-button color="accent" (click)="delete(notification)">Delete</button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [pageSize]="20"
    aria-label="Select page of periodic elements" style="width: 100% !important;">
</mat-paginator>