import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from '@insight-services/authentication.service';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html'
})
export class NgxLogoutComponent implements OnInit {
  logoutText: string = "main.views.logout.logout-text";

  constructor(
    public authService: AuthenticationService,
    protected router: Router,
    protected sanitizer: DomSanitizer
  ) {
  }
  ngOnInit(): void {
    this.authService.logout();
  }
}
