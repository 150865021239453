import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Injectable, inject, signal } from "@angular/core";
import { Observable, Subject, combineLatest, of } from "rxjs";
import { environment } from "@insight-environments/environment";
import {
	catchError,
	filter,
	map,
	switchMap,
	tap,
} from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { retry } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { NbToastrService } from "@nebular/theme";
import { StorageService } from "./storage.service";
import { MenuItem } from "@insight-models/menuItem";
import { ProductCodes } from "@insight-models/product-codes";
import { Dashboard } from "@insight-models/dashboard";
import { searchMenuItem } from "@insight-models/search-menu-item";

@Injectable({
	providedIn: "root",
})
export class MenuService {
	private readonly http = inject(HttpClient);
	private readonly authService = inject(AuthenticationService);
	private readonly translateService = inject(TranslateService);
	private readonly router = inject(Router);
	private readonly toastrService = inject(NbToastrService);
	private readonly storageService = inject(StorageService);

	defaultCountry = "AE";
	firstLoad: boolean = true;
	error: boolean = false;

	readonly isSideMenuCollapsed = signal(false); //For header
	readonly isSidemenuCollapsed = signal(false); //For sidemenu

	readonly #selectedTopMenuItem = signal<MenuItem | null>(null);
	readonly selectedTopMenuItem = this.#selectedTopMenuItem.asReadonly();

	readonly #topMenuItems = signal<MenuItem[] | undefined>(undefined);
	readonly topMenuItems = this.#topMenuItems.asReadonly();

	readonly #currentDashboard$ = new Subject<Dashboard>();
	readonly currentDashboard$ = this.#currentDashboard$.asObservable();

	readonly #navigateToSearch$ = new Subject<never>();
	readonly navigateToSearch$ = this.#navigateToSearch$.asObservable();

	constructor() {
		this.setLanguage().subscribe();
	}

	setTopMenuItems(menuItems: MenuItem[]) {
		this.#topMenuItems.set(menuItems);
	}

	getTopMenuItems() {
		return this.authService.getCurrentUser().pipe(
			filter((user) => user.email !== ""),
			switchMap((user) => {
				user.products.forEach((product) => {
					if (
						product.code.includes("insight") || product.code.includes("INSFULL")
					){
						return combineLatest([
							this.fetchMenuItems(product.country),
							of(product),
						]);
					}
						
				});
				return combineLatest([
					this.fetchMenuItems(this.defaultCountry),
					of(this.defaultCountry),
				]);
			}),
			filter(([topMenuItems, _]) => topMenuItems.length > 0),
			tap(([menuItems, product]) => {
				if (menuItems.length > 0 && product === "AE") {
					for (let menuItem of menuItems) {
						menuItem.child.unshift(searchMenuItem);
					}
				}
			}),
			map(([topMenuItems, _]) => topMenuItems)
		);
	}

	async navigateToSearch() {
		await this.router.navigate(["/home/dashboard"]);
		this.#navigateToSearch$.next();
	}

	private setLanguage() {
		return this.authService.getCurrentUser().pipe(
			filter((user) => user.email !== ""),
			tap((user) => {
				user.products.forEach((product) => {
					if (
						product.code.includes("insight") || product.code.includes("INSFULL")
					){
						this.defaultCountry = product.country;
					}
				});
			}),
			tap((_) => {
				if (this.firstLoad) {
					this.firstLoad = false;
					this.translateService.use(
						this.defaultCountry === "AE" ? "eng" : "tur"
					);
				}
			})
		);
	}

	findDashboard(menuItem: MenuItem, id: number): MenuItem | null {
		if (menuItem.id === id) return menuItem;

		if (menuItem.child) {
			for (const child of menuItem.child) {
				const item = this.findDashboard(child, id);
				if (item) return item;
			}
		}

		return null;
	}

	setSelectedTopMenuItem(menuItem: MenuItem | null): void {
		const selectedTopMenuItem = this.#selectedTopMenuItem();
		if (menuItem?.id !== selectedTopMenuItem?.id) {
			this.#selectedTopMenuItem.set(menuItem);
		}
	}

	setCurrentDashboard(dashboard: Dashboard) {
		this.#currentDashboard$.next(dashboard);
	}

	private fetchMenuItems(country: string): Observable<MenuItem[]> {
		return this.http
			.get<MenuItem[]>(environment.backendAPIUrl + "/api/menu/", {
				params: {
					country: country,
				},
			})
			.pipe(
				retry(5),
				catchError((err) => {
					if (
						err.error.detail == "No such token/Wrong" &&
						!this.error
					) {
						this.error = true;
						this.storageService.clearLocalStorage();
						this.toastrService.danger(
							this.translateService.instant(
								"main.general.messages.error.session-expired"
							),
							this.translateService.instant(
								"main.general.messages.title.error"
							)
						);
						setTimeout(() => {
							this.router.navigate(["auth/login"]);
						}, 3000);
					}
					console.log(
						"Error occured while getting menuItems from service..."
					);
					console.log(err);
					throw new Error(err);
				})
			);
	}

	openSidebar() {
		this.isSideMenuCollapsed.set(false);
		this.isSidemenuCollapsed.set(false);
	}

	closeSidebar() {
		this.isSideMenuCollapsed.set(true);
		this.isSidemenuCollapsed.set(true);
	}
}
