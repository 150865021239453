<div class="modal-dialog" role="document">
  <div class="modal-content" style="background: #fff !important">
    <div class="modal-close">
      <button type="button" class="modal-close-button" data-dismiss="modal">
        <img src="../../../assets/close-icon-insight.svg" alt="" />
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-header">
        <div class="col">
          <div class="row">
            <img
              class="modal-header-logo"
              src="../../../assets/images/r-insight-logo.svg"
              alt=""
            />
          </div>
          <div class="row justify-content-center">
            <h1 style="text-align: left !important" class="modal-header-title">
              {{ "main.views.request-demo.request-demo" | translate }}
            </h1>
          </div>
        </div>
      </div>

      <hr class="line" />

      <form [formGroup]="emailForm">
        <div class="form-control-group">
          <label class="form-header">
            {{ "main.views.request-demo.first-name" | translate }}:
          </label>
          <input
            class="form-input"
            name="firstName"
            formControlName="firstName"
            placeholder="{{ 'main.views.request-demo.first-name' | translate }}"
          />
        </div>
        <div
          *ngIf="
            emailForm.controls.firstName.invalid &&
            (emailForm.controls.firstName.dirty ||
              emailForm.controls.firstName.touched)
          "
          class="alert alert-danger"
        >
          {{ "main.general.messages.warning.first-name-required" | translate }}
        </div>

        <hr class="line" />

        <div class="form-control-group">
          <label class="form-header">
            {{ "main.views.request-demo.second-name" | translate }}:
          </label>
          <input
            style="height: 1.6em; align-items: center"
            class="form-input"
            name="secondName"
            formControlName="secondName"
            placeholder="{{
              'main.views.request-demo.second-name' | translate
            }}"
          />
        </div>
        <div
          *ngIf="
            emailForm.controls.secondName.invalid &&
            (emailForm.controls.secondName.dirty ||
              emailForm.controls.secondName.touched)
          "
          class="alert alert-danger"
        >
          {{ "main.general.messages.warning.second-name-required" | translate }}
        </div>

        <hr class="line" />

        <div class="form-control-group">
          <label class="form-header">
            {{ "main.views.request-demo.email-address" | translate }}:
          </label>
          <input
            style="height: 1.6em; align-items: center"
            class="form-input"
            name="email"
            formControlName="email"
            pattern=".+@.+\..+"
            placeholder="{{
              'main.views.request-demo.email-address' | translate
            }}"
          />
        </div>
        <div
          *ngIf="
            emailForm.controls.email.invalid &&
            (emailForm.controls.email.dirty || emailForm.controls.email.touched)
          "
          class="alert alert-danger"
        >
          {{
            emailForm.controls.email.value.length === 0
              ? ("main.general.messages.warning.email-required" | translate)
              : ("main.general.messages.warning.username-invalid" | translate)
          }}
        </div>

        <hr class="line" />

        <div class="form-control-group">
          <label class="form-header">
            {{ "main.views.request-demo.phone-number" | translate }}:
          </label>
          <input
            style="height: 1.6em; align-items: center"
            class="form-input"
            name="phoneNumber"
            formControlName="phoneNumber"
            placeholder="{{
              'main.views.request-demo.phone-number' | translate
            }}"
          />
        </div>
        <div
          *ngIf="
            emailForm.controls.phoneNumber.invalid &&
            (emailForm.controls.phoneNumber.dirty ||
              emailForm.controls.phoneNumber.touched)
          "
          class="alert alert-danger"
        >
          {{
            emailForm.controls.phoneNumber.value.length === 0
              ? ("main.general.messages.warning.phone-number" | translate)
              : ("main.general.messages.warning.phone-number-invalid"
                | translate)
          }}
        </div>

        <hr class="line" />

        <div class="form-control-group">
          <label class="form-header">
            {{ "main.views.request-demo.company" | translate }}:
          </label>
          <input
            style="height: 1.6em; align-items: center"
            class="form-input"
            name="company"
            formControlName="company"
            placeholder="{{ 'main.views.request-demo.company' | translate }}"
          />
        </div>
        <div
          *ngIf="
            emailForm.controls.company.invalid &&
            (emailForm.controls.company.dirty ||
              emailForm.controls.company.touched)
          "
          class="alert alert-danger"
        >
          {{ "main.general.messages.warning.company-required" | translate }}
        </div>

        <hr class="line" />

        <div class="form-control-group">
          <label class="form-header">
            {{ "main.views.request-demo.industry" | translate }}:
          </label>
          <select
            style="font-size: 14px; padding-left: 5px"
            name="industry"
            id="industry"
            formControlName="industry"
          >
            <option value="ASP (Application Service Provider)">
              ASP (Application Service Provider)
            </option>
            <option value="Data/Telecom OEM">Data/Telecom OEM</option>
            <option value="ERP (Enterprise Resource Planning)">
              ERP (Enterprise Resource Planning)
            </option>
            <option value="Government/Military">Government/Military</option>
            <option value="Large Enterprise">Large Enterprise</option>
            <option value="ManagementISV">ManagementISV</option>
            <option value="MSP (Management Service Provider)">
              MSP (Management Service Provider)
            </option>
            <option value="Network Equipment Enterprise">
              Network Equipment Enterprise
            </option>
            <option value="management ISV">Non-management ISV</option>
            <option value="Optical Networking">Optical Networking</option>
            <option value="Service Provider">Service Provider</option>
            <option value="Medium Enterprise">Small/Medium Enterprise</option>
            <option value="Storage Equipment">Storage Equipment</option>
            <option value="Storage Service Provider">
              Storage Service Provider
            </option>
            <option value="Systems Integrator">Systems Integrator</option>
            <option value="Wireless Industry">Wireless Industry</option>
            <option value="ERP">ERP</option>
            <option value="Management ISV">Management ISV</option>
          </select>
        </div>

        <div style="margin-top: 20px" class="mf-input-wrapper">
          <div class="mf-checkbox-option">
            <label
              style="
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                color: black;
              "
              class="form-check-label checkModal"
            >
              <input
                type="checkbox"
                class="mf-input mf-checkbox-input"
                [(ngModel)]="termsAndPrivacyCheckbox"
                [ngModelOptions]="{ standalone: true }"
                id="flexCheckDefault"
                name="mf-checkbox"
                data-checked=""
                aria-invalid="false"
                checked="checked"
              />
              <span>{{
                this.languageChose
                  ? "By entering your email, you agree to our "
                  : "Demo talep ederek REIDIN'in "
              }}</span>
              <span>
                <a
                  href="https://reidin.com/terms-conditions/"
                  target="_blank"
                  class="form-check-link"
                >
                  {{
                    this.languageChose
                      ? "Terms and Conditions"
                      : "Kullanım Koşulları"
                  }}
                </a>
              </span>
              {{ this.languageChose ? " and " : " ve " }}
              <span>
                <a
                  href="https://reidin.com/privacy-policy/"
                  target="_blank"
                  class="form-check-link"
                >
                  {{
                    this.languageChose
                      ? "Privacy Policy"
                      : "Gizlilik Politikası"
                  }}
                </a>
              </span>
              {{ this.languageChose ? "." : "'nı kabul etmiş sayılırsınız." }}
            </label>
          </div>
        </div>

        <div style="margin-bottom: 20px" class="mf-input-wrapper">
          <div class="mf-checkbox-option">
            <label
              style="
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                color: black;
              "
              class="form-check-label checkModal"
            >
              <input
                type="checkbox"
                class="mf-input mf-checkbox-input"
                [(ngModel)]="messageCheckbox"
                [ngModelOptions]="{ standalone: true }"
                name="mf-checkbox"
                data-checked=""
                aria-invalid="false"
                checked="checked"
              />
              <span
                >{{
                  this.languageChose
                    ? "I agree receive newsletters and commercial messages from REIDIN."
                    : "REIDIN'nin ticari mesaj göndermesini kabul ediyorum."
                }}
              </span>
            </label>
          </div>
        </div>
        <button
          [disabled]="
            !emailForm.valid || !termsAndPrivacyCheckbox || !messageCheckbox
          "
          id="modal-submit-button"
          class="form-button"
          type="submit"
          status="success"
          data-toggle="modal"
          data-target="#request-success"
          (click)="sendMail()"
        >
          {{
            !this.emailForm.valid ||
            !this.termsAndPrivacyCheckbox ||
            !this.messageCheckbox
              ? ("main.general.messages.warning.form-missing" | translate)
              : ("main.general.messages.success.get-demo" | translate)
          }}
        </button>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="request-success"
  tabindex="-1"
  role="dialog"
  aria-labelledby="request-success"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-close">
        <button
          type="button"
          class="modal-close-button"
          aria-label="Close"
          data-dismiss="modal"
        >
          <img src="../../../assets/close-icon-insight.svg" alt="" />
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-header">
          <div class="col">
            <div class="row">
              <img
                class="modal-header-logo"
                src="../../../assets/images/r-insight-logo.svg"
                alt=""
              />
            </div>
            <div class="row justify-content-center">
              <label class="modal-header-title">
                {{ this.languageChose ? "Request Demo" : "Demo Talebi" }}
              </label>
            </div>
          </div>
        </div>
        <hr class="line" />
        <div class="row justify-content-center my-3">
          <label class="modal-text">
            {{
              this.languageChose
                ? "We have received your demo request."
                : "Demo talebinizi aldık."
            }}
            <br />
            {{
              this.languageChose
                ? "Our customer representative will contact you as soon as possible."
                : "Müşteri temsilcimiz en kısa sürede sizinle iletişime geçecektir."
            }}
          </label>
        </div>
        <hr class="line" />
        <div class="row justify-content-center my-3">
          <label class="modal-text">
            {{ this.languageChose ? "Fast Communication" : "Hızlı İletişim" }}
          </label>
        </div>
        <a href="mailto:demo@reidin.com">
          <button type="button" class="form-button">
            Email: demo&#64;reidin.com
          </button>
        </a>
        <div class="row justify-content-center">
          <label class="modal-text my-3">or</label>
        </div>
        <a href="{{ this.languageChose ? 'https://reidin.link/demo-insight-tel-uae' : 'https://reidin.link/demo-insight-tel-tr' }}">
          <button type="button" class="form-button">
            {{ this.languageChose ? "Call : +971 4 277 68 35" : "Ara : +90 850 217 34 22" }}
          </button>
        </a>
      </div>
    </div>
  </div>
</div>
