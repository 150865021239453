<div *ngIf="currentUser$ | async as user"
    class="sidebar sidebar-bg sidebar-main sidebar-expand-md sidebar-content sidebar-user" [ngClass]="{'d-none': !visible}">
    <div class="card">
        <img class="card-img-top" [src]="logoUrl" *ngIf="logoUrl$ | async as logoUrl" style="width: 80%;">

        <div class="card-body">
            <div class="media" *ngIf="!logoUrl$">
                <!-- <i class="fa fa-user" style="font-size: 1.8rem; margin-left:-14px;padding-right:15px"></i> -->
                <nb-icon icon="person-outline"></nb-icon>
                <div class="media-body" *ngIf="!visible">
                    <div class="media-title font-weight-semibold">
                        {{ user.name }}</div>
                    <div class="font-size-xs opacity-50">
                        <i class="icon-pin font-size-sm"></i> &nbsp;{{ country }}, &nbsp;
                        {{ (user.segment === '' || user.segment === 'General') || 'Investor' }} Module
                    </div>
                </div>
                <div class="ml-3 align-self-center">
                    <a href="#" class="text-white"><i class="icon-cog3"></i></a>
                </div>
            </div>
            <ng-container *ngIf="logoUrl$">
                <h5 class="card-title">
                    {{ user.name }}
                </h5>
                <p class="card-text">
                    <i class="icon-pin font-size-sm"></i> &nbsp;{{ country }}, &nbsp;
                    {{ (user.segment === '' || user.segment === 'General') || 'Investor' }} Module
                </p>
            </ng-container>
        </div>
    </div>

</div>


