import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class NgxPrivacyPolicyComponent implements OnChanges {
  public translateService: TranslateService;
  @Input('language') language: any = 'eng';

  constructor(translateService: TranslateService) {
    this.translateService = translateService;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.language = changes.language && changes.language.currentValue ? 'eng' : 'tur';
  }
}
