<div class="row">
  <div class="col-md-12">
    <nb-card>
      <nb-card-body>
        <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
          <h2 class="title">401 Unauthorized Page</h2>
          <small class="sub-title"></small>
          <button type="button" (click)="backClicked();" class="btn btn-block btn-hero-primary">
            Take me back
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
