

<!-- Sidebar menu -->
<nb-menu tag="sidebarMenu"
  class="sb-custom"
  [collapsedBreakpoints]="['xs', 'is', 'sm']"
  [responsive]="true"
    *ngIf="nbMenuItems()"
    [items]="nbMenuItems()" #sidebarMenu ></nb-menu>


