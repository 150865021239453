import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from '@insight-services/authentication.service';
import { User } from '@insight-models/user';

@Component({
  selector: 'usercard',
  templateUrl: './usercard.component.html',
  styleUrls: ['./usercard.component.scss']
})
export class UsercardComponent implements OnInit {
  @Input() visible: boolean = true
  logoUrl$: Observable<string>
  currentUser$: Observable<User>

  country: string = 'Turkey'

  constructor(
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.authService.getCurrentUser();
    this.logoUrl$ = this.authService.getCurrentUserCompanyLogoUrl();
  }

}
