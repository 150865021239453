import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { AuthModule } from './auth/auth.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeModule } from './home/home.module';

import { MatDialogModule } from '@angular/material/dialog';
import { AuthGuardService } from './services/auth-guard.service';
// import { NbOverlayService } from '@nebular/theme';
import { NotFoundComponent } from './home/not-found/not-found.component';
import { ErrorInterceptor } from './helpers/error-interceptor';
import { UserServiceInterceptor } from './helpers/userservice-interceptor';
import { AddDeviceDialogComponent } from './auth/add-device-dialog/add-device-dialog.component';

import { AuthenticationService } from './services/authentication.service';

import { UnauthorizedComponent } from './home/unauthorized/unauthorized.component';
import { AnalyticsService } from './services/gtm-analytics.service';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { TermsConditionsComponent } from './privacy-terms/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-terms/privacy-policy/privacy-policy.component';
import { DataDisclaimerComponent } from './privacy-terms/data-disclaimer/data-disclaimer.component';
import { RefundPolicyComponent } from './privacy-terms/refund-policy/refund-policy.component';
import { CookiePolicyComponent } from './privacy-terms/cookie-policy/cookie-policy.component';
import { PolicyOnProtectionOfPersonalDataComponent } from './privacy-terms/policy-on-protection-of-personal-data/policy-on-protection-of-personal-data.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbLayoutComponent, NbLayoutModule } from '@nebular/theme';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { tr, eng } from './helpers/dictionaries';
import { MatSelectModule } from '@angular/material/select';
import { StorageService } from './services/storage.service';
import { ToastrModule } from 'ngx-toastr';


export class DictionaryLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    if(lang === 'eng') {
      return of(JSON.parse(JSON.stringify(eng)));
    } else {
      return of(JSON.parse(JSON.stringify(tr)));
    }
  }
}

export class MissingTranslationLogger implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.log('missing translation', params);
    return 'missing translation:' + params;
  }
}

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        UnauthorizedComponent,
        AddDeviceDialogComponent,
        PrivacyTermsComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        DataDisclaimerComponent,
        RefundPolicyComponent,
        CookiePolicyComponent,
        PolicyOnProtectionOfPersonalDataComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatSelectModule,
        ThemeModule.forRoot(),
        NbLayoutModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            defaultLanguage: 'eng',
            useDefaultLang: true,
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MissingTranslationLogger
            },
            loader: {
                provide: TranslateLoader,
                useClass: DictionaryLoader
            }
        }),
        HomeModule,
        NbEvaIconsModule,
        NgbModule,
        AuthModule,
        MatDialogModule,
        ToastrModule.forRoot(),
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        AuthGuardService,
        AuthenticationService,
        AnalyticsService,
        TranslateService,
        StorageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserServiceInterceptor,
            multi: true
        }
    ]
})
export class AppModule { }


