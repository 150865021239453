<!-- <nb-layout> -->
<!-- <nb-layout-header fixed> -->
<div class="noprint header-container">
  <div class="logo-containter">
    <button (click)="toggleSidebar()" href="#" class="navigation" *ngIf="isSideMenuToggleButtonActive()">
      <nb-icon [icon]="iconClass" id="headerIcon" style="font-size: 24px;">
      </nb-icon>
    </button>
    <div class="logo" style="border: 0px;" (click)="goToSearch()">
      <img src="../../../../assets/images/logo-insight.svg" alt="Reidin - Data & Analytics" />
    </div>
  </div>
</div>

<!-- Menu items -->
<div style="overflow-y: hidden;" class="toggle-layout header-control">
  <nb-button-group *ngIf="menuItems()">
    <button class="tabs-buttons-unclicked" *ngFor="let item of menuItems(); index as i"
      [style.background-color]="activeTab() == item.id ? '#11524e' :null"
      [style.color]="activeTab() == item.id ? 'white' : '#9aa2ad'" (click)="setSelectedTopMenuItem(item,i)">
      {{ translateService.currentLang === "tur" ? item.title_tr : item.title_en}}
    </button>

    <button 
      class="tabs-buttons-unclicked"
      (click)="gotoNotifications()"
      [style.background-color]="activeTab() == 0 ? '#11524e' :null"
      [style.color]="activeTab() == 0 ? 'white' : '#9aa2ad'"
      *ngIf="(getCountry() | async) === 'AE'"
    >
      {{ translateService.currentLang === "tur" ? "BILDIRIMLER" : "NOTIFICATIONS"}}
    </button>
  </nb-button-group>
</div>

<!-- User profile menu -->
<div class="noprint header-container" *ngIf="currentUser$ | async as currentUser">
  <ng-container *ngIf="menuItems()">
    <button nbButton class="tabs-buttons-unclicked ng-star-inserted my-filters-button" [status]="danger"
      data-toggle="modal" data-target="#exampleModalCenter" (click)="openFiltersDialog()">
      <nb-icon icon="save-outline"></nb-icon>
      {{ "main.general.buttons.my-filter" | translate }}
    </button>
    <nb-actions size="small" id="nbFiltersIcon" class="ng-star-inserted my-filters-icon-button">
      <nb-action style="background-color: #1a3756;" icon="save-outline" data-toggle="modal"
        data-target="#exampleModalCenter" (click)="openFiltersDialog()">
      </nb-action>
    </nb-actions>
  </ng-container>
  <ngx-menu-switcher></ngx-menu-switcher>
  <!-- <ngx-notifications></ngx-notifications> -->
  <!-- <nb-actions size="small" id="nbNotificationsIcon" class="ng-star-inserted">
    <nb-action style="background-color: #1a3756;" icon="bell-outline" (click)="notificationsSoon()">
    </nb-action>
  </nb-actions> -->
  <nb-actions size="medium">
    <nb-action class="ng-star-inserted">
      <!-- <nb-user name="{{ currentUser.name }}"> -->
      <nb-user name="{{ currentUser.name }}" [nbContextMenu]="userMenu">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<!-- Preferences modal -->
<ng-template #preferencesModalTemplate let-ref="preferences-ref">
  <form [formGroup]="languageForm" class="preferences-ref bg-secondary p-4 rounded"
    (ngSubmit)="setPreferences(languageForm.value)">
    <h4 class="font-md py-2 mr-1">{{ 'main.views.header.user-preferences' | translate }}:</h4>
    <hr />
    <div class="form-group row">
      <label for="languageSelect" class="col-12 col-sm-6 col-form-label">{{ 'main.views.header.select-language.title' |
        translate }}:</label>
      <div class="col-12 col-sm-6">
        <nb-radio-group formControlName="languageValue">
          <nb-radio value="{{language}}" [checked]="translateService.currentLang === language"
            *ngFor="let language of availableLanguages">{{
            'main.language.' + language | translate: {value:
            'main.language.' + language } }}
          </nb-radio>
        </nb-radio-group>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="languageSelect" class="col-12 col-sm-6 col-form-label">Choose country:</label>
      <div class="col-12 col-sm-6">
        <nb-radio-group>
          <nb-radio *ngFor="let product of [{name: 'UAE', value: 'UAE'}, {name: 'Turkey', value: 'TR'}]"
            value="{{product?.value}}">{{product?.name}}</nb-radio>
        </nb-radio-group>
      </div>
    </div> -->

    <div class="form-group row">
      <div class="col">
        <button nbButton status="warning" type="submit" translate="main.general.buttons.okay" translate-default="Okay">
        </button>
      </div>

    </div>
  </form>
</ng-template>