import { Component, HostListener, ViewChild, inject } from '@angular/core';
import { MenuService } from '@insight-services/menu.service';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['home.component.scss'],
  templateUrl: 'home.component.html'
})
export class HomeComponent {
  private readonly menuService = inject(MenuService)

  showUserCard: boolean = true

  @ViewChild('header') headerElement;
  @ViewChild('sidebar') sidebarElement;
  @ViewChild('mainContent') mainContent;

  @HostListener('window:click', ['$event'])
  homeComponentListener(event: any) {
    if (event.target.className === 'columns' && this.sidebarElement._state === 'expanded' && window.innerWidth < 1200) {
      this.toggleSidebar('collapsed');
      this.sidebarElement._state = 'collapsed';
      this.headerElement.iconClass = 'menu-outline';
      this.headerElement.isCollapsed = true;
    }
  }

  public toggleSidebar(ev) {
    this.showUserCard = ev === 'expanded';
    const mainContentStyle = document.getElementById('mainContent').style;
    mainContentStyle.pointerEvents = this.showUserCard && window.innerWidth <= 767 ? 'none' : 'auto';
    mainContentStyle.opacity = this.showUserCard && window.innerWidth <= 767 ? '40%' : '100%';
  }

  /* Set the width of the side navigation to 250px */
  public openNav() {
    document.getElementById('mySidenav').style.width = '250px';
  }

  /* Set the width of the side navigation to 0 */
  public closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  isSideMenuCollapsed() {
    return this.menuService.isSidemenuCollapsed()
  }
}