import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '@insight-services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { AddDeviceDialogComponent } from '../add-device-dialog/add-device-dialog.component';
import { catchError, switchMap } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { NonNullableFormBuilder } from '@angular/forms';
import { EmailValidation, PasswordValidation } from '@insight-helpers/validations';
import { PowerBIService } from '@insight-services/power-bi.service';
import { LoginRequest } from '@insight-models/login-request';

declare let userGuideFunction:any;

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./login.component.scss']
})
export class NgxLoginComponent implements OnInit {
  currentProduct = { code: 'TR', country: 'UAE' };
  rememberMe = false
  redirectUrl: string
  messages$: Subject<[any]> = new Subject()
  public languageChose: boolean = true;
  submitted: boolean = true
  isLogin: boolean = false
  waitLogin: boolean = false
  width = window.innerWidth;
  errorDictionaryKeys: string[] = [];
  public static passData: boolean
  public selectedLanguage: string = 'Select Language';
  public languages: string[] = ['eng', 'tur'];
  loginForm = this.formBuilder.group({
    email: ['', EmailValidation],
    password: ['', PasswordValidation]
  });

  constructor(
    private formBuilder: NonNullableFormBuilder,
    protected changeDetector: ChangeDetectorRef,
    protected router: Router,
    private dialogService: NbDialogService,
    protected authService: AuthenticationService,
    public translateService: TranslateService,
    private toastrService: NbToastrService,
    private readonly powerbiService: PowerBIService,
  ) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('language') == null) {
      this.languageChose = this.languageChose;
    } else {
      if (sessionStorage.getItem('language') === 'true') {
        this.languageChose = true;
      } else {
        this.languageChose = false;
      }
      NgxLoginComponent.passData = this.languageChose;
    }
    this.translateService.use(this.languageChose ? 'eng' : 'tur');
    this.selectedLanguage = this.translateService.currentLang;
  }

  login() {
    if (!this.loginForm.valid) {
      this.errorDictionaryKeys = [];
      !this.loginForm.controls.email.valid ? this.errorDictionaryKeys.push('main.general.messages.warning.username-invalid') : '';
      !this.loginForm.controls.password.valid ? this.errorDictionaryKeys.push('main.general.messages.warning.password-character-valid') : '';
      this.submitted = false
    }
    if (this.loginForm.valid) {
      this.waitLogin = true;
      this.authService.login(this.loginForm.value as LoginRequest).pipe(
        catchError(err => {
          this.messages$.next([err]);
          this.errorDictionaryKeys = [];
          if (err.error.code === "101" && err.error.message === "Already logged in another device") {
            this.logoutAnotherDevice(this.loginForm.value.email);
            throw err;
          }
          else if (err.error.message === 'Invalid \"username\" or \"password\" in request.') {
            this.errorDictionaryKeys.push('main.general.messages.error.check-your-email-password')
            this.setFormState();
            throw err;
          }
          else if (err.error.code) {
            this.errorDictionaryKeys.push('main.general.messages.error.' + err.error.code)
            this.setFormState();
            throw err;
          }
          else {
            this.errorDictionaryKeys.push('main.general.messages.error.general-error')
            this.setFormState();
            throw err;
          }
        }),
        this.getPowerBiToken(),
        tap(_ => {
          this.submitted = true
          this.isLogin = true
          this.changeDetector.detectChanges();
          userGuideFunction();
          this.router.navigateByUrl('/home/dashboard')
        }),
      ).subscribe()
    }
  }

  private setFormState() {
    this.submitted = false;
    this.waitLogin = false;
    this.changeDetector.detectChanges();
  }

  getPowerBiToken() {
    return switchMap(_ => {
      return this.powerbiService.getPowerBiToken().pipe(
        tap(powerBiToken => {
          localStorage.setItem("powerbi_token", powerBiToken.access_token)
        }),
      )
    });
  }

  logoutAnotherDevice(email: string) {
    this.authService.logoutAnotherDevice(email).subscribe(res => {
      if (res.code === 200) {
        this.toastrService.info(this.translateService.instant("main.general.messages.info.another-device-logout"), this.translateService.instant("main.general.messages.title.info"));
        setTimeout(() => {
          this.login();
        }, 1000)
      }
    })
  }

  addDevice() {
    this.dialogService.open(AddDeviceDialogComponent, {
      context: {
        title: 'This is a title passed to the dialog component'
      }
    });
  }

  langualeOnChange(): void {
    this.languageChose = !this.languageChose;
    this.translateService.use(this.languageChose ? 'eng' : 'tur');
    this.languageChose ? sessionStorage.setItem('language', 'true') : sessionStorage.setItem('language', 'false');
    NgxLoginComponent.passData = this.languageChose;
  }
}
