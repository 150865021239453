<div class="d-flex flex-column">
    <div style="border-bottom: 1px solid #1a3756;" class="pb-3">
        <div class="filter-container">
            <ngx-power-bi [selectedDashboard]="selectedDashboard$"
                (onReportRendered)="setPowerBIComponent($event)"></ngx-power-bi>
        </div>

        <div class="form-row">

            <mat-form-field appearance="outline" class="email-form">
                <mat-label>Email</mat-label>
                <input matInput [ngModel]="email" readonly>
            </mat-form-field>

            <div class="form-row">
                <section class="frequency-section">
                    <label class="frequency-margin">Frequency:</label>
                    <mat-radio-group [formControl]="frequencyForm" color="primary">
                        <mat-radio-button class="frequency-margin" [value]="7">Weekly</mat-radio-button>
                        <mat-radio-button class="frequency-margin" [value]="30">Monthly</mat-radio-button>
                    </mat-radio-group>
                </section>

                <button mat-raised-button color="accent" (click)="isEditting() ? saveEdit() : saveNotification()">
                    {{isEditting() ? 'Save Editted Filter': 'Save Filter'}}
                </button>
            </div>

        </div>

    </div>


    <h2 class="mt-3">Created Notifications</h2>
    <ngx-notification-table [data]="data" [columns]="columns" [edittingData]="edittingNotification()"
        (onEdit)="edit($event, this.isTargetHierarchy)" (onExitEdit)="exitEdit()" (onDelete)="deleteNotification($event)"/>
</div>