<div
	class="container-fluid h-100 d-flex justify-content-center align-items-center"
	[ngClass]="country == 'AE' ? 'search-background' : 'search-background-tr'" 
>
<div></div>

	<div class="search-container">
		<ngx-search-bar
			(onSearch)="onSearchReport($event)"
			(onBack)="back()"
			[borderRounded]="searchInputBorder"
			[placeholders]="placeholders"
			[icon]="icon()"
		/>

		<ng-container *ngIf="stage() === 'first'; else lastStage">
			<div
				class="result-container rounded-bottom"
				*ngIf="result() as result"
			>
			<div class="result" *ngIf="country == 'TR'">
					<div class="d-flex align-items-center gap-1">
						<h4 class="title">{{"main.general.search-subtitle.city" | translate}}</h4>
						<reidin-city-icon/>
					</div>
					<span
						*ngFor="let city of result.citys"
						class="item"
						(click)="
							selectResult(
								'city',
								[
									city.fields.city_name
								],
								city.fields.location_level
							)
						"
					>
						{{ city.fields.city_name }}
					</span>
					<span *ngIf="result.citys?.length === 0" class="item">
						{{"main.general.search-subtitle.no-result-found" | translate}}
					</span>
					<span
						class="item"
						*ngIf="paginationState().city.next"
						(click)="getMore('city')"
					>
						{{"main.general.search-subtitle.more" | translate}}
					</span>
				</div>
				<div class="result">
					<div class="d-flex align-items-center gap-1">
						<h4 class="title">{{"main.general.search-subtitle.location" | translate}}</h4>
						<reidin-location-icon/>
					</div>
					<span
						*ngFor="let location of result.locations"
						class="item"
						(click)="
							selectResult(
								'location',
								[
									location.fields.location_name,
									location.fields.city_name,
									location.fields.county_name
								],
								location.fields.location_level
							)
						"
					>
						{{ location.fields.location_name }} ({{
							country == "TR"
							?
							(location.fields.location_level == "County"
							? location.fields.city_name 
							: location.fields.county_name + "," + location.fields.city_name)
							: "main.general.search-subtitle." +  location.fields.location_level.toLowerCase() | translate
						}})
					</span>
					<span *ngIf="result.locations?.length === 0" class="item">
						{{"main.general.search-subtitle.no-result-found" | translate}}
					</span>
					<span
						class="item"
						*ngIf="paginationState().location.next"
						(click)="getMore('location')"
					>
						{{"main.general.search-subtitle.more" | translate}}
					</span>
				</div>
				<div class="result">
					<div class="d-flex align-items-center gap-1">
						<h4 class="title">{{"main.general.search-subtitle.building" | translate}}</h4>
						<reidin-building-icon/>
					</div>
					<span
						*ngFor="let building of result.buildings"
						class="item"
						(click)="
							selectResult(
								'building',
								[
									building.fields.location_name,
									building.fields.property_name,
									building.fields.city_name,
									building.fields.county_name
								],
								building.fields.location_level
							)
						"
					>
						{{ building.fields.property_name }} ({{
							country == "TR"
							?
							(building.fields.building_level == "County" 
							? building.fields.city_name 
							: building.fields.county_name + "," + building.fields.city_name)
							: building.fields.location_name
						}})
					</span>
					<span *ngIf="result.buildings?.length === 0" class="item">
						{{"main.general.search-subtitle.no-result-found" | translate}}
					</span>
					<span
						class="item"
						*ngIf="paginationState().building.next"
						(click)="getMore('building')"
					>
						{{"main.general.search-subtitle.more" | translate}}
					</span>
				</div>
				<div class="result">
					<div class="d-flex align-items-center gap-1">
						<h4 class="title">{{"main.general.search-subtitle.developer" | translate}}</h4>
						<reidin-developer-icon/>
					</div>
					<span
						*ngFor="let developer of result.developers"
						class="item"
						(click)="
							selectResult('developer', [
								developer.fields.developer_name === undefined
								? developer.fields.developer_name_local
								: developer.fields.developer_name
							])
						"
					>
						{{
							developer.fields.developer_name === undefined
								? developer.fields.developer_name_local
								: developer.fields.developer_name
						}}
					</span>
					<span *ngIf="result.developers?.length === 0" class="item">
						{{"main.general.search-subtitle.no-result-found" | translate}}
					</span>
					<span
						class="item"
						*ngIf="paginationState().developer.next"
						(click)="getMore('developer')"
					>
						{{"main.general.search-subtitle.more" | translate}}
					</span>
				</div>
			</div>
		</ng-container>
		<ng-template #lastStage>
			<div
				class="result-container rounded-bottom"
				*ngIf="result() as result"
			>
				<div class="result">
					<h4 class="title">{{"main.general.search-subtitle.reports" | translate}}</h4>
					<span
						*ngFor="let subCategory of selected().subCategory"
						class="item"
						(click)="
							openDashboard({
								dashboardId: subCategory.dashboardId,
								slicers: subCategory.slicers,
								values: selected().value,
								locationLevel: selected().locationLevel,
								cityAndCommunity: subCategory.cityAndCommunity
							})
						"
					>
						{{ subCategory.name }}
					</span>
				</div>
			</div>
		</ng-template>
	</div>
</div>
