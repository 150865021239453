import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertsComponent } from './alerts.component';
// import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule
    // TranslateModule
  ],
  declarations: [AlertsComponent],
  providers: [],
  exports: [AlertsComponent]
})
export class AlertsModule {}
