import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@insight-environments/environment';
import { catchError, filter, map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { pipe } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { CompanyResult } from '@insight-models/company';
import { ResultModel } from '@insight-models/result-model';
import { LoginRequest } from '@insight-models/login-request';
import { User } from '@insight-models/user';
import { UserResponse } from '@insight-models/user-response';
import { ProductCodes } from '@insight-models/product-codes';
import { LoginResponse } from '@insight-models/login-response';
import { ResetPasswordRequest } from '@insight-models/reset-password-request';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

const defaultUser: User = {
  admin: false,
  closed: false,
  company: null,
  created_at: '',
  crm_user_id: null,
  email: '',
  c_prd: '',
  c_cnt: '',
  free_session: false,
  id: 0,
  is_active: false,
  logout_option: false,
  mobile_option: false,
  mobile_phone: null,
  multiple_device_option: false,
  name: 'Wait while loading...',
  products: [],
  provider: '',
  segment: '',
  session_count: 0,
  status: false,
  updated_at: null

};

const defaultUserResponse: UserResponse = {
  code: 0,
  message: '',
  data: defaultUser

};

export const defaultLoginResponse: LoginResponse = {
  access_token: '',
  expires_in: 0,
  client_id: '',
  device_id: '',
  expired_at: '',
  token_type: '',
  code: '',
  message: '',
  user: defaultUser
};

@Injectable()
export class AuthenticationService extends StorageService {

  public readonly currentUser$ = new BehaviorSubject<User | null>(this.getCurrentUserFromLocalStorage())
  public readonly authStatus$ = new BehaviorSubject<LoginResponse>(defaultLoginResponse)
  public readonly deviceId = this.createDeviceId()

  private getAndUpdateUserIfAuthenticated = pipe(
    filter((status: LoginResponse) => {
      const result = status.access_token !== ''; // || Date.now() >= status.expires_in * 1000
      return result;
    }),
    // tap(item => {
    //   console.log('Access token come to update')
    //   console.log(item)
    // }),
    // map(authStatus => this.authStatus$.next(authStatus)),
    mergeMap(() => this.getCurrentUser()),
    filter(user => user?.email !== ''),
    map((user: User) => {
      this.currentUser$.next(user);
    }),
    catchError(err => {
      console.log('Some error occured while getAndUpdateUser');
      console.log(err);
      throw err;
    })
    // share()
  )


  protected readonly updateCurrentUserIfNecessery$ = this.authStatus$.pipe(
    filter(authStatus => authStatus.access_token !== ''),
    this.getAndUpdateUserIfAuthenticated
  )

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private translateService: TranslateService,
    private toastrService: NbToastrService
  ) {
    super();
    if (this.hasExpiredToken()) {
      this.logout();
    } else if (this.getAuthStatus().access_token !== '') {
      this.authStatus$.next(this.getAuthStatus());
      setTimeout(() => this.updateCurrentUserIfNecessery$.subscribe(), 0);
    }

  }

  resetPassword(resetPasswordRequest: ResetPasswordRequest) {
    return this.http.post<ResultModel<never>>(`${environment.userServiceAPIUrl}/users/reset_password/${resetPasswordRequest.email}`, resetPasswordRequest)
  }

  forgotPassword(email: string) {
    return this.http.get<ResultModel<never>>(`${environment.userServiceAPIUrl}/users/reset_password/${email}`)
  }

  private createDeviceId() {
    return Math.floor(1e9 + Math.random() * 9e9);
  }

  // USER STATUS METHODS

  private fetchCurrentUserFromService(id?: string | null): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${environment.userServiceAPIUrl}/users/${id ? id : 'me'}`).pipe(
      tap(resp => {
        console.log('Response of current user /me');
        console.log(resp);
      }),
      filter(resp => resp.code === 200),
      retry(15)
    );
  }

  public getCurrentUsersCompanyInformation(id: number) {
    return this.http.get<CompanyResult>(`${environment.userServiceAPIUrl}/company_info/${id}`);
  }


  public getCurrentUser(): Observable<User> {
    const currentUser = this.getObject('user') as User;

    if (currentUser?.email !== '') {
      return of(currentUser);
    } else if (!this.hasExpiredToken()) {
      return this.fetchCurrentUserFromService().pipe(
        map(resp => resp.data)
      );
    } else {
      return of(defaultUser);
    }

  }

  protected setCurrentUser(user: User): void {
    this.setObject('user', user);
  }


  activateAccount(activation_key): Observable<any> {
    const uData = {};
    uData['activation_key'] = activation_key;
    return this.http.patch(
      // account-beta.reidin.com
      `${environment.userServiceAPIUrl}/users/free_user`,
      uData,
      httpOptions
    );
  }

  addDevice(token: string) {
    return this.http.get<any>(
      // account-beta.reidin.com
      `${environment.userServiceAPIUrl}/users/add_device?token=${token}`
    );
  }

  // AUTH STATUS METHODS


  hasExpiredToken(): boolean {
    const authStatus = this.getAuthStatus();

    if (authStatus?.access_token !== '') {
      const isTokenExpired = Date.now() >= Date.parse(authStatus.expired_at);
      return isTokenExpired;
    } else if (!this.storageService.getItem('userServiceToken')) {
      return false;
    }

    return true;
  }


  protected setAuthStatus(userServiceToken: LoginResponse) {
    this.setObject('userServiceToken', userServiceToken);
    this.authStatus$.next(userServiceToken);
  }

  public getAuthStatus(): LoginResponse {
    return this.getObject('userServiceToken') as LoginResponse || defaultLoginResponse;
  }

  protected clearAuthStatusToken() {
    this.removeItem('userServiceToken');
  }


  login(credentials: LoginRequest): Observable<any> {
    this.clearLocalStorage();
    const formData = new FormData();
    formData.append('username', credentials.email);
    formData.append('password', credentials.password);
    formData.append('grant_type', 'password');
    formData.append('device_id', this.createDeviceId().toString());
    const loginResponse = this.http.post<LoginResponse>(environment.userServiceAPIUrl + '/login', formData).pipe(
      filter(resp => resp.access_token !== ''),
      switchMap(response => this.getCurrentUsersCompanyInformation(response.user.id).pipe(
        map(companyResult => ({
          response,
          companyResult
        }))
      )),
      map(({ response, companyResult }) => {
        if (!this.productsControl(response.user.products)) {
          this.logoutForNonCustomer(response.user, response);
          let err = { error: { message: "You haven't insight product", code: 109 } };
          throw err;
        } else {
          response.user.company = companyResult.data
          this.setCurrentUser(response.user);
          this.setAuthStatus(response);
        }
        return response;
      }),
      this.getAndUpdateUserIfAuthenticated,
      catchError(err => {
        console.log('Error occurred while login at service...');
        console.log(err);
        throw err;
      })
    );
    return loginResponse;
  }

  logout(): void {
    this.getCurrentUser().subscribe(user => {
      let token = this.getAuthStatus();
      this.http.get(environment.userServiceAPIUrl + '/logout/' + user.email,
        {
          headers: {
            'Authorization': 'Basic ' + token.access_token,
            'access_token': token.access_token,
            'device_id': token.device_id
          }
        })
        .subscribe((response: any) => {
          if (response.code == 200) {
            this.storageService.clearLocalStorage();
            this.router.navigate(['auth/login']);
          }
        }, (error: any) => {
          if (error.status === 401 && error.error === "Session Not Found. Check Device ID") {
            this.toastrService.danger(this.translateService.instant("main.general.messages.error.session-not-found"), this.translateService.instant("main.general.messages.title.error"), { duration: 1000 });
            this.storageService.clearLocalStorage();
            setTimeout(() => {
              this.router.navigate(["auth/login"]);
            }, 1000)
          }
        })
    })
  }

  logoutAnotherDevice(email: string): Observable<any> {
    const logoutResponse = this.http.get(environment.userServiceAPIUrl + "/logout/" + email, {
      headers: {
        'Authorization': environment.clientSecret,
        'is_zoho': "true"
      }
    }).pipe(catchError(err => {
      console.log('Error occurred while logout another device at service...');
      throw err;
    }))
    return logoutResponse;
  }

  logoutForNonCustomer(user: User, authStatus: LoginResponse): void {
    this.http.get(environment.userServiceAPIUrl + "/logout/" + user.email, {
      headers: {
        'Authorization': 'Basic ' + authStatus.access_token,
        'access_token': authStatus.access_token,
        'device_id': authStatus.device_id
      }
    }).pipe(catchError(err => {
      console.log('Error occurred while logout for non customer device at service...');
      throw err;
    })).subscribe();
  }

  sendMail(mailData: any): Observable<any> {
    const formData = new FormData();
    formData.append('full_name', mailData.firstName);
    formData.append('email', mailData.email);
    formData.append('phone_number', mailData.phoneNumber);
    formData.append('company', mailData.company);
    formData.append('sector', mailData.industry);
    return this.http.post(environment.backendAPIUrl + '/api/demo-request', formData);
  }

  register(email, password): Observable<UserResponse> {
    return of(defaultUserResponse);
  }

  getCurrentUserCompanyLogoUrl(): Observable<string> {
    return this.http.get<string>(`${environment.backendAPIUrl}/api/company-logo/`).pipe(
      retry(5),
      map(res => res['logo_url'])
    );
  }

  productsControl(products): boolean {
    let isInsightUser: boolean = false;
    let today = Date.now();
    products.forEach(product => {
      if(product.code.includes("insight")){
        let end_date = new Date(product.end_date).valueOf();
        if (isNaN(end_date)) {
          end_date = new Date(product.end_date.replace(/-/g, "/")).valueOf()
        }
        if (today <= end_date) {
          isInsightUser = true;
        }
      }
    });
    return isInsightUser;
  }

  getCurrentUserFromLocalStorage(): User | null {
    return JSON.parse(localStorage.getItem("user"))
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem("user")) as User
  }
}
