import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@insight-environments/environment';
import { User } from '@insight-models/user';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  user: User;
  username: string;
  cntry: string;
  product: string;
  company: string;
  crm_user_id: string;
  free_session: boolean;
  fullname: string;
  provider: string;
  segment: string;
  userid: number;
  constructor(private router: Router) {}

  public event(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }

  public init() {
    this.listenForRouteChanges();

    try {

      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + environment.googleAnalyticsKey + `', {
        'user_id': '` + this.username + `',
          'custom_map': {'dimension1': 'user_email', 'dimension2': 'user_id', 'dimension3': 'country',
           'dimension4': 'product', 'dimension5': 'company', 'dimension6': 'crm_user_id',
           'dimension7': 'free_session', 'dimension8': 'fullname', 'dimension9': 'provider', 'dimension10': 'segment','dimension11':'route_path',
          'metric1': 'RouteMetric'}
        });
      `;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  private listenForRouteChanges() {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsKey, {
          page_path: event.urlAfterRedirects,
          user_id: this.username,
          app_name: 'insight',
          custom_map: {
            dimension1: 'user_email',
            dimension2: 'user_id',
            dimension3: 'country',
            dimension4: 'product',
            dimension5: 'company',
            dimension6: 'crm_user_id',
            dimension7: 'free_session',
            dimension8: 'fullname',
            dimension9: 'provider',
            dimension10: 'segment',
            dimension11: 'route_path',
            metric1: 'RouteMetric'
          }
        });
        gtag('set', { user_id: this.username });
        gtag('event', 'route_changed',
          {
            user_email: this.username,
            user_id: this.username,
            country: this.cntry,
            product: this.product,
            company: this.company,
            crm_user_id: this.crm_user_id,
            free_session: this.free_session,
            fullname: this.fullname,
            provider: this.provider,
            segment: this.segment,
            route_path: event.url,
            RouteMetric: 1
          });
        gtag('require', 'linker');
        gtag('linker:autoLink', ['cumul.io'], false, true);
      }
    });
  }
}
