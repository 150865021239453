import { RequiredTextValidation } from '@insight-helpers/validations';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { EmailValidation, PhoneNumberValidation } from '@insight-helpers/validations';
import { AuthenticationService } from '@insight-services/authentication.service';

@Component({
  selector: 'ngx-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss']
})

export class NgxRequestDemoComponent implements OnInit {
  @Input() languageChose: boolean = true;
  public static passData: boolean;
  emailForm: UntypedFormGroup;
  messageCheckbox: boolean = true;
  termsAndPrivacyCheckbox: boolean = true;

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('language') == null) {
      this.languageChose = this.languageChose;
    } else {
      if (sessionStorage.getItem('language') === 'true') {
        this.languageChose = true;
      } else {
        this.languageChose = false;
      }
      NgxRequestDemoComponent.passData = this.languageChose;
    }

    this.emailForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', RequiredTextValidation),
      secondName: new UntypedFormControl('', RequiredTextValidation),
      email: new UntypedFormControl('', EmailValidation),
      phoneNumber: new UntypedFormControl('', PhoneNumberValidation),
      company: new UntypedFormControl('', RequiredTextValidation),
      industry: new UntypedFormControl('ASP (Application Service Provider)', RequiredTextValidation),
    })
  }

  sendMail(): void {
    if (this.emailForm.valid && this.termsAndPrivacyCheckbox && this.messageCheckbox) {
      this.authService.sendMail(this.emailForm.value).subscribe();
    }
  }
}
