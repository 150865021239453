<div class="modal fade" tabindex="-1" role="dialog" id="privacy-policy" aria-labelledby="privacy-policy"
  aria-hidden="true">
  <ngx-privacy-policy></ngx-privacy-policy>
</div>

<div class="modal fade" tabindex="-1" role="dialog" id="terms-and-conditions" aria-labelledby="terms-and-conditions"
  aria-hidden="true">
  <ngx-terms-and-conditions></ngx-terms-and-conditions>
</div>

<div class="modal fade" id="request-demo" tabindex="-1" role="dialog" aria-labelledby="request-demo" aria-hidden="true">
  <ngx-request-demo [languageChose]="languageChose"></ngx-request-demo>
</div>

<div class="desktop">
  <div style="height: 100vh" class="row">
    <div class="col-md-9">
      <ngx-landing-promotion [languageChose]="languageChose" [isDesktop]="true"></ngx-landing-promotion>
    </div>
    <div class="col-md-3 form-container">
      <img class="logo mt-4" src="../../../assets/images/r-insight-logo.svg" alt="" />
      <div class="row mt-5">
        <div class="col-sm-12">
          <h4 class="login-header text-center mb-3">
            {{ this.languageChose ? "Forgot Password" : "Şifre Sıfırla" }}
          </h4>
        </div>
      </div>
      <hr class="line" />
      <form (ngSubmit)="forgotPassword()" *ngIf="!isMailSent(); else resetPassword">
        <div class="form-control-group">
          <label class="form-header">
            {{ this.languageChose ? "Email Address" : "Eposta Adresi" }}
          </label>
          <input [formControl]="email" style="height: 1.6em; align-items: center" class="form-input" name="email"
            pattern=".+@.+\..+" placeholder="{{
              this.languageChose ? 'Email Address' : 'Eposta Adresi'
            }}" />
        </div>
        <hr class="line" />
        <button class="form-button mt-3 d-flex align-items-center justify-content-center flex-row" type="submit"
          [ngClass]="!email.valid ? 'disable-button': null" [disabled]="!email.valid">
          <span>{{ this.languageChose ? "SEND MAIL" : "MAİL GÖNDER" }}</span>
          <div class="text-center spinner-border ml-2" style="opacity: 0.5" [class.d-none]="!isLoading()"></div>
        </button>
      </form>
      <ng-template #resetPassword>
        <ngx-reset-password [languageChose]="languageChose" [email]="email.value" id="reset-password-desktop"/>
      </ng-template>
      <div class="container d-flex justify-content-center">
        <div class="form-close">
          <a routerLink="/auth/login">
            <button type="button" class="form-close-button">
              <img src="../../../assets/close-icon-insight.svg" alt="" />
            </button>
          </a>
        </div>
      </div>
      <div style="margin-top: 30px; text-align: center" class="container">
        <a class="privacy-terms" data-toggle="modal" data-target="#privacy-policy">
          <ins>
            {{ this.languageChose ? "Privacy Policy" : "Gizlilik Politikası" }}
          </ins>
        </a>
      </div>
      <div style="margin-top: 10px; text-align: center" class="container">
        <a class="privacy-terms" data-toggle="modal" data-target="#terms-and-conditions">
          <ins>
            {{
            this.languageChose ? "Terms and Conditions" : "Kullanım Koşulları"
            }}
          </ins>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="container mobile">
  <div class="row">
    <div class="col-md-12">
      <div class="form-container">
        <img class="logo" src="../../../assets/images/r-insight-logo.svg" alt="" />
        <div class="row">
          <div class="col-sm-12">
            <h1 class="login-header text-center">
              {{ this.languageChose ? "Reset Password" : "Şifreni Sıfırla" }}
            </h1>
          </div>
        </div>
        <form (ngSubmit)="forgotPassword()" *ngIf="!isMailSent(); else resetPassword">
          <div class="form-control-group">
            <label class="form-header">
              {{ this.languageChose ? "Email Address" : "Eposta Adresi" }}
            </label>
            <input [formControl]="email" style="height: 1.6em; align-items: center" class="form-input" name="email"
              pattern=".+@.+\..+" placeholder="{{
                this.languageChose ? 'Email Address' : 'Eposta Adresi'
              }}" />
          </div>
          <hr class="line" />
          <button class="form-button mt-3 d-flex align-items-center justify-content-center flex-row" type="submit"
            [ngClass]="!email.valid ? 'disable-button': null" [disabled]="!email.valid">
            <span>{{ this.languageChose ? "SEND MAIL" : "MAİL GÖNDER" }}</span>
            <div class="text-center spinner-border ml-2" style="opacity: 0.5" [class.d-none]="!isLoading()"></div>
          </button>
        </form>
        <ng-template #resetPassword>
          <ngx-reset-password [languageChose]="languageChose" [email]="email.value" id="reset-password-mobile"/>
        </ng-template>
        <button type="button" class="form-request-demo" data-toggle="modal" data-target="#request-demo">
          {{
          this.languageChose
          ? "Request Your Free Demo"
          : "Ücretsiz Demonuzu İsteyiniz"
          }}
        </button>
        <div class="container d-flex justify-content-center">
          <div class="form-close">
            <a routerLink="/auth/login">
              <button type="button" class="form-close-button">
                <img src="../../../assets/close-icon-insight.svg" alt="" />
              </button>
            </a>
          </div>
        </div>
        <div style="margin-top: 10px; text-align: center" class="container">
          <a class="privacy-terms" data-toggle="modal" data-target="#privacy-policy">
            <ins>
              {{
              this.languageChose ? "Privacy Policy" : "Gizlilik Politikası"
              }}
            </ins>
          </a>
        </div>
        <div style="margin-top: 10px; text-align: center" class="container">
          <a class="privacy-terms" data-toggle="modal" data-target="#terms-and-conditions">
            <ins>
              {{
              this.languageChose
              ? "Terms and Conditions"
              : "Kullanım Koşulları"
              }}
            </ins>
          </a>
        </div>
      </div>
    </div>
    <ngx-landing-promotion [languageChose]="languageChose" [isDesktop]="false"></ngx-landing-promotion>
  </div>
</div>