<div class="search-container" [ngClass]="[border, shadow]">
	<svg
		width="35"
		height="35"
		class="search-icon"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		*ngIf="icon === 'search'"
	>
		<path
			d="M10 2.5a7.5 7.5 0 0 1 5.964 12.048l4.743 4.745a1 1 0 0 1-1.32 1.497l-.094-.083-4.745-4.743A7.5 7.5 0 1 1 10 2.5Zm0 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11Z"
		/>
	</svg>
	<svg
		width="35"
		height="35"
		class="back-icon"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
		*ngIf="icon === 'back'"
		(click)="back()"
	>
		<path
			d="M10.295 19.716a1 1 0 0 0 1.404-1.425l-5.37-5.29h13.67a1 1 0 1 0 0-2H6.336L11.7 5.714a1 1 0 0 0-1.404-1.424l-6.924 6.822a1.25 1.25 0 0 0 0 1.78l6.924 6.823Z"
		/>
	</svg>
	<div class="loading-icon-container" *ngIf="icon === 'load'">
		<svg
			class="search-icon"
			width="35"
			height="35"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" />
		</svg>
		<svg
			class="search-icon"
			width="35"
			height="35"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" />
		</svg>
		<svg
			class="search-icon"
			width="35"
			height="35"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" />
		</svg>
	</div>
	<input
		[placeholder]="placeholder()"
		class="search-input"
		[formControl]="search"
		#searchInput
	/>
</div>
