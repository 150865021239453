import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-data-disclaimer',
  templateUrl: './data-disclaimer.component.html',
  styleUrls: ['./data-disclaimer.component.scss']
})
export class DataDisclaimerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
