import {
	DestroyRef,
	Injectable,
	computed,
	inject,
	signal,
} from "@angular/core";
import { AuthenticationService } from "@insight-services/authentication.service";
import { SearchProxyService } from "./search-proxy.service";
import { HttpErrorResponse } from "@angular/common/http";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { PaginatedResultModel } from "@insight-models/result-model";
import { Subject, merge, EMPTY } from "rxjs";
import { map, tap, switchMap, filter, catchError } from "rxjs/operators";
import {
	SearchResult,
	Pagination,
	Stage,
	Icon,
	Paginate,
	Building,
	Developer,
	SubCategory,
	Location,
	SelectedResult,
	OpenDashboardParams,
	City,
	PaginationObj,
} from "../models/models";
import { Country } from "@insight-models/country";
import { Router } from "@angular/router";
import { ProductCodes } from "@insight-models/product-codes";

@Injectable()
export class SearchService {
	private readonly searchProxyService = inject(SearchProxyService);
	private readonly authService = inject(AuthenticationService);
	private readonly destroyRef = inject(DestroyRef);
	private readonly router = inject(Router);

	readonly #search$ = new Subject<string>();
	readonly search = signal("");

	readonly result = signal<SearchResult>(undefined);
	readonly placeholders = [];
	readonly paginationObj:PaginationObj = {
		page: 1,
		size: 3,
		next: undefined,
		previous: undefined,
	}
	readonly paginationState = signal<Pagination>({
		city: this.paginationObj,
		location: this.paginationObj,
		developer: this.paginationObj,
		building: this.paginationObj,
	});

	readonly selected = signal<SelectedResult>(undefined);

	readonly isLoading = signal(false);

	readonly stage = computed<Stage>(() => {
		if (this.selected()) return "last";
		return "first";
	});

	readonly icon = computed<Icon>(() => {
		if (this.stage() === "first" && !this.isLoading()) return "search";
		if (this.stage() === "first" && this.isLoading()) return "load";
		return "back";
	});

	constructor() {
		this.searchReport().subscribe();
		this.getCountry().subscribe((country:Country) =>  
			country == "TR"
			? this.placeholders.push(
				"Şehir Ara",
				"Proje Ara",
				"Konum Ara",
				"Geliştirici Ara"
			)
			: this.placeholders.push(
				"Search For Property",
				"Search For Location",
				"Search For Developer"
			));	
	}

	back() {
		this.selected.set(undefined);
	}

	getCountry() {
		return this.authService.getCurrentUser().pipe(
			map((user) => {
				for(let product of user.products){	
					if (
						product.code.includes("insight") || product.code.includes("INSFULL")
					){
						return product.country as Country;
					}
				}
				return "AE" as Country;
			})
		);
	}

	searchReport() {
		return this.#search$.pipe(
			tap((search) => {
				this.isLoading.set(true);
				this.resetState();
			}),
			switchMap((search) =>
				this.getCountry().pipe(
					map((country) => ({
						search,
						country,
					}))
				)
			),
			switchMap(({ country, search }) =>
				merge(
					this.getSubCategories<City>(
						"city",
						search,
						country,
						this.paginationState().city
						),
					this.getSubCategories<Location>(
						"location",
						search,
						country,
						this.paginationState().city
						),
					this.getSubCategories<Building>(
						"building",
						search,
						country,
						this.paginationState().city
						),
					this.getSubCategories<Developer>(
						"developer",
						search,
						country,
						this.paginationState().city
						)
				)
			),
			tap((_) => this.isLoading.set(false)),
			takeUntilDestroyed()
		);
	}

	onSearchReport(search: string) {
		if (search) {
			this.#search$.next(search);
			this.search.set(search);
		} else {
			this.resetState();
		}
	}

	getSubCategories<T>(category:string,search: string, country: Country, pagination: Paginate){
		return this.searchProxyService
			.searchByCategoryName<T>(category,search, country, pagination)
			.pipe(
				filter(Boolean),
				catchError((err: HttpErrorResponse) => EMPTY),
				tap((value:PaginatedResultModel<T>) => {
					this.result.update((state) => ({
						...state,
						[`${category}s`]: value.data.results,
					}));

					this.paginationState.update((state) => ({
						...state,
						[category]: {
							...pagination,
							next: !!value.data.next,
							previous: !!value.data.previous,
						},
					}));		
				})
			)
	}

	getSearchResponse<T>(category:string,pagination:Paginate){
		return this.getCountry()
				.pipe(
					tap((country) => this.isLoading.set(true)),
					switchMap((country) =>
						this.searchProxyService.searchByCategoryName<T>(
							category,
							this.search(),
							country,
							pagination
						)
					),
					tap((country) => this.isLoading.set(false)),
					takeUntilDestroyed(this.destroyRef)
				)
	}

	getMore<T>(category){
		const paginationState = this.paginationState();
		const selectedPaginationState = paginationState[category];
		if (
			selectedPaginationState.next &&
			selectedPaginationState.page === 1 &&
			selectedPaginationState.size === 3
		){
			const pagination: Paginate = {
				page: 1,
				size: 10,
			};
			this.getSearchResponse<T>(category,pagination)
				.subscribe({
					next: (value:PaginatedResultModel<T>) => {
						this.result.update((state) => ({
							...state,
							[`${category}s`]: value.data.results,
						}));

						this.paginationState.update((state) => ({
							...state,
							[category]: {
								...pagination,
								next: !!value.data.next,
								previous: !!value.data.previous,
							},
						}));
					},
				});
		}else if (selectedPaginationState.next) {
			const pagination: Paginate = {
				page: selectedPaginationState.page + 1,
				size: 10,
			};
			this.getSearchResponse<T>(category,pagination)
				.subscribe({
					next: (value:PaginatedResultModel<T>) => {
						this.result.update((state) => ({
							...state,
							[`${category}s`]: [
								...state[`${category}s`],
								...value.data.results,
							],
						}));
						this.paginationState.update((state) => ({
							...state,
							[category]: {
								...pagination,
								next: !!value.data.next,
								previous: !!value.data.previous,
							},
						}));
					},
				});
		}
	}

	get searchInputBorder() {
		if (this.result()) return "top";
		return "all";
	}

	selectResult(type: keyof SubCategory, value: string[], locationLevel?: string) {
		let userCountry:string;
		this.getCountry().subscribe(country => userCountry = country);
		const result = this.searchProxyService.getSubCategories(userCountry,locationLevel);
		
		const selectedResult: SelectedResult = {
			subCategory: result[type],
			value: value,
			locationLevel: locationLevel,
		};
		this.selected.set(selectedResult);
	}

	openDashboard(openDashboardParams: OpenDashboardParams) {
		const url = this.router.serializeUrl(this.router.createUrlTree(
			["home/dashboard/", openDashboardParams.dashboardId],
			{
				queryParams: {
					value: openDashboardParams.values,
					filterType: openDashboardParams.slicers.map(
						(slicer) => slicer.filterType
					),
					visualName: openDashboardParams.slicers.map(
						(slicer) => slicer.visualName
					),
					locationLevel: openDashboardParams.locationLevel,
					cityAndCommunity: openDashboardParams.cityAndCommunity
				},
			}
		))
		window.open(url, '_blank');
	}
	
	resetState() {
		this.result.set(undefined);
		this.selected.set(undefined);	
		this.paginationState.set({
			city: this.paginationObj,
			location: this.paginationObj,
			developer: this.paginationObj,
			building: this.paginationObj,
		});
	}
}
