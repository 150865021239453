<div class="modal fade" tabindex="-1" role="dialog" id="privacy-policy" aria-labelledby="privacy-policy"
  aria-hidden="true" data-target="privacy-policy">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{this.language === 'eng' ? 'Privacy Policy' :
          'Gizlilik Politikası'}}
        </h5>
      </div>
      <div class="modal-body english"
        [ngStyle]="{'display':this.language === 'eng' ? 'block' : 'none'}">
        <p class="modal-body-text">
          REIDIN FZ LLC “REIDIN”, a registered company in Dubai Development
          Authority with a registered address at 2304 Concord Tower, Dubai Media
          City, Dubai-U.A.E.
        </p>
        <P class="modal-body-text">REIDIN and its subsidiary/associate (“us”, “we”, or “our”) operates the
          http://www.reidin.com website (the “Service”) and associate services
          “R-MAP”, “R-INSIGHT”, “R-REBIS”, “R-RETAIL”, “R-CMA”</P>
        <P class="modal-body-text">This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Website and Service and
          the choices you have associated with that data.</P>
        <P class="modal-body-text">We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, terms used in this Privacy Policy have the same meanings as in
          our Terms and Conditions, accessible from http://www.reidin.com</P>
        <h3 class="modal-body-title">Information Collection and Use</h3>
        <p class="modal-body-text">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h3 class="modal-body-title">Types of Data Collected</h3>
        <p class="modal-body-text">Personal Data</p>
        <p class="modal-body-text">
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personally, identifiable information may
          include, but is not limited to:
        </p>
        <ul>
          <li class="modal-body-text">Email address</li>
          <li class="modal-body-text">First name and last name</li>
          <li class="modal-body-text">Phone number</li>
          <li class="modal-body-text">Website</li>
          <li class="modal-body-text">Company Name</li>
          <li class="modal-body-text">Address, State, Province, ZIP/Postal code, City</li>
        </ul>
        <h3 class="modal-body-title">Cookies and Usage Data</h3>
        <h3 class="modal-body-title">Usage Data</h3>
        <p class="modal-body-text">
          We may also collect information how the Service is accessed and used
          (“Usage Data”). This Usage Data may include information such as your
          computer’s Internet Protocol address (e.g. IP address), browser type,
          browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data.
        </p>
        <h3 class="modal-body-title">Tracking & Cookies Data</h3>
        <p class="modal-body-text">
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information. Cookies are files with
          small amount of data which may include an anonymous unique identifier.
          Cookies are sent to your browser from a website and stored on your
          device. Tracking technologies also used are beacons, tags, and scripts
          to collect and track information and to improve and analyse our Service.
          You can instruct your browser to refuse all cookies or to indicate when
          a cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Service.
        </p>
        <h3 class="modal-body-title">Examples of Cookies we use:</h3>
        <ul>
          <li class="modal-body-text">Session Cookies. We use Session Cookies to operate our Service.</li>
          <li class="modal-body-text">
            Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings.
          </li>
          <li class="modal-body-text">
            Security Cookies. We use Security Cookies for security purposes.
          </li>
        </ul>
        <h3 class="modal-body-title">Use of Data</h3>
        <p class="modal-body-text">
          REIDIN uses the collected data for various purposes:
        </p>
        <ul>
          <li class="modal-body-text">To provide and maintain the Service</li>
          <li class="modal-body-text">To notify you about changes to our Service</li>
          <li class="modal-body-text">
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li class="modal-body-text">To provide customer care and support</li>
          <li class="modal-body-text">
            To provide analysis or valuable information so that we can improve the
            Service
          </li>
          <li class="modal-body-text">To monitor the usage of the Service</li>
          <li class="modal-body-text">To detect, prevent and address technical issues</li>
          <li class="modal-body-text">To send you non-marketing commercial communications</li>
          <li class="modal-body-text">
            To send you marketing communications relating to our business or the
            businesses of carefully selected third parties which REIDIN. think may
            be of interest to you, by post or, where you have specifically agreed
            to this, by email or similar technology (you can inform us at any time
            if you no longer require marketing communications)
          </li>
          <li class="modal-body-text">
            To provide third parties with statistical information about our users
            (but those third parties will not be able to identify any individual
            user from that information)
          </li>
          <li class="modal-body-text">
            To verify compliance with the terms and conditions governing the use
            of REIDN.com
          </li>
        </ul>
        <h3 class="modal-body-title">Transfer of Data</h3>
        <p class="modal-body-text">
          Your information, including Personal Data, may be transferred to any of
          the countries where we operate or not and maintained on servers and
          computers located outside of your state, province, country or other
          governmental jurisdiction where the data protection laws may differ than
          those from your jurisdiction. If you are located outside United Arab
          Emirates and choose to provide information to us, please note that we
          transfer the data, including Personal Data, to countries including
          United Arab Emirates and other where we need to process it. Your consent
          to this Privacy Policy followed by your submission of such information
          represents your agreement to that transfer. REIDIN will take all steps
          reasonably necessary to ensure that your data is treated securely and in
          accordance with this Privacy Policy and no transfer of your Personal
          Data will take place to an organization or a country unless there are
          adequate controls in place including the security of your data and other
          personal information.
        </p>
        <h3 class="modal-body-title">Business Transactions</h3>
        <p class="modal-body-text">
          We may assign or transfer this Policy, as well as your account and
          related information and data, including any personal information, to any
          person or entity that acquires all or substantially all of our business,
          stock or assets, or with whom we merge. If we do, we will inform them of
          the requirement to handle your personal information in accordance with
          this Policy.
        </p>
        <h3 class="modal-body-title">Children’s Personal Information</h3>
        <p class="modal-body-text">
          We do not knowingly collect any personal information from children under
          the age of 18. If you are under the age of 18, please do not submit any
          personal information through our Websites or Services. If, however, we
          collect and use information about any minor, we will comply with the
          applicable laws. We encourage parents and legal guardians to monitor
          their children’s Internet usage and to help enforce this Policy by
          instructing their children never to provide personal information through
          the Websites or Services without their permission.
        </p>
        <h3 class="modal-body-title">Disclosure of Data</h3>
        <p class="modal-body-text">Disclosure of Data</p>
        <h3 class="modal-body-title">Security of Data</h3>
        <p class="modal-body-text">
          The security of your data is important to us but remember that no method
          of transmission over the Internet, or method of electronic storage is
          100% secure. While we strive to use commercially acceptable means to
          protect your Personal Data, we cannot guarantee its absolute security.
        </p>
        <h3 class="modal-body-title">Service Providers</h3>
        <p class="modal-body-text">
          We may employ third party companies and individuals to facilitate our
          Service (“Service Providers”), to provide the Service on our behalf, to
          perform Service-related services or to assist us in analysing how our
          Service is used. These third parties have access to your Personal Data
          only to perform these tasks on our behalf and are obligated not to
          disclose or use it for any other purpose.
        </p>
        <h3 class="modal-body-title">Links to Other Sites</h3>
        <p class="modal-body-text">
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third-party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy Policy
          of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any
          third-party sites or services.
        </p>
        <h3 class="modal-body-title">Changes to This Privacy Policy</h3>
        <p class="modal-body-text">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by publishing the new Privacy Policy on this page. We
          will let you know via email and/or a prominent notice on our Service,
          prior to the change becoming effective and update the “effective date”
          at the top of this Privacy Policy. You are advised to review this
          Privacy Policy periodically for any changes. Changes to this Privacy
          Policy are effective when they are posted on this page.
        </p>
        <h3 class="modal-body-title">Contact Us</h3>
        <p class="modal-body-text">
          If you have any questions about this Privacy Policy, please contact us:
        </p>
        <ul>
          <li class="modal-body-text">By email: support&#64;reidin.com</li>
          <li class="modal-body-text">By phone number: +971 4 277 68 35</li>
        </ul>
      </div>
      <div class="modal-body turkish"
        [ngStyle]="{'display':this.language === 'tur' ? 'block' : 'none'}">
        <ol>
          <li class="modal-body-text">
            REIDIN, kullanıcıların ve üyelerin, bireysel veya kurumsal gizliliğini korumak ve tüm teknolojik
            servislerden
            maksimum yararlanmasını sağlayabilmek amacıyla; bireysel / kurumsal bilgi ve veri güvenliği için çeşitli
            ilkeler benimsemiştir. Bu gizlilik ilkeleri; REIDIN ve REIDIN’e bağlı tüm internet siteleri, tüm mobil
            uygulamaları ve tüm alt hizmetler üzerinde veri toplanması ve/veya kullanımı konusunda uygulanmak üzere
            belirlenmiş ve beyan edilmektedir.
          </li>
          <li class="modal-body-text">REIDIN ve REIDIN’e bağlı internet sitelerini, uygulamalarını ziyaret etmekle
            ve/veya
            mobil uygulamalarını kullanmakla ve/veya kayıtlı üye olmakla belirtilen bu ilkelerin tümü Kullanıcı
            tarafından
            kabul edilmiş addolunur. REIDIN, kendisine bağlı internet sitelerinde iletişim başlıklı sayfada belirtilen
            iletişim adreslerinden birisi ile kendisine yapılacak geri beslemeler doğrultusunda, “Gizlilik Bildirimi”
            bölümünde düzeltme ve güncelleme gibi işlemleri, önceden bildirmeksizin her zaman yapma hakkına sahiptir.
          </li>
          <li class="modal-body-text">REIDIN, kişisel verileri işlerken 6698 sayılı Kişisel Verilerin Korunması
            Kanunu’na
            (KVKK) uygun hareket edeceğini taahhüt eder. REIDIN, Kullanıcılar tarafından REIDIN ve REIDIN’e bağlı
            internet
            siteleri ve/veya mobil uygulamaları üzerinden kendisine elektronik ortamdan iletilen her türden bireysel /
            kurumsal bilgileri ve verileri, KVKK’da belirtilen işleme şartları ile diğer yasal yükümlülükleri yerine
            getirme amacı dışında üçüncü kişilere açıklamayacak, paylaşmayacak, satışını yapmayacak ve kullanılmasına
            izin
            vermeyecektir.
          </li>
          <li class="modal-body-text">REIDIN, e.posta, SMS veya telefon ile iletişim faaliyetlerinde, yasaların
            düzenlediği
            şekilde izinli bülten gönderebilir, izinli pazarlama yapabilir, çeşitli araç ve yöntemlerle bu iletişim
            faaliyetlerinden istatistiki amaçlı veriler toplayabilir. REIDIN, kullanıcının isteği dışında ticari
            iletişim
            (reklam/kampanya/tanıtım) faaliyeti yapmamayı, izin alınmaksızın pazarlama yapmamayı, kullanıcının
            sistemden
            ücretsiz ve kolayca çıkabilmesini sağlayacak araçlar sunmayı beyan ve taahhüt eder. Tacirler ve esnaflara
            yönelik gerçekleştirilecek ticari iletişime ilişkin haller saklıdır.
          </li>
          <li class="modal-body-text">REIDIN, REIDIN’e bağlı internet siteleri içerisinden ve/veya mobil
            uygulamalarından
            başkaca sitelere
            link(bağlantı) sağlayabilir. REIDIN, bu link (bağlantı) yoluyla erişilen diğer sitelerin ve/veya mobil
            uygulamaların gizlilik uygulama ve politikalarına, ayrıca barındırdıkları görsel, metinsel her tür
            içeriklerine ilişkin olarak hiç bir sorumluluk taşımamaktadır.
          </li>
          <li class="modal-body-text">Reidin, Kullanıcıya ait kişisel verileri ve bu kişisel verileri kullanılarak elde
            ettiği yeni verileri veya Kullanıcıların kendilerinin pozitif hareketleriyle web sitesine girdikleri
            kişisel
            bilgileri başta iletişim taleplerini karşılayabilmek, Yasal yükümlülüklerini ifa edebilmek, Kullanıcı
            deneyimini geliştirmek, Kullanıcıların güvenliğini sağlamak, Elektronik veya kâğıt ortamında işleme
            dayanak
            olacak tüm kayıt ve belgeleri düzenlemek; mevzuat ve ilgili otoritelerce öngörülen bilgi saklama,
            raporlama,
            bilgilendirme yükümlülüklerine uymak; talep edilen ürün ve hizmetlerini sunabilmek, kurumsal yönetim
            politikalarımızı uygulayabilmek, Kullanıcı kimliklerini doğrulamak, sistem performansını geliştirmek olmak
            üzere yukarıda işleme amaçları kısmında belirtilen amaçlarını gerçekleştirebilmek için çağrı merkezleri,
            şikayet yönetimi ve güvenliğin sağlanmasına ilişkin yazılım şirketleri ile ve yasal zorunluluklar
            kapsamında
            yetkili kurum, kuruluş, merci, idari ve yargı organları ile paylaşabilecektir. Reidin, kişisel verileri
            yukarıda belirtilen kategoriler ve amaçlar dahilinde bu amaçlarla sınırlı ve orantılı olmak üzere yurt
            içinde
            üçüncü kişilere aktarabileceği gibi KVKK’nın 9. Maddesinde belirtilen yasal şartların sağlanması halinde
            yurt
            dışına da aktarabilecektir.
          </li>
          <li class="modal-body-text">REIDIN, kendisine verilen gizli bilgileri yasal mevzuat çerçevesinde özel ve
            gizli
            tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak kabul ettiğini ve gizliliğin sağlanıp
            sürdürülmesi,
            gizli bilginin tamamının veya herhangi bir parçasının kamu alanına girmesini veya yetkisiz kullanımını
            veya
            üçüncü bir kişiye açıklanmasını önleme gereği olan gerekli tüm teknik, hukuki ve yönetimsel tedbirleri
            almayı
            ve üzerine düşen tüm özeni tam olarak göstermeyi işbu bildirimle taahhüt etmektedir.
          </li>
          <li class="modal-body-text">REIDIN, kullanıcıların REIDIN ve REIDIN’e bağlı internet siteleri ve/veya mobil
            uygulamalardaki kullanım bilgilerini, teknik bir iletişim dosyası olan çerezler (Cookie) kullanarak,
            Uygulama
            kullanımı hakkındaki bilgileri IP ya da Sosyal Ağ hesabı kullanıcı verileri ile elde edebilir. Çerez
            denilen
            teknik iletişim dosyası, internet sitesini zamansal oranlamalı olarak kaç kişinin kullandığını, bir
            kişinin
            ilgili internet sitesini hangi amaçla, kaç kez ziyaret ettiği ve ne kadar kaldığı hakkında istatistiki
            bilgiler elde etmeye ve kullanıcılar için özel olarak tasarlanmış kullanıcı sayfalarından dinamik
            çeşitlilikle
            reklam ve içerik üretilmesine yardımcı olmak üzere tasarlanan ve kullanılan veri dosyasıdır. Kullanıcılar
            dilerlerse çerezlerin bilgisayarlarına yerleştirilmemesi veya bu türden bir dosyasının gönderildiğinde
            ikaz
            verilmesini sağlayacak biçimde tarayıcılarının ayarlarını her zaman değiştirebilirler. Hangi çerezlerin
            kullanıldığına “Çerez Politikası”ndan erişilebilir.
          </li>
          <li class="modal-body-text">Reidin Web sitesi kullanıcılarının kişisel verilerinin işlenmesi ve ilgili
            kişilerin
            hakları ile ilgili ayrıntılı bilgilendirme “Web Sitesi Kullanıcıları için Aydınlatma Metni”nde
            bulunmaktadır.
          </li>
          <li class="modal-body-text">REIDIN, işbu gizlilik bildiriminde geçen hükümleri gerekli gördüğü anlarda
            REIDIN
            ve
            REIDIN’e bağlı internet siteleri ve/veya mobil uygulamalarında yayınlamak şartıyla değiştirebilir.
            REIDIN’in
            değişiklik yaptığı gizlilik bildirimi hükümleri Site’de veya Uygulamada yayınlandığı tarihte yürürlüğe
            girmiş
            kabul edilirler.
          </li>
        </ol>
      </div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-secondary modal-footer-button" data-target="privacy-policy"
          data-dismiss="modal">
          {{this.language === 'eng' ? 'Close' : 'Kapat'}}
        </button>
      </div>
    </div>
  </div>