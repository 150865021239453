export type SearchResult = {
	citys: City[];
	locations: Location[];
	buildings: Building[];
	developers: Developer[];
};
export type  PaginationObj = Paginate & {
	next: boolean;
	previous: boolean;
}
export type Pagination = {
	city: PaginationObj;
	location: PaginationObj;
	developer: PaginationObj;
	building: PaginationObj;
};

export type City = {
	pk: number;
	model: string;
	fields: {
		county_name: string,
	};
};

export type Location = {
	pk: number;
	model: string;
	fields: {
		location_name: string;
	};
};

export type Building = {
	pk: number;
	model: string;
	fields: {
		developer_name: string;
		property_name: string;
		location_name: string;
	};
};

export type Developer = {
	pk: number;
	model: string;
	fields: {
		developer_name_local?: string;
		developer_name?: string;
		property_name: string;
		location_name: string;
	};
};

export type SubCategory = {
	city: {
		name: string;
		dashboardId?: number;
		slicers?: SubCategorySlicer[];
	}[];
	location: {
		name: string;
		dashboardId?: number;
		slicers?: SubCategorySlicer[];
	}[];
	building: {
		name: string;
		dashboardId?: number;
		cityAndCommunity?: boolean,
		slicers?: SubCategorySlicer[];
	}[];
	developer: {
		name: string;
		dashboardId?: number;
		slicers?: SubCategorySlicer[];
	}[];
};

export type SubCategorySlicer = {
	visualName: string;
	filterType: "hierarchy" | "basic";
};

export type Stage = "first" | "last";

export type Icon = "load" | "search" | "back";

export type Paginate = {
	page: number;
	size: number;
};

export type SelectedResult = {
	subCategory: any;
	value: string[];
	locationLevel?: string;
	cityAndCommunity?: boolean
};

export type OpenDashboardParams = {
	dashboardId: number;
	slicers: SubCategorySlicer[];
	values: string[];
	locationLevel?: string;
	cityAndCommunity?: boolean;
};
