<div class="d-flex flex-column-reverse">
  <div *ngFor="let alert of alerts;let i=index"
    class="{{ cssClass(alert) }} alert-dismissable no-margin margin-bottom-sm row justify-content-md-start align-items-center">
    <div class="col align-center">
      {{alert.message}} <a (click)="open(content,i)" style="text-decoration: underline;cursor:pointer"
        *ngIf="alert.detail">{{'main.views.login-page.error-detail'|translate}}</a>
    </div>
    <a class="close transition-100ms" (click)="removeAlert(alert)">&times;</a>
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          {{'main.general.messages.error.error-details' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm"
          (click)="modal.close('Save click')">{{'main.general.buttons.close'|translate}}</button>
      </div>
    </ng-template>
  </div>
</div>