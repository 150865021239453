import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'ngx-auth',
  templateUrl: './ngx-auth.component.html',
  styleUrls: ['./ngx-auth.component.scss']
})
export class NgxAuthComponent implements OnDestroy {

  private alive = true;

  subscription: any;

  authenticated: boolean = false;
  token: string = '';

  constructor(
    protected location: Location
  ) {

  }

  back() {
    this.location.back();
    return false;
  }

  ngOnDestroy(): void {
    // this.alive = false;
  }
}
