import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class NgxTermsAndConditionsComponent {
  public translateService: TranslateService;
  @Input('language') language: any;

  constructor(translateService: TranslateService) {
    this.translateService = translateService;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.language = changes.language && changes.language.currentValue ? 'eng' : 'tur';
  }
}