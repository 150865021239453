<h2 mat-dialog-title>{{ "main.general.buttons.my-filter" | translate }}</h2>
<mat-dialog-content class="mat-typography" color="accent">
  <table mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="filter_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.filter_name}} </td>
    </ng-container>

    <ng-container matColumnDef="create_date">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Created At </th>
      <td mat-cell *matCellDef="let element"> {{element.create_date | date}} </td>
    </ng-container>

    <ng-container matColumnDef="dashboard">
      <th mat-header-cell *matHeaderCellDef> Dashboard </th>
      <td mat-cell *matCellDef="let element"> {{element.dashboard_name}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button mat-dialog-close (click)="applyDashboardFilter(element)" data-dismiss="modal"
          color="accent">
          {{ "main.general.buttons.apply" | translate }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-container *ngIf="loading()">
    <mat-spinner class="spinner" color="accent"></mat-spinner>
  </ng-container>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements" />
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ "main.general.buttons.close" | translate }}</button>
</mat-dialog-actions>