import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(route, state);
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(childRoute, state);
  }

  protected checkLogin(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    return of(this.authService.getAuthStatus()).pipe(
      map(authStatus => {

        // const roleMatch = this.checkRoleMatch(authStatus.userRole, route)
        const roleMatch = true;
        let expiryDate = new Date(authStatus.expired_at).valueOf();
        if (isNaN(expiryDate)) {
          expiryDate = new Date(authStatus.expired_at.replace(/-/g, "/")).valueOf()
        }
        const allowLogin = authStatus.access_token !== '' && Date.now() < expiryDate && roleMatch;
        if (!allowLogin && state?.url === '/auth/login') {
          // this.showAlert(authStatus.isAuthenticated, roleMatch)
          this.router.navigate(['/auth/login'], {
            queryParams: {
              redirectUrl: this.getResolvedUrl(route)
            }
          });
        } else if (allowLogin && state?.url.startsWith('/auth/login')) {
          this.router.navigate(['/home/dashboard']);
        }
        if (!allowLogin && (state?.url === '/home/dashboard')) {
          this.router.navigate(['/auth/login']);
        }
        return true;
      }),
      take(1) // complete the observable for the guard to work
    );
  }


  private showAlert(isAuth: boolean, roleMatch: boolean) {
    if (!isAuth) {
      // this.uiService.showToast('You must login to continue')
    }
    if (!roleMatch) {
      // this.uiService.showToast(
      //   'You do not have the permissions to view this resource'
      // )
    }
  }

  getResolvedUrl(route?: ActivatedRouteSnapshot): string {
    if (!route) {
      return '';
    }
    return route.pathFromRoot
      .map(r => r.url.map(segment => segment.toString())
        .join('/'))
      .join('/')
      .replace('//', '/');
  }

}
