import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnInit,
	computed,
	inject,
	signal,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { filter, finalize, map, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { MenuService } from "@insight-services/menu.service";
import { ActivatedRoute } from "@angular/router";
import { PowerBIService } from "@insight-services/power-bi.service";
import { Dashboard } from "@insight-models/dashboard";
import {
	takeUntilDestroyed,
	toObservable,
	toSignal,
} from "@angular/core/rxjs-interop";
import { Filter } from "@insight-models/filter";
import { DashboardFilterService } from "@insight-services/dashboard-filter.service";
import { MenuItem } from "@insight-models/menuItem";
import { IPowerBiTokenResponse } from "@insight-models/powerbi-token-response";
import { User } from "@insight-models/user";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { SaveFilterModalComponent } from "../save-filter-modal/save-filter-modal.component";

@Component({
	selector: "ngx-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
	private readonly translateService = inject(TranslateService);
	private readonly menuService: MenuService = inject(MenuService);
	private readonly toastrService = inject(ToastrService);
	private readonly powerBIService: PowerBIService = inject(PowerBIService);
	private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
	private readonly destroyRef: DestroyRef = inject(DestroyRef);
	private readonly dashboardFilterService: DashboardFilterService = inject(
		DashboardFilterService
	);
	private readonly dialog = inject(MatDialog);

	fullPath = signal<string>("");
	currentDashboard = toSignal(this.menuService.currentDashboard$, {
		initialValue: undefined,
	});
	selectedDashboard = computed(() =>
		this.getSelectedDashboards(this.currentDashboard())
	);
	selectedDashboard$ = toObservable(this.selectedDashboard);

	exporting = signal(false);
	readyToExport = signal(false);
	isSaving = signal(false);
	selectedDashboardMainPath: string = "";
	selectedDashboardLastPath: string = "";
	isPrint:boolean;

	rendered() {
		this.readyToExport.set(true);
	}

	loading() {
		this.readyToExport.set(false);
	}

	ngOnInit() {
		this.findAndSetSelectedDashboard()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}

	toMenuItem(currentDashboard: Dashboard): Dashboard {
		if (
			currentDashboard.dashboard_id != null &&
			currentDashboard.child_tabs.length != 0 &&
			currentDashboard.is_tab == false
		) {
			const clone: MenuItem = {
				...currentDashboard,
				id: currentDashboard.menu_id,
				tab_menu: !currentDashboard.is_tab,
				child: currentDashboard.child_tabs,
				link: null,
			};
			if (
				currentDashboard.child_tabs[
					currentDashboard.child_tabs.length - 1
				].title_en != clone.title_en
			) {
				currentDashboard.child_tabs.push(clone);
				currentDashboard.child_tabs[0].child
					? currentDashboard.child_tabs[0].child
					: (currentDashboard.child_tabs[0].child = []);
				currentDashboard.child_tabs[0].child.push(clone);
				this.fullPath.set(clone.full_path);
				currentDashboard.dashboard_id =
					currentDashboard.child_tabs[0].dashboard_id;
				currentDashboard.title_en =
					currentDashboard.child_tabs[0].title_en;
				currentDashboard.title_tr =
					currentDashboard.child_tabs[0].title_tr;
				currentDashboard.url = currentDashboard.child_tabs[0].url;
			}
		}
		if (currentDashboard.full_path == "") {
			currentDashboard.full_path = this.fullPath();
		}
		if (!currentDashboard.is_tab) {
		}

		return currentDashboard;
	}

	getSelectedDashboards(currentDashboard: Dashboard | null) {
				if (currentDashboard)
			currentDashboard = this.toMenuItem(currentDashboard);

		if (currentDashboard && currentDashboard.dashboard_id !== "")
			this.breadcrumbTextEdit(
				currentDashboard.full_path,
				currentDashboard.full_path_tr
			);

		return currentDashboard;
	}

	async saveDashboardFilters($event: string) {
		this.isSaving.set(true);
		const capturedBookmark = this.powerBIService.currentReport;
		const user = JSON.parse(localStorage.getItem("user")) as User;
		const filter: Filter = {
			id: 0,
			user_id: user.id,
			user_name: user.name,
			filter_name: $event,
			dashboard_id: this.currentDashboard().menu_id,
			company_name: user.company.company_name,
			filter: capturedBookmark.capturedBookmark.state,
			page_name: capturedBookmark.pageName,
		};
		this.dashboardFilterService
			.saveFilter(filter)
			.pipe(finalize(() => this.isSaving.set(false)))
			.subscribe({
				next: (_) => {
					this.toastrService.success("Filter is successfully saved");
				},
				error: (err) => {
					this.toastrService.error("A problem occured during saving");
				},
			});
	}

	openSaveDashobardFiltersDialog() {
		const dialogRef = this.dialog.open(SaveFilterModalComponent);

		dialogRef
			.afterClosed()
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter((filterName) => filterName !== undefined),
				tap(async (filterName) => {
					await this.saveDashboardFilters(filterName);
				})
			)
			.subscribe();
	}

	findAndSetSelectedDashboard() {
		return this.activatedRoute.params.pipe(
			map((params) => ({
				id: +params.id,
				menuItems: this.menuService.topMenuItems(),
			})),
			filter(({ id, menuItems }) => {
				return menuItems && menuItems.length > 0;
			}),
			tap(({ id, menuItems }) => this.setSelectedDashboard(menuItems, id))
		);
	}

	setSelectedDashboard(menuItems: MenuItem[], id: number) {
		for (let menuItem of menuItems) {
			const dashboard = this.menuService.findDashboard(menuItem, id);
			if (dashboard) {
				this.menuService.setSelectedTopMenuItem(menuItem);
				this.setCurrentDashboard(dashboard);
				this.isPrint = dashboard.is_print;
				break;
			}
		}
	}

	breadcrumbTextEdit(fullPath: string, fullPathTr: string) {
		this.selectedDashboardMainPath = "";
		let navigate = fullPath.split(">");
		if (
			this.translateService.currentLang === "tur" &&
			fullPathTr &&
			fullPathTr.length > 0
		) {
			navigate = fullPathTr.split(">");
		}
		this.selectedDashboardLastPath = navigate[navigate.length - 1];
		navigate.forEach((nav) => {
			this.selectedDashboardMainPath +=
				nav != navigate[navigate.length - 1] ? nav + " > " : "";
		});
	}

	public setCurrentDashboard(menuItem: MenuItem): void {
		if (menuItem && menuItem.id) {
			this.menuService.setCurrentDashboard(
				this.transformMenuItemToDashboard(menuItem)
			);
		}
	}

	private transformMenuItemToDashboard(menuItem: MenuItem): Dashboard {
		const transformMenuItemFullPathToDashboardFullPath = (
			fullPath: string
		) => {
			const result = fullPath
				.split("->")
				.splice(2)
				.map((str) => str.trim())
				.join(" > ");
			return result;
		};
		const dashboard: Dashboard = {
			...menuItem,
			menu_id: menuItem.id,
			full_path: transformMenuItemFullPathToDashboardFullPath(
				menuItem.full_path
			),
			full_path_tr:
				menuItem.full_path_tr && menuItem.full_path_tr.length > 5
					? transformMenuItemFullPathToDashboardFullPath(
							menuItem.full_path_tr
					  )
					: menuItem.full_path_tr,
			is_tab: menuItem.tab_menu,
			child_tabs:
				menuItem.child?.map((item: MenuItem) => {
					if (item.tab_menu) {
						item.child = menuItem.child;
					}
					return item;
				}) || [],
		};
		return dashboard;
	}

	getPowerBiToken(): Observable<IPowerBiTokenResponse> {
		const powerBIToken: string | null =
			localStorage.getItem("powerbi_token");

		if (powerBIToken) {
			return of({ access_token: powerBIToken, token: null });
		}

		return this.powerBIService.getPowerBiToken();
	}

	exportDashboard(dashboardId?: string, type: "pdf" = "pdf") {
				this.exporting.set(true);
		const currentDashboardTitleEn = this.currentDashboard()
			.title_en.toLocaleLowerCase()
			.trim()
			.replace(" ", "");
		const date = new Date();
		const day =
			date.getDate() < 10
				? `0${date.getDate()}`
				: date.getDate().toString();
		const month =
			(date.getMonth() + 1) < 10
				? `0${date.getMonth() + 1}`
				: (date.getMonth() + 1).toString();
		const fileName = `${currentDashboardTitleEn}.${day + month}`;
		this.powerBIService
			.exportDashboard(fileName, dashboardId, type)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (_) => {
					this.exporting.set(false);
				},
				complete: () => {
					this.exporting.set(false);
				},
				error: (_) => {
					this.exporting.set(false);
				},
			});
	}
}
