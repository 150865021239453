import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SelectivePreloadingStrategy } from './utils/selective-preloading-strategy';
import { AuthGuardService } from './services/auth-guard.service';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { TermsConditionsComponent } from './privacy-terms/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-terms/privacy-policy/privacy-policy.component';
import { DataDisclaimerComponent } from './privacy-terms/data-disclaimer/data-disclaimer.component';
import { RefundPolicyComponent } from './privacy-terms/refund-policy/refund-policy.component';
import { PolicyOnProtectionOfPersonalDataComponent } from './privacy-terms/policy-on-protection-of-personal-data/policy-on-protection-of-personal-data.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home/dashboard'
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'auth',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'privacy-terms',
    component: PrivacyTermsComponent,
    children: [
      {
        path: 'terms-conditions',
        component: TermsConditionsComponent
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
      },
      {
        path: 'data-disclaimer',
        component: DataDisclaimerComponent
      },
      { path: 'refund-policy', component: RefundPolicyComponent },
      {
        path: 'policy-on-protection-of-personal-data',
        component: PolicyOnProtectionOfPersonalDataComponent
      }
    ]
  },
];

const config: ExtraOptions = {
  // enableTracing: true,
  preloadingStrategy: SelectivePreloadingStrategy
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [
    SelectivePreloadingStrategy
  ]
})
export class AppRoutingModule { }
