import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'ngx-landing-promotion',
  templateUrl: './landing-promotion.component.html',
  styleUrls: ['./landing-promotion.component.scss']
})

export class NgxLandingPromotionComponent implements OnDestroy, OnInit {
  @Input() languageChose: boolean = true;
  @Input() isDesktop: boolean = true;

  public static passData: boolean
  carouselScrollInterval: any;
  customerScroll$: any;
  customerScrollInterval$: any;
  carouselIndexCount: number = 0;
  coordinates: number[] = [];
  touched: boolean = false;
  activeSlide: number = 0;

  ngOnDestroy(): void {
    if (this.customerScroll$)
      this.customerScroll$.unsubscribe();
    clearInterval(this.carouselScrollInterval);
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('language') == null) {
      this.languageChose = this.languageChose;
    } else {
      if (sessionStorage.getItem('language') === 'true') {
        this.languageChose = true;
      } else {
        this.languageChose = false;
      }
      NgxLandingPromotionComponent.passData = this.languageChose;
    }
    this.enableCarouselScroll(0);
    if (this.isDesktop)
      this.enableCustomerScroll();
  }

  liClick(event) {
    this.switchCase(event.srcElement.attributes[2].value);
  }

  switchCase(switchValue) {
    this.disableCarouselScroll();
    switch (switchValue) {
      case 0: case 'nav-reports-tab': case 'nav-reports-tabM': case 'indicator1': case 'desktopIndicator1': {
        this.isDesktop ? document.getElementById('nav-reports-tab').click() : document.getElementById('nav-reports-tabM').click();
        this.activeSlide = 0;
        break;
      }
      case 1: case 'nav-rankings-tab': case 'nav-rankings-tabM': case 'indicator2': case 'desktopIndicator2': {
        this.isDesktop ? document.getElementById('nav-rankings-tab').click() : document.getElementById('nav-rankings-tabM').click();
        this.activeSlide = 1;
        break;
      }
      case 2: case 'nav-trends-tab': case 'nav-trends-tabM': case 'indicator3': case 'desktopIndicator3': {
        this.isDesktop ? document.getElementById('nav-trends-tab').click() : document.getElementById('nav-trends-tabM').click();
        this.activeSlide = 2;
        break;
      }
      case 3: case 'nav-data-tab': case 'nav-data-tabM': case 'indicator4': case 'desktopIndicator4': {
        this.isDesktop ? document.getElementById('nav-data-tab').click() : document.getElementById('nav-data-tabM').click();
        this.activeSlide = 3;
        break;
      }
      default: {
        break;
      }
    }
    this.enableCarouselScroll(this.activeSlide);
  }

  sliderControlClick(type: string) {
    this.disableCarouselScroll();
    if (type === 'prev')
      this.activeSlide !== 0 ? this.activeSlide -= 1 : this.activeSlide = 3;
    if (type === 'next')
      this.activeSlide !== 3 ? this.activeSlide += 1 : this.activeSlide = 0;
    this.enableCarouselScroll(this.activeSlide);
  }

  navClick(event) {
    this.switchCase(event.srcElement.id);
  }

  touchMove(event: any) {
    let yon;
    if (this.coordinates.length < 5 && this.touched)
      this.coordinates.push(event.touches[0].clientX);
    if (this.coordinates.length >= 5 && this.touched) {
      this.disableCarouselScroll();
      yon = this.coordinates[4] - this.coordinates[0];
      if (this.touched && yon < 0) {
        this.touched = false;
        this.activeSlide !== 3 ? this.activeSlide += 1 : this.activeSlide = 0;
      } else if (this.touched && yon > 0) {
        this.touched = false;
        this.activeSlide !== 0 ? this.activeSlide -= 1 : this.activeSlide = 3;
      }
      this.enableCarouselScroll(this.activeSlide);
      this.coordinates = [];
    }
  }

  touchStart() {
    this.touched = true;
  }

  touchEnd() {
    this.touched = false;
  }

  enableCarouselScroll(activeSlide: number) {
    this.disableCarouselScroll();
    this.carouselIndexCount = activeSlide;
    this.carouselScrollInterval = (setInterval(() => {
      if (this.carouselIndexCount === 4) {
        this.carouselIndexCount = 0;
      }
      this.switchCase(this.carouselIndexCount);
      this.carouselIndexCount += 1;
    }, 4000));
  };

  disableCarouselScroll() {
    window.clearInterval(this.carouselScrollInterval);
  }

  disableCustomerScroll() {
    this.customerScroll$.unsubscribe();
  }

  enableCustomerScroll() {
    this.customerScrollInterval$ = interval(20);
    this.customerScroll$ = this.customerScrollInterval$.subscribe(() => {
      const x = document.getElementById('desktop-customer');
      const y = document.getElementById('mobile-customer');
      let xOldScrollLeft;
      let yOldScrollLeft;
      if (x) {
        xOldScrollLeft = x.scrollLeft;
        x.scrollLeft += 1;
        if (x.scrollLeft === xOldScrollLeft) {
          x.scrollLeft = 0;
        }
      }
      if (y) {
        yOldScrollLeft = y.scrollLeft;
        y.scrollLeft += 1;
        if (y.scrollLeft === yOldScrollLeft) {
          y.scrollLeft = 0;
        }
      }
    })
  }
}
