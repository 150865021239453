import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  language: string;
  constructor(

    private translateService: TranslateService
  ) {

    this.language = this.translateService.currentLang;
  }

  ngOnInit() {}
}
