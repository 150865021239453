import { NgModule } from '@angular/core';

import { HomeComponent } from './home.component';
import { HeaderComponent } from './header/header.component';
import { HomeRoutingModule } from './home-routing.module';
import { ThemeModule } from '../@theme/theme.module';
import { SidebarComponent } from './sidebar-menu/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NbButtonGroupModule, NbIconModule, NbLayoutModule, NbTooltipModule } from '@nebular/theme';
import { NbActionsModule } from '@nebular/theme';
// import { NbTooltipModule } from '@nebular/theme'
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuService } from '../services/menu.service';
import { UsercardComponent } from './usercard/usercard.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserServiceInterceptor } from '../helpers/userservice-interceptor';
import { CommonModule } from '@angular/common';
import { DashboardInterceptor } from '../helpers/dashboard-interceptor';
import { MatSelectModule } from '@angular/material/select';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { PowerBiRestAPIInterceptor } from '../helpers/power-bi-rest-api.interceptor';
import { PowerBIComponent } from './power-bi/power-bi.component';
import { FiltersComponent } from "./filters/filters.component";
import { SaveFilterModalComponent } from "./save-filter-modal/save-filter-modal.component";
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        HomeComponent,
        SidebarComponent,
        UsercardComponent,
        DashboardComponent,
        HeaderComponent,
    ],
    providers: [
        MenuService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DashboardInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PowerBiRestAPIInterceptor,
            multi: true
        },
    ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        ThemeModule,
        MatSelectModule,
        NbLayoutModule,
        NbIconModule,
        NbActionsModule,
        NbTooltipModule,
        NbButtonGroupModule,
        TranslateModule,
        FiltersComponent,
        SaveFilterModalComponent,
        PowerBIComponent,
        MatDialogModule,
    ],
})
export class HomeModule { }
