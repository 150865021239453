<ol style="list-style-type: none">
  <li>
      <p>In this policy, terms “member” or “user” or “you” refers to the reader or client. Whereas
          terms “us”, “we” and “our” refers to the company REIDIN FZ LLC or “REIDIN”.</p>
  </li>
  <li>
      <h4 class="privacyPolicyHeader">Services and products</h4>
      <p>
          We do not issue refunds for any of our services and products according to signed
          contract.
      </p>
      <p>Our Subscription service is minimum 12 months commitment. Any member's cancellation of
          the Membership before the end of the contract term will render any remaining unpaid
          invoiced or contract amount immediately due. During the Actual Contract Term, Member can
          only reassign/transfer the membership within the company only in bona fide circumstances
          with the prior approval and consultation of REIDIN. </p>
      <p>We recommend you read our contract thoroughly and you can contact us for assistance if
          you experience any issues.</p>
  </li>
  <li>
      <h4 class="privacyPolicyHeader">
          Contact us
      </h4>
      <p>If you have any questions about our Refunds Policy, please contact us:</p>
      <ul type="disc">
          <li>
              <p>By phone number: <a href="tel:+97142776835">+971 4 277 68 35</a></p>
          </li>
          <li>
              <p>By email: <a href="mailto:cs@reidin.com">cs&#64;reidin.com</a></p>
          </li>
      </ul>
  </li>
</ol>