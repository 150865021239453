import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'reidin-building-icon',
    templateUrl: './building-icon.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class BuildingIcon {
}