import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '@insight-environments/environment';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { User } from '@insight-models/user';

@Injectable()
export class DashboardInterceptor implements HttpInterceptor {
  private readonly router = inject(Router)

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const authStatus = JSON.parse(localStorage.getItem("userServiceToken"));
    const user = JSON.parse(localStorage.getItem("user")) as User | null

    const lang = 'eng';
    const country = 'AE';
    if(!request.url.includes('/api/demo-request')){
      if (
        request.url.indexOf(environment.backendAPIUrl + '/api') > -1
        || request.url.indexOf(environment.backendAPIUrl + '/api/dashboard-filters') > -1
      ) {
        if (authStatus.access_token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authStatus.access_token}`,
              Lang: `${lang}`,
              Country: `${country}`,
              Userid: user?.id.toString()
            }
          });
        }
      }
    }

    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (err.status === 401 || err.status == 403) {
          console.log('Error 401:');
          localStorage.clear();
          this.router.navigate(['/auth/login']);
        }
        return throwError(err);
      })
    );
  }
}
