import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { HomeComponent } from "./home.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NotificationComponent } from "./notification/notification.component";
import { SalesTransactionsComponent } from "./notification/sales-transactions/sales-transactions.component";
import { RentTransactionsComponent } from "./notification/rent-transactions/rent-transactions.component";
import { ListingMonitorComponent } from "./notification/listing-monitor/listing-monitor.component";
import { ListingDataComponent } from "./notification/listing-data/listing-data.component";
import { SupplyDataComponent } from "./notification/supply-data/supply-data.component";
import { SearchComponent } from "./search/feature/search.component";
import { menuItemResolver } from "app/resolvers/menu-item.resolver";

const routes: Routes = [
	{
		path: "",
		component: HomeComponent,
		resolve: {
			menuItem: menuItemResolver,
		},
		children: [
			{
				path: "dashboard/:id",
				pathMatch: "prefix",
				component: DashboardComponent,
			},
			{
				path: "dashboard",
				pathMatch: "prefix",
				component: SearchComponent,
			},
			{
				path: "notification",
				pathMatch: "prefix",
				component: NotificationComponent,
				children: [
					{
						path: "",
						pathMatch: "full",
						redirectTo: "sales-transactions",
					},
					{
						path: "sales-transactions",
						component: SalesTransactionsComponent,
					},
					{
						path: "rent-transactions",
						component: RentTransactionsComponent,
					},
					{
						path: "listing-monitor",
						component: ListingMonitorComponent,
					},
					/*{
						path: "listing-data",
          				component: ListingDataComponent
         			 },*/
					{
						path: "supply-data",
						component: SupplyDataComponent,
					},
				],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class HomeRoutingModule {}
