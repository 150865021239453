import {
	Component,
	DestroyRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	inject,
	signal,
} from "@angular/core";
import { Embed, IEmbedConfiguration, models, service } from "powerbi-client";
import {
	PowerBIEmbedModule,
	PowerBIReportEmbedComponent,
} from "powerbi-client-angular";
import { Observable, from, of, pipe, timer } from "rxjs";
import {
	debounceTime,
	filter,
	map,
	switchMap,
	take,
	tap,
	withLatestFrom,
} from "rxjs/operators";
import { PowerBIService } from "@insight-services/power-bi.service";
import { DashboardFilterService } from "@insight-services/dashboard-filter.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { IPowerBiConfigDto } from "@insight-models/powerbi-config.dto";
import { IPowerBiReportResponse } from "@insight-models/powerbi-report-response";
import { IPowerBiEmbedTokenResponse } from "@insight-models/powerbi-embed-token-response";
import { FilterDto } from "@insight-models/filter.dto";
import { CommonModule } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { FilterSchema } from "@insight-models/filter-schema";
import { IHttpPostMessageResponse } from "http-post-message";
import { SearchProxyService } from "../search/data-access/search-proxy.service";
import { SearchService } from "../search/data-access/search.service";

function isString(value: any) {
	return typeof value === "string";
}

@Component({
	selector: "ngx-power-bi",
	templateUrl: "./power-bi.component.html",
	styleUrls: ["./power-bi.component.scss"],
	standalone: true,
	imports: [CommonModule, PowerBIEmbedModule],
	providers:[SearchProxyService,SearchService]
	})
export class PowerBIComponent implements OnInit {
	@Input() selectedDashboard: Observable<any>;
	@ViewChild(PowerBIReportEmbedComponent)
	reportObj!: PowerBIReportEmbedComponent;
	@Output() onReportRendered? =
		new EventEmitter<PowerBIReportEmbedComponent>();
	@Output() onLoading = new EventEmitter();

	private readonly powerBIService = inject(PowerBIService);
	private readonly dashboardFilterService = inject(DashboardFilterService);
	private readonly destroyRef = inject(DestroyRef);
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly searchService = inject(SearchService);
	
	country:string;
	reportClass = "report-container";
	source = timer(undefined, 30000);
	isPowerBIEmbedRendered = signal(false);
	configDto$: Observable<IPowerBiConfigDto>;
	eventHandlersMap = new Map([
		[
			"loaded",
			async () => {
				this.powerBIService.onLoaded(this.reportObj);

				const queryParams = this.activatedRoute.snapshot.queryParams;
				const {
					visualName,
					filterType,
					value,
					locationLevel,
					cityAndCommunity,
				} = queryParams;

				if (visualName && filterType && value)
					await this.applySearchResult(
						isString(visualName) ? [visualName] : visualName,
						isString(filterType) ? [filterType] : filterType,
						isString(value) ? [value] : value,
						locationLevel,
						cityAndCommunity,
					);
			},
		],
		[
			"rendered",
			async () => {
				await this.powerBIService.onRendered(
					this.reportObj,
					this.isPowerBIEmbedRendered
				);
				this.onReportRendered.emit(this.reportObj);
			},
		],
	]) as Map<
		string,
		(
			event?: service.ICustomEvent<any>,
			embeddedEntity?: Embed
		) => void | null
	>;

	private async applySearchResult(
		visualName: string[],
		filterType: string[],
		value: string[],
		locationLevel?: string,
		cityAndCommunity?: boolean
	) {
		const report = this.reportObj.getReport();
		const slicers = await this.powerBIService.getSlicers(report);

		const setSlicerStates: Promise<IHttpPostMessageResponse<void>>[] = [];

		for (let index = 0; index < visualName.length; index++) {
			const element = visualName[index];
			const slicer = slicers.find((slicer) => slicer.name === element);
			
			const slicerState = await slicer.getSlicerState();
						
			const isLocationDistrict =
				locationLevel && locationLevel === "District";

			if (filterType[index] === "hierarchy") {
					const hierarchyData: models.IHierarchyFilterNode = {
					children: isLocationDistrict
						? [
								{
									operator: "Selected",
									value: value[index],
								},
						  ]
						: undefined,
					operator: isLocationDistrict ? "Inherited" : "Selected",
					value: this.getHierarchyFilterValue(value, index, isLocationDistrict, cityAndCommunity)
				};

				const hierarchyFilter: models.IHierarchyFilter = {
					$schema: FilterSchema.hierarchy,
					target: slicerState.targets,
					filterType: 9,
					hierarchyData: [hierarchyData],
				};

				if(hierarchyFilter.target.length < 2 && this.country == "TR"){
					hierarchyFilter.target.push(
						{
							column: "Mahalle",
							table: "pbi_tr_locations"
						}
					)
				}
				
				const setSlicerState = slicer.setSlicerState({
					filters: [hierarchyFilter],
				});
				setSlicerStates.push(setSlicerState);
			} else {
				const isTrCity = slicerState.targets[0]["column"] == "İl";
				const isTrDistrict = slicerState.targets[0]["column"] == "Mahalle";
				const basicfilter: models.IBasicFilter = {
					$schema: FilterSchema.basic,
					target: slicerState.targets[0],
					filterType: 1,
					operator: "In",
					values: await this.getValueBySubCategories(value,isTrCity,isTrDistrict,isLocationDistrict,index)
				};
								
				const setSlicerState = slicer.setSlicerState({
					filters: [basicfilter],
				});
				setSlicerStates.push(setSlicerState);
			}
		}

		await Promise.all(setSlicerStates);
	}

	getValueBySubCategories(
		value:(string)[],
		isCity:boolean,
		isTrDistrict,
		isLocationDistrict:boolean,
		index:number
		):string[]{
			if(this.country == "AE") return [value[index]];
			
			if(isCity && value.length == 4){
				return [value[value.length - 2]];
			}else if(isTrDistrict && value.length == 3){
				return [value[value.length - 3]];
			}else if(isLocationDistrict && value.length == 3 && index == 0){
				return [value[value.length - 1]];
			}else{
				return [value[index]];
			}
	}

	getHierarchyFilterValue(
		value: string[],
		index: number,
		isLocationDistrict: boolean,
		isCityAndCommunity: boolean
	) {
		if (isCityAndCommunity) {
			return value[value.length - 2];
		} else if (isLocationDistrict) {
			return value[value.length - 1];
		} else return value[index];
	}

	ngOnInit(): void {
		this.configDto$ = this.getConfigDto();
		this.applyDashboardFilterIfExist().subscribe();
		this.searchService.getCountry()
			.pipe(
				tap((country) => this.country = country),
				take(1)
			)
			.subscribe();
	}

	getConfigDto() {
		return this.selectedDashboard.pipe(
			this.debounceAndSetLoadingState(),
			this.getReport(),
			this.getEmbedToken(),
			this.saveEmbedTokenExpirationToLocalStorage(),
			this.startRefreshEmbedTokenTimer(),
			this.includeAppliedDashboardFilterAndSelectedDashboard(),
			this.mapToConfigDto()
		);
	}

	private includeAppliedDashboardFilterAndSelectedDashboard() {
		return withLatestFrom(
			this.activatedRoute.queryParams.pipe(
				map((queryParams) => {
					const filterId = +queryParams["filterId"];
					return this.dashboardFilterService
						.filters()
						.find((filter) => filter.id === filterId);
				})
			),
			this.selectedDashboard
		);
	}

	private startRefreshEmbedTokenTimer() {
		return switchMap<
			{
				report: IPowerBiReportResponse;
				embedToken: IPowerBiEmbedTokenResponse;
			},
			Observable<{
				report: IPowerBiReportResponse;
				embedToken: IPowerBiEmbedTokenResponse;
			}>
		>(({ report, embedToken }) => {
			return this.refreshEmbedToken(report.id, report.datasetId).pipe(
				map((_) => {
					return { report, embedToken };
				})
			);
		});
	}

	private saveEmbedTokenExpirationToLocalStorage() {
		return tap<{
			report: IPowerBiReportResponse;
			embedToken: IPowerBiEmbedTokenResponse;
		}>(({ report, embedToken }) => {
			const date = new Date(embedToken.expiration);
			localStorage.setItem("embed_token_exp", date.toUTCString());
		});
	}

	private mapToConfigDto() {
		return map<
			[
				report: {
					report: IPowerBiReportResponse;
					embedToken: IPowerBiEmbedTokenResponse;
				},
				filterDto: FilterDto,
				selectedDashboard: any
			],
			IPowerBiConfigDto
		>(([report, filterDto, selectedDashboard]) => ({
			id: report.report.id,
			embedUrl: report.report.embedUrl,
			accessToken: report.embedToken.token,
			state:
				filterDto?.dashboard_id === selectedDashboard.menu_id
					? filterDto.filter
					: undefined,
		}));
	}

	private getEmbedToken() {
		return switchMap((report: IPowerBiReportResponse) =>
			this.powerBIService.getEmbedToken(report.datasetId, report.id).pipe(
				map((embedToken) => ({
					report,
					embedToken,
				}))
			)
		);
	}

	private getReport() {
		return switchMap((selectedDashboard: any) =>
			this.powerBIService.getReport(selectedDashboard.dashboard_id)
		);
	}

	private debounceAndSetLoadingState() {
		return pipe(
			tap((selectedDashboard: any) => {
				if (
					this.reportObj &&
					this.reportObj.getReport().getId() !==
						selectedDashboard.dashboard_id
				) {
					this.onLoading.emit();
					this.isPowerBIEmbedRendered.set(false);
					const report = this.reportObj.getReport();
					report.element.style.setProperty("visibility", "hidden");
				}
			}),
			debounceTime(500),
			tap((selectedDashboard: any) => {
				if (
					this.reportObj &&
					this.reportObj.getReport().getId() ===
						selectedDashboard.dashboard_id
				) {
					this.isPowerBIEmbedRendered.set(true);
					this.onReportRendered.emit(this.reportObj);
					const report = this.reportObj.getReport();
					report.element.style.setProperty("visibility", "visible");
				}
			})
		);
	}

	applyDashboardFilterIfExist() {
		return this.activatedRoute.queryParams.pipe(
			takeUntilDestroyed(this.destroyRef),
			map((queryParams) => queryParams["filterId"]),
			filter(
				(filterId) =>
					filterId !== undefined && this.isPowerBIEmbedRendered()
			),
			tap((filterId: string) => {
				const dashboardFilter = this.dashboardFilterService
					.filters()
					.find((x) => x.id === +filterId);
				const report = this.reportObj.getReport();
				report.bookmarksManager.applyState(dashboardFilter.filter);
			})
		);
	}

	getPowerBIEmbedConfig(configDto: IPowerBiConfigDto): IEmbedConfiguration {
		return {
			type: "report",
			id: configDto.id,
			embedUrl: configDto.embedUrl,
			accessToken: configDto.accessToken,
			tokenType: models.TokenType.Embed,
			bookmark: configDto.state ? { state: configDto.state } : undefined,
			settings: {
				panes: {
					filters: {
						expanded: false,
						visible: false,
					},
					pageNavigation: {
						visible: false,
					},
				},
				background: models.BackgroundType.Transparent,
			},
		};
	}

	private refreshEmbedToken(reportId: string, datasetId: string) {
		const MINUTES_BEFORE_EXPIRATION = 10;
		let tokenExpiration = localStorage.getItem("embed_token_exp");
		let currentTime = Date.now();
		let expiration = Date.parse(tokenExpiration);
		let timeUntilExpiration = expiration - currentTime;
		let timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

		return this.source.pipe(
			tap((_) => {
				tokenExpiration = localStorage.getItem("embed_token_exp");
				currentTime = Date.now();
				expiration = Date.parse(tokenExpiration);
				timeUntilExpiration = expiration - currentTime;
				timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;
			}),
			switchMap((_) => {
				//if (timeUntilExpiration <= timeToUpdate) {
					return this.powerBIService.getPowerBiToken().pipe(
						tap((powerBIToken) => {
							localStorage.setItem(
								"powerbi_token",
								powerBIToken.access_token
							);
						}),
						// switchMap((_) =>
						// 	this.powerBIService
						// 		.getEmbedToken(datasetId, reportId)
						// 		.pipe(
						// 			tap((embedToken) => {
						// 				const date = new Date(
						// 					embedToken.expiration
						// 				);
						// 				localStorage.setItem(
						// 					"embed_token_exp",
						// 					date.toUTCString()
						// 				);
						// 			})
						// 		)
						// ),
						// switchMap((embedToken) =>
						// 	from(
						// 		this.reportObj
						// 			.getReport()
						// 			.setAccessToken(embedToken.token)
						// 	)
						// )
					);
				// }
				// return of(null);
			})
		);
	}
}
