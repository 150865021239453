import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  language: string;
  constructor(

    private translateService: TranslateService
  ) {
    this.language = this.translateService.currentLang;
  }


  ngOnInit() {}
}
