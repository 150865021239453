import { Component, OnInit } from '@angular/core';
import { AlertsService } from '@insight-services/alerts.service';
import { AlertType } from "@insight-models/alert-type";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import '../../assets/js/jsonview';
import { Alert } from '@insight-models/alert';
@Component({
  selector: 'ngx-custom-alert',
  templateUrl: 'alerts.component.html',
  styleUrls: ['alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  alerts: Alert[] = [];

  constructor(
    private alertService: AlertsService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }

      // add alert to array
      this.alerts.push(alert);
    });
  }
  open(content, i: number) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-error-detail',
      size: 'lg'
    });
    window['jsonView'].format(this.alerts[i].detail, '.modal-body');
  }
  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'alert-custom alert-custom-success';
      case AlertType.Error:
        return 'alert-custom alert-custom-danger';
      case AlertType.Info:
        return 'alert-custom alert-custom-info';
      case AlertType.Warning:
        return 'alert-custom alert-custom-warning';
      default:
        return 'alert-custom alert-custom-success';
    }
  }
}
